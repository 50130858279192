import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useParams } from "../../stores/uiStore"
import { observer } from 'mobx-react-lite'
import { useStore } from '../../contexts/store'
import { PodClass } from '../../classes/Pod'
import SyncProblemIcon from '@mui/icons-material/SyncProblem'
import ConfirmDialog from '../Elements/ConfirmDialog'
import { Link } from 'wouter'

import { env } from '../../api/api'
import ConversationUnreads from './Conversation/ConversationUnreads'

import { Box, FormControl, InputLabel, MenuItem, Link as MuiLink, Select, SelectChangeEvent } from "@mui/material"
import { navigate } from 'wouter/use-location'


const PodHeader = ({activeTabIndex}: {activeTabIndex?: string}) => {
  const { t } = useTranslation()
  const { podId } = useParams()
  const { podStore, uiStore } = useStore()
  const pod:PodClass|null = podStore.pod
  const [ confirmPodReset, setConfirmPodReset ] = useState<null|string>(null)
  const deviceWidth = uiStore.deviceWidth
  const containerPadding = (deviceWidth === "tiny" || deviceWidth === 'smaller') ? 16 : 40

  useEffect(() => {
    if(activeTabIndex) uiStore.setActivePodTab(podId, activeTabIndex)
    // reset show unread conversations
    uiStore.setShowUnreads(false)
  }, [activeTabIndex])

  if (pod === null) return <div>'pod' is null when trying to show pod</div>

  const isAdmin = pod.isAllowed('deletePod', pod.podId)

  const initScrollPos = (tab: string) => {
    const scrollPos = uiStore.getPodScrollPos(podId, tab)
    const podContainer = document.getElementById("pod-tab-scroll-container")
    if(scrollPos !== null && podContainer) {
      podContainer.scroll({
        top: scrollPos
      })
    }
  }

  const OutOfSync = () => {
    if(pod.outOfSync) {
      return (
        <Box
          style={{float:'right'}}
          title={t('This pod seems to be out of sync. It is recommended that you reset it. Click for more.')}
          onClick={() => {setConfirmPodReset(pod.podId) }}
        >
          <SyncProblemIcon color='warning' />
        </Box>
      )
    }
    return null
  }


  const podTabs = [
    {
      path: 'contents',
      name: t('Contents'),
    },{
      path: 'activity',
      name: t('Activity'),
    },{
      path: 'conversations',
      name: t('Conversations'),
    },{
      path: 'notes',
      name: t('Notes'),
    },{
      path: 'people',
      name: t('People'),
    },{
      path: 'settings',
      name: isAdmin ? t('Settings') : t('Info'),
    }
  ]

  const activeTabStyle = {
    alignContent: "center",
    color: "rgb(108, 108, 211)",
    display: "grid",
    fontSize: "14px",
    fontWeight: "500",
    gridTemplateColumns: "min-content min-content",
    gridGap: "5px",
    padding: "13px",
    backgroundColor:'#ffff',
    borderLeft: '1px solid #0008',
    borderTop: '1px solid #0008',
    borderRight: '1px solid #0008',
  }

  const tabStyle = {
    alignContent: "center",
    borderBottom: "2px solid #888",
    color: "rgba(0, 0, 0, 0.6)",
    display: "grid",
    fontSize: "14px",
    fontWeight: "500",
    gridTemplateColumns: "min-content min-content",
    gridGap: "5px",
    padding: "13px",
    marginBottom: '-5px',
    boxShadow: 'inset -2px -3px 4px -4px #00000080',
    backgroundColor: '#fffb',
    backdropFilter: 'saturate(180%) blur(3px)',
    height: '40px',
    marginTop: '8px'
  }

  // if a conversation was previously active, navigate there
  function generateUrl(tab: string) {
    const activeConversation = uiStore.getActiveConversation(podId)
    const url = `/pod/${podId}/${tab}`
    if(tab === "conversations" && activeConversation) return `${url}/${activeConversation}`
    return url
  }

  const Menu = () => {
    // show selection menu on small screens
    if(deviceWidth === "tiny" || deviceWidth === 'smaller') return <SeclectionMenu />
    // else show tab menu
    return (
      <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "2px",
        margin: "0 8px",
        clear:'both',
      }}>
        {
        podTabs.map((tab, i) => (<Link key={tab.path} to={generateUrl(tab.path)}>
          <MuiLink underline="none" sx={ activeTabIndex === tab.path ? activeTabStyle : tabStyle } style={{textTransform: 'uppercase'}}>
            {tab.name}
            {tab.path === "conversations" &&
              <ConversationUnreads />
            }
          </MuiLink>
        </Link>))
        }
      </Box>
    )
  }

  const SeclectionMenu = () => (
    <Box sx={{ minWidth: 120, clear: 'both' }}>
      <FormControl fullWidth size="small">
        <Select
          labelId="select-pod-menu-label"
          id="select-pod-menu"
          value={activeTabIndex}
          onChange={(event: SelectChangeEvent) => {if(event.target && event.target.value) navigate(generateUrl(event.target.value))}}
          sx={{backgroundColor:'#fff'}}
        >
          {
          podTabs.map((tab, i) => (<MenuItem sx={{backgroundColor:'#fff'}} key={tab.path} value={tab.path}>{tab.name}</MenuItem>))
          }
        </Select>
      </FormControl>
    </Box>
  )

  const headerStyle:any = {
    float:'left',
    width: '100%',
    paddingBottom:3,
    paddingLeft:1,
    paddingRight:1,
    paddingTop: `${((deviceWidth === 'medium' || deviceWidth === 'large') && (!pod.description)) ? 40 : 0}px`
  }

  const headerImage:any = {
    backgroundColor: pod.podColor ? pod.podColor : 'white'
  }

  if (pod.podImageFileId) {
    headerImage.backgroundImage = `url(${env.backendBaseUrl}/file/${pod.podImageFileId})`
    headerImage.backgroundSize = '100% 100%'
    headerImage.backgroundSize = 'cover'
    headerImage.backgroundPositionY = 'center'
    headerImage.boxShadow = 'inset 0 -3px 4px -2px #0008'
    // headerStyle.height ='200px'

    if (deviceWidth === 'large') {
      headerImage.marginTop = '20px'
      headerImage.borderTopLeftRadius = '20px'
      headerImage.borderTopRightRadius = '20px'
    }
  }

  const headingStyle:any = {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "24px",
    fontWeight: "700",
    backgroundColor:'rgba(255, 255, 255, .3)',
    backdropFilter: 'saturate(180%) blur(3px)',
    float:'left',
    padding:2
  }

  if (deviceWidth === "tiny" || deviceWidth === 'smaller') {
    headingStyle.fontSize = "20px"
    headingStyle.fontWeight = "700"
    if (pod.description) headingStyle.marginBlockStart = '2px'
  }


  return <Box sx={{backgroundColor: "whitesmoke"}}>
          <Box sx={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: `${1280 + (2*containerPadding)}px`,
            paddingLeft: `${containerPadding}px`,
            paddingRight: `${containerPadding}px`,
            marginBottom: "0px",
            ...headerImage
          }}>
            <Box sx={headerStyle}>
              <OutOfSync />
              <h2 style={{color: "rgba(0, 0, 0, 0.87)", fontSize: "24px", fontWeight: "700", backgroundColor:'rgba(255, 255, 255, .3)',  backdropFilter: 'saturate(180%) blur(3px)', float:'left', padding:2}}>
                {pod.name}
              </h2>
              {pod.description ? <div style={{
                backgroundColor:'rgba(255, 255, 255, .3)',
                backdropFilter: 'saturate(180%) blur(3px)',
                padding:4,
                float:'left',
                clear:'both',
                }}>{pod.description}</div> : null}
            </Box>
            <Menu />
          </Box>
            <ConfirmDialog
              title={t('Pod Out of Sync')}
              info={t('The Pod seems to be out of sync. To make sure') as string}
              open={Boolean(confirmPodReset)}
              handleClose={() => { setConfirmPodReset(null); }}
              onOk={() => { if (confirmPodReset) podStore.resetPod(confirmPodReset) }}
            />
        </Box>

}

export default observer(PodHeader)
