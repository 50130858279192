import { useLayoutEffect } from "react"
import { observer } from "mobx-react-lite"
import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import React from "react"

import { PodClass } from "../../classes/Pod"
import { useStore } from '../../contexts/store'

import Subtabs from "../Elements/Subtabs"
import Permissions from "./Settings/Permissions"
import Appearance from "./Settings/Appearance"
import PdfExporter from "./Settings/PdfExporter"

const Settings = ({ initScrollPos, activeSubTabIndex }: {initScrollPos: (tab: string) => void, activeSubTabIndex?:string}) => {
  const { podStore, sessionStore } = useStore()
  const isAdmin:boolean = podStore.pod?.isAllowed('deletePod', podStore.pod?.podId) ? true : false

  const { t } = useTranslation()
  const session = sessionStore.session

  useLayoutEffect(() => {
    initScrollPos("settings")
  })

  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  return <Box className={"overflowWrapper"}>
    <Subtabs context="settings" activeKey={activeSubTabIndex} tabs={[
      {
        key:'appearance',
        label: t('Appearance'),
        content: <Appearance />
      },{
        key:'permisisons',
        label: t('Permissions'),
        content: <Permissions />
      },{
        key:'export',
        label: t('Export&Share'),
        content: <PdfExporter nodeId={Object.keys(pod.content.pdfFiles)} />
      },
    ]} />
  </Box>

}

export default observer(Settings)