import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { Box, Button, CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useStore } from '../../../contexts/store'
import {
  PDFDocument,
} from 'pdf-lib'
import { saveAs } from 'file-saver'
import api, {env} from '../../../api/api'
import { humanReadableBytes } from "../../../helper/Helper"
import { richPdfData } from "../../../../../types/ShrimpRichPdf"
import { Tag } from "../../../../../types/Content"
import { downloadZip } from "client-zip"
import { Pod } from "../../../../../types/Pod"
import { alert } from "../../../stores/alertStore"
import JSZip from "jszip"


export type FileInfo = {
  file:ArrayBuffer,
  uploadName:string,
  shrimpData: any,
}

type Zippable = {
  name: string,
  size: number,
  input: Uint8Array
}

//@ts-ignore
window.downloadZip = downloadZip

// { file, setFile } : { file:FileInfo|undefined, setFile:Function }
const PdfExporter = ({nodeId} : {nodeId:string|string[]}) => {

  const [ zipFile, setZipFile ] = useState<null|Blob>(null)
  const [ step, setStep ] = useState<null|string>(null)

  const { podStore, sessionStore, alertStore } = useStore()
  const { t } = useTranslation()

  const pod = podStore.pod
  if (!pod) return null
  const podx = pod as Pod

  const enabled = (sessionStore.session.user.userId === podx.creator?.userId)
                  || (pod?.checkForRole('Admin'))

  const doExport = async(nodeId:string) => {
    const node = podStore.pod?.content.pdfFiles[nodeId]
    const tags:{[tagId:string]: Tag} = podStore.pod?.content.tags || {}
    if (!node) return false
    setStep(t('Prepping') + ` ${node.name}`)

    const tagIdsInNode:{[tagId:string]:boolean} = {}
    Object.keys(node.taggings).forEach((interactionId:string) => { tagIdsInNode[node.taggings[interactionId].tagId] = true })

    // fetch the PDF
    setStep(t('Downloading') + ` ${node.name}`)
    const res:Response = await api.fetch(`${env.backendBaseUrl}/pdf/${nodeId}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      pipeThrough:true,
      headers: {
        'X-SHRIMP-Id': sessionStore.session ? sessionStore.session.sessionId : "",
      }
    }) as Response

    const pdf = res.status === 200 ? await res.arrayBuffer() : null

    if (pdf) {
      setStep(t('Compiling') + ` ${node.name}`)

      const threadIds = Object.keys(pod.content.threads)
      const threadIdsForThisNode = threadIds.filter((threadId:string) => pod.getInteractionFromThreadId(threadId)?.anchor.nodeId === nodeId)

      const pdfDoc = await PDFDocument.load(pdf, {ignoreEncryption: true})

      // build the metadata
      const shrimpData:richPdfData = {
        version: 0.1,
        originNodeId: nodeId,
        downloadNodeId: nodeId,
        node: {
          name: node.name,
          description: node.description,
          hash: node.hash,
          weight: node.weight,
          annotations: node.annotations,
          comments: node.comments,
          emotions: node.emotions,
          links: node.links,
          readingQuestions: node.readingQuestions,
          taggings: node.taggings,
          weblinks: node.weblinks,
        },
        context: {
          folder: node.folderId ? pod.content.folders[node.folderId] : null,
          tags: Object.keys(tagIdsInNode).map((tagId:string) => tags[tagId]),
          threads: threadIdsForThisNode.map((threadId:string) => pod.content.threads[threadId]),
        }
      }

      // combine
      const buf = new TextEncoder().encode(JSON.stringify(shrimpData))

      await pdfDoc.attach(buf, `Shrimp`, {
        mimeType: 'application/json',
        description: 'SHRIMP Data',
        creationDate: new Date(),
        modificationDate: new Date(),
      })

      // export
      const pdfBytes = await pdfDoc.save()
      //setStep('')
      return {
        name: node.folderId ?  `${pod.content.folders[node.folderId].name}/${node.name.replace('/', '_')}.pdf` : `${node.name.replace('/', '_')}.pdf`,
        size: pdfBytes.byteLength,
        input: pdfBytes
      }
    }

    alertStore.push(alert(t(`Could not include file "{{filename}}."`, {filename: node.name}), 'error'))
    return false
  }

  if (typeof nodeId === 'string') {
    return <Box>
      <Button disabled={!enabled} onClick={async () => {
        const file = await doExport(nodeId)
        if (file) window.open(URL.createObjectURL(new Blob([file.input], {type: "application/pdf"})))
      }}>{t('Download')} {nodeId}</Button>
    </Box>
  }

  if (Boolean(zipFile) || Boolean(step)) return <Box>
      <Button disabled={!enabled || !Boolean(zipFile) || Boolean(step)} variant='contained' onClick={() => { if (zipFile) {
        saveAs(zipFile, `${pod.name}.zip`)
      }}}>
        {step ? <><CircularProgress size={12} sx={{mr:1}} /> {step}</> : t('Download Zip ({{size}})', {size: humanReadableBytes(zipFile?.size || 0)})}
      </Button>
  </Box>

  return <Box>
    <Button disabled={!enabled || Boolean(zipFile) || Boolean(step)} onClick={async () => {
      var files:(false|{
        name: string,
        size: number,
        input: Uint8Array
      })[] = []
      try {
        //files = await Promise.all(nodeId.map((nid:string) => doExport(nid)))
        for(var i=0; i<nodeId.length; i++) {
          files.push(await doExport(nodeId[i]))
        }
      }
      catch (e) {
        console.warn(e)
        alertStore.push(alert(t(`Could not create all exports`) + ': ' + e, 'error'))
      }

      setStep(t('Zipping'))
      const successfulFiles:{
        name: string,
        size: number,
        input: Uint8Array
      }[] = files.filter(f => f !== false) as {
        name: string,
        size: number,
        input: Uint8Array
      }[]
      try {
        const zip = new JSZip()
        for(const f of successfulFiles) {
          zip.file(f.name, f.input)
        }

        //console.log(successfulFiles)
        //await downloadZip(successfulFiles.slice(0,19)).blob()
        //const blob = await downloadZip(successfulFiles).blob()
        const blob = await zip.generateAsync({
          type:'blob'
        })
        console.log(blob)
        setZipFile(blob)
      }
      catch(e) {
        console.warn(e)
        alertStore.push(alert(t(`Could not create zip file`) + ': ' + e, 'error'))
      }
      setStep('')

      }}>{t('Zip All for Export')}
    </Button>
  </Box>


}

export default observer(PdfExporter)