import { createElement, useEffect, useRef } from 'react'
import 'emoji-picker-element'

const EmojiPickerElement = ({onEmojiClick}: {onEmojiClick: (event: any) => void}) => {
  const ref = useRef<any>(null)

  useEffect(() => {
    ref.current.dataSource = '/emoji.json'
    ref.current.addEventListener('emoji-click', onEmojiClick)
    return () => {
      if(ref && ref.current) {
        ref.current.removeEventListener('emoji-click', onEmojiClick)
      }
    }
  }, [])

  return createElement('emoji-picker', { ref })
}

export default EmojiPickerElement