export const environment:{[key: string]: any} = {
  frontendUrl: 'https://app.shrimpp.de',
  shortnameRedirectUrl: 'https://go.shrimpp.de',
  backendBaseUrl: 'https://backend.shrimpp.de',
  backendPdfUrl: 'https://backend-pdf.shrimpp.de',
  dev: false,
  httpDefaultTimeout: 80000,
  idps: [{
    name: 'satosa',
    loginEndpoint: 'login_satosa',
    description: '',
  },{
    name: 'local',
    loginEndpoint: '*local',
    description: '',
  }]
}