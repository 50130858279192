import * as React from 'react'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { PodClass } from '../../classes/Pod'
import Loading from '../Loading'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import Infobox from '../Infobox'

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const LoadingPod = ({pod}: {pod: PodClass}) => {
  const { t } = useTranslation()

  const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  function niceBytes(x: any){
    let l = 0, n = parseInt(x, 10) || 0;
    while(n >= 1024 && ++l){
        n = n/1024;
    }
    return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }

  const loadStatus = pod.loadStatus
  if(loadStatus === undefined) return <Loading info="Loading Pod" />

  const annotations = pod.getPdfFiles().map((f: any) => f.annotations ? Object.keys(f.annotations).length : 0).reduce((acc: any, f: any) => acc+=f, 0)
  const comments = pod.getPdfFiles().map((f: any) => f.comments ? Object.keys(f.comments).length : 0).reduce((acc: any, f:any) => acc+=f, 0)
  const sumInteraction = parseInt(annotations) + parseInt(comments)

  return (
    <Infobox>
      <Box>
        <h1 style={{padding: "5px", margin: 0}}>{t('Loading')} Pod</h1>
        {niceBytes(JSON.stringify({...pod}).length)}
      </Box>
      <LinearProgressWithLabel value={loadStatus} />
      <Box sx={{p: "5px"}}>
        {t('Contains')} {pod.getPdfFiles().length} {t('Files')} {t('and')} {sumInteraction} {t('Interactions')}
      </Box>
    </Infobox>
  )
}

export default observer(LoadingPod)