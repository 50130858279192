import { t } from "i18next"
import { useStore } from "../../contexts/store"
import { Box, Button } from "@mui/material"

const SaveCancel = ({handleSave, handleClose}: {handleSave: () => void, handleClose: () => void}) => {
  const { uiStore } = useStore()
  const deviceWidth = uiStore.deviceWidth

  return (
    <Box sx={{display: "flex", gridGap: "10px", flexWrap: "wrap", justifyContent: "end", margin: "10px"}}>
      <Button onClick={handleClose} variant="outlined" sx={{backgroundColor: "white", flex: (deviceWidth === "tiny") ? 1 : ""}}>
        {t('Cancel')}
      </Button>
      <Button onClick={handleSave} variant="contained" sx={{ flex: (deviceWidth === "tiny") ? 1 : "" }}>
        {t('Save')}
      </Button>
    </Box>
  )
}

export default SaveCancel