import React from "react"
import { Card } from '@mui/material'

const Infobox = (props:any) => {

  const sx = {
    justifySelf: "center",
    alignSelf: "center",
    padding: "25px",
    maxWidth: 500,
    overflow: 'auto',
    ...props.sx
  }

  return <Card elevation={12} sx={sx}>
      {props.children}
  </Card>
}

export default Infobox