import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../contexts/store'
import podStore from '../../stores/podStore';
import { useLocation } from "wouter";
import api from '../../api/api';

const DevSyncStatus: React.FC = () => {
  const { broadcastStore, opStore } = useStore()
  const [ previousServiceWorkerPodFingerprint, setPreviousServiceWorkerPodFingerprint ] = useState('')
  const navigate = useLocation()[1]

  const pod = podStore.pod

  var localPodFingerprint = '...'
  var serviceWorkerPodFingerprint = '...'
  var backendFingerprint = '...'

  if (pod) {
    const podId:string = pod.podId
  
    if (pod.serviceWorkerFingerprint) serviceWorkerPodFingerprint = pod.serviceWorkerFingerprint
    if (pod.backendFingerprint) backendFingerprint = pod.backendFingerprint
  
    localPodFingerprint = pod.fingerprint()
  }

  const style = {
    fontSize:9,
    color: opStore.static.busy ? '#f00' : '#fff',
    borderLeft: opStore.static.busy ?  '4px solid red' : '1px solid black',
  }

  const fingerprintStyle1 = (localPodFingerprint !== serviceWorkerPodFingerprint) ? { color: 'orange' } : { color: 'lightgreen' }
  const fingerprintStyle2 = (serviceWorkerPodFingerprint !== backendFingerprint) ? { color: 'orange' } : { color: 'lightgreen' }

  return <table style={style} border={0} cellPadding={0} cellSpacing={0} onClick={() => { navigate('/system') }}>
    <tbody>
      <tr><td>Client:</td><td>{localPodFingerprint}</td></tr>
      <tr><td>ServiceWorker:&nbsp;</td><td style={fingerprintStyle1}>{serviceWorkerPodFingerprint}</td></tr>
      <tr><td>Backend:&nbsp;</td><td style={fingerprintStyle2}>{backendFingerprint}</td></tr>
    </tbody>
  </table>
}

export default observer(DevSyncStatus)