import { t } from 'i18next'
import React from 'react'
import uiStore from '../../../stores/uiStore'
import podStore from '../../../stores/podStore'
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer'
import { PodClass } from '../../../classes/Pod'
import { env } from '../../../api/api'
import QRCode from "qrcode"

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white'
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "row",
  },
  user: {
    display: "flex",
    justifyContent: "center"
  },
  attributes: {
    display: "flex",
    flexDirection: "row"
  }
})

const AddUserPdfDocument = () => {
  const instantUserList = uiStore.instantUserList
  const pod: PodClass | null = podStore.pod

  if(pod === null) return null

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {instantUserList.map(user => {
          const urlPromise = QRCode.toDataURL(`${env.shortnameRedirectUrl}/${pod.podIdHr || pod.podId}?login=${user.login}`)
          return <View  key={user.id} style={styles.section}>
          <Image style={{height: "110px", width: "110px"}} src={urlPromise} />
          <View style={styles.user}>
            <Text style={styles.attributes}>{t('Pod')}: {pod.podIdHr}</Text>
            <Text style={styles.attributes}>{t('Login')}: {user.login}</Text>
            <Text style={styles.attributes}>{t('Password')}: {user.password}</Text>
            <Text style={styles.attributes}>{t('Url')}: {`${env.shortnameRedirectUrl}/${pod.podIdHr || pod.podId}`}</Text>
          </View>
        </View>
        })}
      </Page>
    </Document>
  )
}

export default AddUserPdfDocument