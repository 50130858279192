import { useStore } from '../../contexts/store'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime.js'
import { observer } from 'mobx-react-lite'
dayjs.extend(relativeTime)

const TimeFromNow = ({timestamp}: {timestamp: number}) => {
  const { uiStore } = useStore()
  // timer is only used for re-rendering the component and update relative time
  const timer = uiStore.timer
  if(!timer) console.warn("timer stopped")

  if(!timestamp) return null
  return (
    <>
      {dayjs(timestamp).fromNow()}
    </>
  )
}

export default observer(TimeFromNow)