import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../contexts/store'

import { Snackbar } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import WifiOffIcon from '@mui/icons-material/WifiOff'
import WifiIcon from '@mui/icons-material/Wifi'
import { navigate } from 'wouter/use-location'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props: any,
  ref: any,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Alerts = () => {
  const { alertStore } = useStore()
  const alert = alertStore.getAlert()

  let icon = null
  if(alert && alert.icon) {
    switch (alert.icon) {
      case 'WifiOffIcon':
        icon = <WifiOffIcon />
        break;
      case 'WifiOnIcon':
        icon = <WifiIcon />
        break;

      default:
        break;
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    // if(alert) alert.display = false;
    event?.stopPropagation()
    if(alert) alertStore.setDisplay(alert, false)
    return false
  };

  if(alert) return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={alert.display}
        onClose={handleClose}
        TransitionProps={{ onExited: alertStore.process }}
        autoHideDuration={6000}
      >
        <Alert onClose={handleClose} severity={alert.kind} sx={{ width: '100%' }} icon={icon ? icon : ''} onClick={() => { if (alert.link) navigate(alert.link) }} style={{cursor: alert.link ? 'pointer' : 'default'}}>
          {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
          {alert.message.split(/\n/).map((s, i) => <div key={i}>{s}</div>)}
        </Alert>
      </Snackbar>
  )
  else return <></>
}

export default observer(Alerts);