import { observer } from "mobx-react-lite"
import { iWeblink } from "../../../../types/Interaction"
import ViewTracker from "./ViewTracker"
import BlockquoteBox from "./BlockquoteBox"
import { Box } from "@mui/material"

const Weblink = ({weblink}: {weblink: iWeblink}) => {

  return (
    <ViewTracker type="weblink" id={weblink.interactionId}>
      <BlockquoteBox interaction={weblink} />
      <Box sx={{padding: "20px", display: "grid", gridTemplateRows: "min-content min-content"}}>
        <Box sx={{padding: "15px", margin: "0 5px 5px 0", color: "#000556", fontWeight: "300", fontSize: "17px"}}>
          {weblink.label}
        </Box>
        <Box sx={{display: "grid", backgroundColor: "#dedefd", border: "1px solid blue", borderRadius: "5px", p: "10px", wordBreak: "break-all"}}>
          <a href={weblink.url} rel="noreferrer" target="_blank">{weblink.url}</a>
        </Box>
      </Box>
    </ViewTracker>
  )
}

export default observer(Weblink)