import { observer } from "mobx-react-lite"
import { useStore } from "../../../contexts/store"
import { useParams } from "../../../helper/Helper"
import ActiveConversation from "./AcitveConversation"
import ConversationList from "./ConversationList"
import { Box } from "@mui/material"


const Conversations = ({activeConversationId}: {activeConversationId: string | undefined}) => {
  const { podId } = useParams()
  const { uiStore } = useStore()

  // large screen: show list of conversations and the thread side by side
  const SharedView = () => {
    return (
      <Box className="overflowWrapper"  sx={{display: "grid", gridTemplateColumns: "2fr 3fr"}}>
        <ConversationList podId={podId} activeConversationId={activeConversationId} />
        {activeConversationId &&
          <ActiveConversation podId={podId} activeConversationId={activeConversationId} />
        }
      </Box>
    )
  }

  // small screen: show list of conversation or thread in single view
  const SplitView = () => {
    if(activeConversationId) return (
      <ActiveConversation podId={podId} activeConversationId={activeConversationId} />
    )
    else return (
      <ConversationList podId={podId} activeConversationId={activeConversationId} />
    )
  }

  return (
    <Box className="overflowWrapper" sx={{borderTop: "1px solid rgba(63, 67, 80, 0.16)"}}>
      {(uiStore.deviceWidth === "tiny" || uiStore.deviceWidth === 'smaller' || uiStore.deviceWidth === "small") ? (
        <SplitView />
      ) : (
        <SharedView />
      )}
    </Box>
  )
}

export default observer(Conversations)
