import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { useStore } from "../../../contexts/store"
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, MenuItem } from "@mui/material"
import React from "react"
import { PodClass } from "../../../classes/Pod"
import { env } from "../../../api/api"
import Select, { SelectChangeEvent } from '@mui/material/Select';

// message from thread
const DownloadOptions = ({nodeId, open, handleClose}: {nodeId: string, open: boolean, handleClose: any}) => {
  const { podStore, sessionStore } = useStore()
  const pod:PodClass|null = podStore.pod
  const selectionOptions = [ 'annotation', 'comment', 'emotion', 'link', 'readingQuestion', 'tagging', 'weblink' ]
  const defaultSelection:{[interactionType:string]: boolean} = {}
  selectionOptions.forEach(o => { defaultSelection[o] = Boolean(pod && pod.allowDownload.indexOf(o)>=0) })
  const [ selection, setSelection ] = React.useState<{[interactionType:string]: boolean}>(defaultSelection)
  const [ fileType, setFileType ] = React.useState<string>('pdf')
  const { t } = useTranslation()

  if (!pod) return null

  const handleChange = (option:string) => {
    setSelection({
      ...selection,
      [option]: !selection[option]
    })
  }

  const triggerDownload = () => {
    const form = document.createElement('form');
    const append = (name:string, value:string) => {
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = name
      hiddenField.value = value
      form.appendChild(hiddenField)
    }
    form.method = 'POST'
    form.action = `${env.backendPdfUrl}/getPdf`
    form.target = '_blank'
    // create input field with sessionId
    append('podId', pod.podId)
    append('nodeId', nodeId)
    append('shrimpId', sessionStore.session ? sessionStore.session.sessionId : "")
    append('selection', JSON.stringify(selection))
    append('fileType', fileType)
    // add input field to html
    document.body.appendChild(form)
    form.submit()
  }

  return <Dialog open={open}>
    <DialogTitle>Download Options</DialogTitle>
    <DialogContent>
      {t('Please select the interaction types you want to include in your download:')}
      {
        selectionOptions.map((option) => {
          var allowed = false
          //@ts-ignore
          if (pod.allowDownload.indexOf(option)>=0) allowed = true
          return (<Box key={option}>
            <FormControlLabel control={<Checkbox checked={Boolean(selection[option])} onClick={(e) => handleChange(option)} disabled={!allowed} />} label={t(option)} />
          </Box>)
        })
      }
      <Box>
        <Select value={fileType} onChange={(event: SelectChangeEvent) => { setFileType(event.target.value) }}>
          <MenuItem value='pdf'>Annotated PDF</MenuItem>
          <MenuItem value='richPdf'>SHRIMP Rich PDF Export</MenuItem>
        </Select>
      </Box>
    </DialogContent>
    <DialogActions style={{textAlign:'right'}}>
      <Button onClick={handleClose}>{t('Cancel')}</Button>
      <Button variant='contained' onClick={() => { triggerDownload(); handleClose() }}>{t('OK')}</Button>
    </DialogActions>
  </Dialog>

}

export default observer(DownloadOptions)