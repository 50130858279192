import React, { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { Tooltip, IconButton } from '@mui/material'
import { useLocation } from 'wouter'

//const HeaderMenuButton:React.FC = (props: any) => {
const HeaderMenuButton = ({title, description, icon, to, onClick, ...props}: {
  title: string,
  description:string, 
  icon:ReactElement, 
  to?: string, 
  onClick?: any,
  props?:any
}) => {
  const navigate = useLocation()[1]

  return <Tooltip title={title}>
    <IconButton
      onClick={ (to ? () => { navigate(to) } : onClick) }
      size="large"
      edge="start"
      color="inherit"
      aria-label={description}
      sx={{ ml:1 }}
    >
      {icon}
    </IconButton>
  </Tooltip>
}

export default observer(HeaderMenuButton)