import dayjs from 'dayjs'
import { Box } from "@mui/material"

  // show date for activity
  const DateIndicator = ({tCreated, prevTCreated, sx}: {tCreated: number | null | undefined, prevTCreated: number | null | undefined, sx: object}) => {
    if(!tCreated) return null

    const day = dayjs(tCreated*1000).format('DD')
    const month = dayjs(tCreated*1000).format('MMM')
    //actual label
    const dateLabel: JSX.Element = (
      <Box sx={{
        ...sx,
        alignItems: "start",
        display: "grid",
        gridTemplateColumns: "max-content max-content",
        justifyItems: "center",
      }}>
        <Box sx={{
            backgroundColor: "#5952d3",
            color: "white",
            p: "10px",
          }}>
          {day}
        </Box>
        <Box sx={{
            backgroundColor: 'whitesmoke',
            border: "1px solid #cfcece",
            borderBottomRightRadius: "10px",
            color: "#525c76",
            p: "9px 15px",
          }}>
          {month}
        </Box>
      </Box>
    )
    // if no previous acitivy: show date
    if(!prevTCreated) return dateLabel

    // if prev time, check if timestamps differ
    if(prevTCreated) {
      // show date if the day, month or year is different from the previous
      const dateDiff = 'DD.MM.YYYY'
      const date = dayjs(tCreated*1000).format(dateDiff)
      const prevDate = dayjs(prevTCreated*1000).format(dateDiff)
      if(prevDate !== date) {
        return dateLabel
      }
    }
    return <Box />
  }

  export default DateIndicator