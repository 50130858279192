import { observer } from "mobx-react-lite"
import { t } from "i18next"
import { useStore } from "../../contexts/store"
import FreehandButton from "./Toolbar/FreehandButton"
import ColorPicker from "../Elements/ColorPicker"
import ZoomSlider from "./Toolbar/ZoomSlider"
import { Box, Divider } from "@mui/material"
import VisibilitySelection from "./Toolbar/VisibilitySelection"

const PdfFooter = ({storeId}: {storeId: string}) => {
  const { pdfMetaStore, uiStore } = useStore()
  const numPages = pdfMetaStore.getNumPage(storeId)
  const pagePos = pdfMetaStore.getPagePos(storeId)
  const currentPage = (pagePos && pagePos.length) ? Math.round(pagePos[0]) + 1 : null
  const color = uiStore.getAnnotationColor()

  return(
    <Box sx={{display: "grid", gridTemplateColumns: "auto min-content", alignItems: "center", borderTop: "1px solid lightgrey"}}>
      {(numPages !== 0 && numPages !== null && currentPage) &&
        <Box sx={{fontSize: "12px", marginLeft: "15px"}}>
          {t('Page {{current}} of {{total}}', {current: currentPage, total: numPages})}
        </Box>
      }
      <Box sx={{
          alignItems: "center",
          display: "grid",
          gridGap: "10px",
          gridTemplateColumns: "min-content min-content min-content min-content min-content min-content",
          justifyContent: "end",
          justifyItems: "center",
          margin: "5px 15px"
        }}>
        <ColorPicker color={color} setColor={(color) => {uiStore.setAnnotationColor(color)}} />
        <FreehandButton />
        <Divider orientation="vertical" />
        <VisibilitySelection />
        <Divider orientation="vertical" />
        <ZoomSlider storeId={storeId} />
      </Box>
    </Box>
  )
}

export default observer(PdfFooter)