import { t } from "i18next"
import { observer } from "mobx-react-lite"
import { useStore } from "../../contexts/store"
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material"

const FileSelector = ({nodeId, handleFileChange}: {nodeId: string, handleFileChange: (nodeId: string) => void}) => {
  const { podStore } = useStore()

  const pod = podStore.pod
  if (!pod) return null
  const files = pod.getPdfFiles().filter(pdf => !pdf.hidden)

  if(files.length < 1) return null
  const fileItems = files.map(file =>
    <MenuItem
      onClick={() => handleFileChange(file.nodeId)}
      key={file.name}
      value={file.nodeId}
    >{file.name}</MenuItem>
  )
  return(
    <Box sx={{ m: "5px 25px" }}>
      <FormControl fullWidth sx={{ backgroundColor: "whitesmoke", margin: "10px 0" }} size="small">
        <InputLabel id="file-selection-label">{t('Select Pdf')}</InputLabel>
        <Select
          labelId="file-selection-label"
          label={t('Select Pdf')}
          value={nodeId}
        >
          {fileItems}
        </Select>
      </FormControl>
    </Box>
  )
}

export default observer(FileSelector)