import { Box, Button } from "@mui/material"
import { t } from "i18next"

const AddUserStepperButtons = (
  {activeStep, disabledBack, disabledNext, loadingNext, handleBack, handleNext, steps}: {
    activeStep: number,
    disabledBack?: boolean,
    disabledNext?: boolean,
    loadingNext?: boolean,
    handleBack: () => void,
    handleNext: () => void,
    steps: string[]
  }) => {

  function buttonName(index: number) {
    if(loadingNext) return t('Loading')
    if(index === steps.length - 1) return t('Close')
    if(index === steps.length - 2) return t('Submit')
    return t('Next')
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: "20px"}}>
      <Button
        disabled={activeStep === 0 || disabledBack}
        onClick={handleBack}
        size="large"
        variant="outlined"
      >
        {t('Back')}
      </Button>
      <Box sx={{ flex: '1 1 auto' }} />
      <Button disabled={(disabledNext || loadingNext) ? true: false} size="large" onClick={handleNext} variant="contained">
        {buttonName(activeStep)}
      </Button>
    </Box>
  )
}

export default AddUserStepperButtons