import { observer } from "mobx-react-lite"
import { useStore } from "../../contexts/store"

const OverlayFreehandBox = ({pageNumber, hideFreehandBox}: {pageNumber: number, hideFreehandBox?: boolean}) => {
  const { uiStore } = useStore()
  const freehandBox = uiStore.freehandBox

  if(freehandBox === null || freehandBox.page !== pageNumber || hideFreehandBox === true) return null
  return (
    <div style={{
      border:'1px solid red',
      position:'absolute',
      backgroundColor:'#f006',
      left: Math.min(freehandBox.x0, freehandBox.x1),
      top:Math.min(freehandBox.y0, freehandBox.y1),
      width:Math.abs(freehandBox.x1 - freehandBox.x0),
      height:Math.abs(freehandBox.y1 - freehandBox.y0),
    }} />
  )
}

export default observer(OverlayFreehandBox)