import { useStore } from "../../contexts/store"
import { Box } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { observer } from "mobx-react-lite"

const ReadingProgress = ({folderId, nodeId}: {folderId?: string, nodeId?: string}) => {
  const { podStore } = useStore()
  const borderWidth = "9px"
  const diameter = "25px"
  const color = folderId ? "#baed93" : "#51cb11"
  let percentage = null

  // if nodeId: get reading progress for pdf file
  if(nodeId) percentage = podStore.getPdfViewProgress(nodeId)
  // if folderId: get reading progress for all files in folder
  if(folderId) {
    let percentageSum = 0
    const pdfFiles = podStore.getFolderFiles(folderId)
    for(const pdf of pdfFiles) {
        const pdfPercentage = podStore.getPdfViewProgress(pdf.nodeId)
        if(pdfPercentage) percentageSum += pdfPercentage
    }
    // if there is no file, do not show progress circle
    if(pdfFiles.length) percentage = percentageSum / pdfFiles.length
    else percentage = null
  }

  if(percentage === null) return null

  if(percentage >= 100) return (
    <CheckIcon sx={{
      backgroundColor: color,
      borderRadius: "50%",
      color: "white",
      height: diameter,
      width: diameter
    }} />
  )

  return (
    <Box sx={{
      width: diameter,
      height: diameter,
      position: "relative",
      display: "inline-grid",
      placeContent: "center",
      fontSize: "5px",
      fontWeight: "bold",
      '&::before': {
        border: "1px solid #dedede",
        content: "''",
        position: "absolute",
        borderRadius: "50%",
        inset: 0,
        background: `conic-gradient(${color} calc(${percentage}*1%),#0000 0)`,
        WebkitMask: `radial-gradient(farthest-side,#0000 calc(99% - ${borderWidth}),#000 calc(100% - ${borderWidth}))`,
        mask: `radial-gradient(farthest-side,#0000 calc(99% - ${borderWidth}),#000 calc(100% - ${borderWidth}))`,
      }
    }}>
    </Box>
  )
}

export default observer(ReadingProgress)
