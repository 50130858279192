import React from 'react'
import { useStore } from '../contexts/store'
import { useLocation } from 'wouter'
import { PodClass } from '../classes/Pod'

const WindowTitleSetter: React.FC = () => {
  const [ location ] = useLocation()
  const { podStore } = useStore()
  const path = location.split('/')
  switch(path[1]) {
    case 'pod':
      const pod:PodClass|null = podStore.pod
      if (!pod) return null
      if (pod && (pod.podId === path[2])) {
        if (path[3] === 'pdf') {
          document.title = `SHRIMP › ${pod.content.pdfFiles[path[4]]?.name}`
        }
        else {
          document.title = `SHRIMP › ${pod.name}`
        }  
      }
      break
    default:
      document.title = 'SHRIMP'
  }
  return null
}

export default WindowTitleSetter