import { t } from "i18next"
import { observer } from "mobx-react-lite"
import { useStore } from "../../contexts/store"
import { iEmotion } from "../../../../types/Interaction"
import dayjs from 'dayjs'
import { Box } from "@mui/material"

const Emotion = ({interaction}: {interaction: iEmotion}) => {
  const { podStore } = useStore()
  const pod = podStore.pod
  if (!pod) return null

  return(
    <Box sx={{ display: 'grid', alignSelf: "start", gridTemplateColumns: 'max-content auto', alignItems: 'center', gridGap: '10px' }}>
      <Box sx={{padding: "0 20px", display: "grid", fontSize: "45px"}}>
        {interaction.label}
      </Box>
      {pod.userInfos[interaction.userId]?.userName || t('unknown')}<br />{t('user_on_date')} {dayjs(interaction.tModified * 1000).format('D MMM, HH:mm')}
    </Box>
  )
}

export default observer(Emotion)