import { useTranslation } from "react-i18next"
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import React, { useEffect } from "react"

type textInputType = {
  type: 'string',
  minLength?: number,
  maxLength?: number
} | {
  type: 'number'
}

// message from thread
const Prompt = ({title, info, open, handleClose, onOk, type, defaultValue }: {title: string, info:string|JSX.Element, open: boolean, handleClose: any, onOk?: any, type?: textInputType, defaultValue?:string|number}) => {
  const [ text, setText ] = React.useState('')

  const { t } = useTranslation()
  useEffect(() => {
    if (defaultValue) setText(defaultValue as string)
  }, [open])

  const textChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var newValue = event.target.value
    if (type && (type.type === 'string') && (type.maxLength)) newValue = newValue.substring(0, type.maxLength)
    if (type && (type.type === 'number')) newValue = (Number(newValue.replace(/[^0-9\.]/g, '')) + '') || ''
    setText(newValue)
  }

  const isValid = () => {
    if(type && type.type === 'string' && type.minLength && text.trim() === '') return false
    if(type && text && (type.type === 'string') && type.minLength && text.length < type.minLength) return false
    if(type && text && (type.type === 'string') && type.maxLength && text.length > type.maxLength) return false
    return true
  }

  return <Dialog open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <p>{info}</p>
      <TextField fullWidth={true} style={{margin:'8px 0'}} label={title} value={text} onChange={textChange} /><br />
      {(type && type.type === 'string' && (type.minLength || type.maxLength) ) &&
        <Alert severity="info">
          <Box> {type.minLength ? t('The input must contain at least {{minLength}} characters.', {minLength: type.minLength}) : ""} </Box>
          <Box> {type.maxLength ? t('The input may contain a maximum of {{maxLength}} characters.', {maxLength: type.maxLength}) : ""} </Box>
        </Alert>
      }
    </DialogContent>
    <DialogActions style={{textAlign:'right'}}>
      <Button onClick={() => { handleClose(); setText('') }}>{t('Cancel')}</Button>
      <Button disabled={!isValid()} variant='contained' onClick={() => { if (onOk) onOk(text); handleClose(); setText('') }}>{t('OK')}</Button>
    </DialogActions>
  </Dialog>

}

export default Prompt