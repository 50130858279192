import React from "react"
import { Button } from "@mui/material"
import { useTranslation } from 'react-i18next'
import sessionStore from "../stores/sessionStore"
import { Link } from "wouter"

const ErrorFallback = ({
  error, resetErrorBoundary, context, reportId=0
}: {
  error:any,
  resetErrorBoundary:any,
  context?: string|undefined,
  reportId?:number,
}) => {
  const { t } = useTranslation()
  return (
    <div style={{padding:8}}>
      <h1>{t('Apologies! It seems that there was an error')}... {reportId ? `(Reported #${reportId})` : null}</h1>
      <p style={{color:'red'}}>{error.message}</p>
      <pre style={{border:'1px solid #900', borderRadius:4}}>{JSON.stringify(error, null, 2)}</pre>
      <div>
        <p>You may continue to use the app or trigger a reset:</p>
        <Button variant="outlined" onClick={() => { sessionStore.resetCache({
          cache: true, 
          idbStatic: true, 
          idbOpLog: true,
          serviceWorker:true
        }) }}>Hard Reset</Button> &nbsp;
        <Button variant="outlined" onClick={() => { sessionStore.resetCache({
          cache: true, 
          idbStatic: true, 
          serviceWorker:true
        }) }}>Soft Reset</Button> &nbsp;
        <Button variant="contained" component={Link} to="/" onClick={() => {
          setTimeout(() => {
            window.document.location.href="/"
            window.document.location.reload()
          }, 300)
        }} >Continue</Button>
      </div>
    </div>
  )
}

export default ErrorFallback