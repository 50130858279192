import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'wouter'
import { useStore } from '../../contexts/store'
import { useTranslation } from 'react-i18next'
import UserInfoAvatar from '../Elements/UserInfoAvatar'
import { Box, Button } from '@mui/material'
import KeyIcon from '@mui/icons-material/VpnKey'
import ChangeMyPasswordModal from '../Pod/ChangeMyPasswordModal'

const Account: React.FC = () => {
  const { t } = useTranslation()
  const { sessionStore } = useStore()
  const user = sessionStore.session.user
  const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false)

  return <Box sx={{ p: 5 }}>
      <h1>{t('Account Information')}</h1>
      <table>
        <tbody>
          <tr>
            <td>{t('Your official name (idpProvidedUserName)')}: </td>
            <td colSpan={2}>{user.idpProvidedUserName} ({user.userId})</td>
          </tr>
          <tr>
            <td>
            {sessionStore.session.user.provider === 'local' &&
              <Button sx={{padding: "5px 15px", margin: "5px 0"}} endIcon={<KeyIcon />} variant='contained' onClick={() => setPasswordModalIsOpen(true)}>
                {t('changeMyPassword')}
              </Button>
            }
            </td>
          </tr>
          <tr>
            <td colSpan={3}><br />{t('Pseudonyms')}:</td>
          </tr>
          { sessionStore.session.pods.map(pod => {
            return <tr key={pod.podId}>
              <td>
                <Link to={`/pod/${pod.podId}`}>{pod.name}</Link>
              </td>
              <td width="32">
                <UserInfoAvatar userId={user.userId} podId={pod.podId} />
              </td>
              <td>
                {(pod.userInfos && pod.userInfos[user.userId]) ? pod.userInfos[user.userId].userName : <em>not loaded</em>}
              </td>
            </tr>
          }) }
        </tbody>
      </table>
      <ChangeMyPasswordModal username={sessionStore.session.user.login} isOpen={passwordModalIsOpen} handleClose={() => {setPasswordModalIsOpen(false)}} />
    </Box>
}

export default observer(Account)