import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { Op } from "../../../../../types/Ops"
import { useStore } from "../../../contexts/store"
import { Link } from "wouter"

import UserInfoAvatar from "../../Elements/UserInfoAvatar"
import { iComment, iEmotion, iLink, iReadingQuestion, iTag, iWeblink } from "../../../../../types/Interaction"
import { Message, MessageAndThread } from "../../../../../types/Message"
import TimeFromNow from "../../Elements/TimeFromNow"
import { Box, Link as MuiLink } from "@mui/material"

// message from thread
const Operation = ({op, userChanged}: {op: Op, userChanged?: boolean}) => {
  const { t } = useTranslation()
  const { podStore } = useStore()
  const pod = podStore.pod

  if(!op) {
    console.warn("missing op in Operation")
    return null
  }

  if (!pod) {
    console.warn("no pod loaded")
    return null
  }

  const podId = pod.podId
  if (op.podId !== podId) {
    console.warn(`invalid podId ${op.podId} in operation when pod loaded is ${podId}`)
    return null
  }
  // get user object
  const userInfo = podStore.getUserInfo(op.data?.userId, podId)

  const abridge = (s:string) => (s.length > 200 ? s.substring(0, 150)+ `... (${t('+{{cut}} chars', {cut:s.length-150})})` : s)

  // consider edited changes in activity stream
  function getInteraction(opInteraction: iComment | iEmotion | iLink | iReadingQuestion | iTag | iWeblink) {
    const interactionId = opInteraction.interactionId
    const nodeId = opInteraction.anchor.nodeId
    const interaction = (interactionId && nodeId && pod) ? pod.getInteraction(interactionId) : null
    if(interaction) return interaction
    return opInteraction
  }

  // visible content from interactions
  const AddComment = (comment: iComment) => abridge(comment.label)
  const AddMessage = (message: Message|MessageAndThread) => abridge(message.text)
  const AddReadingQuestionReply = (message: Message|MessageAndThread) => t('Reply of approx. {{words}} words.', {words: message.text.split(/[^\w]+/).length })
  const AddLink = (link: iLink) => (link.label)
  const AddWeblink = (weblink: iWeblink) => (weblink.label)
  const AddEmotion = (emotion: iEmotion) => (emotion.label)
  const AddReadingQuestion = (readingQuestion: iReadingQuestion) => abridge(readingQuestion.label)
  const AddReaction = (reaction: any) => (reaction.reactionId)
  const AddTagging = (tag: iTag) => {
    const tagProp = podStore.getTagProp(tag.tagId)
    return "#"+tagProp?.name
  }

  let content = null
  let description = ""
  let interactionUrl = `/pod/${podId}`
  switch(op.op) {
    case 'addComment':
      const comment = getInteraction(op.data)
      content = AddComment(comment as iComment)
      description = t('added a comment')
      interactionUrl += `/pdf/${op.data.anchor.nodeId}/comment/${op.data.interactionId}`
      break
    case 'addMessage':
      // search interaction from thread
      const interaction = pod.getInteractionFromThreadId(op.data.threadId)
      if(interaction) {
        const interactionType = interaction.interactionType
        const message = pod.getMessage(op.data.messageId)
        if(interactionType === "readingQuestion") {
          content = message ? AddReadingQuestionReply(message) : AddReadingQuestionReply(op.data)
          description = t('replied to a reading question')
        }
        if(interactionType === "comment" && message) {
          content = message ? AddMessage(message) : AddMessage(op.data)
          description = t('replied to a comment')
        }
        interactionUrl += `/pdf/${interaction.anchor.nodeId}/comment/${interaction.interactionId}`
      }
      break
    case 'addLink':
      const link = getInteraction(op.data)
      content = AddLink(link as iLink)
      description = t('added a link')
      interactionUrl += `/pdf/${op.data.anchor.nodeId}/link/${op.data.interactionId}`
      break
    case 'addTagging':
      const tagging = getInteraction(op.data)
      content = AddTagging(tagging as iTag)
      description = t('added a tagging')
      interactionUrl += `/pdf/${op.data.anchor.nodeId}/tagging/${op.data.interactionId}`
      break
    case 'addWeblink':
      const weblink = getInteraction(op.data)
      content = AddWeblink(weblink as iWeblink)
      description = t('added a weblink')
      interactionUrl += `/pdf/${op.data.anchor.nodeId}/weblink/${op.data.interactionId}`
      break
    case 'addEmotion':
      const emotion = getInteraction(op.data)
      content = AddEmotion(emotion as iEmotion)
      description = t('added an emotion')
      interactionUrl += `/pdf/${op.data.anchor.nodeId}/emotion/${op.data.interactionId}`
      break
    case 'addReadingQuestion':
      const readingQuestion = getInteraction(op.data)
      content = AddReadingQuestion(readingQuestion as iReadingQuestion)
      description = t('added a reading question')
      interactionUrl += `/pdf/${op.data.anchor.nodeId}/readingQuestion/${op.data.interactionId}`
      break
    case 'addReaction':
      content = AddReaction(op.data)
      description = `${t('added a reaction')} in ${t(op.data.type)}`
      // check whether reaction is part of an interaction or message
      if(op.data.type === 'message') {
        const messageObject = pod?.getMessage(op.data.id)
        if(messageObject) {
          const reactionObject = pod?.getInteractionFromThreadId(messageObject.threadId)
          if(reactionObject) {
            interactionUrl += `/pdf/${reactionObject.anchor.nodeId}/${reactionObject.interactionType}/${reactionObject.interactionId}`
          }
        }
      } else {
        const reactionObject = pod.findInteraction(op.data.id)
        if(reactionObject) {
          interactionUrl += `/pdf/${reactionObject.nodeId}/${reactionObject.interactionType}/${op.data.id}`
        }
      }
      break
    case 'addUserToPod':
      description = t('joined the Pod')
      break
    case 'removeUserFromPod':
      description = t('has left the Pod')
      break
    case 'addPdfFile':
      const nodeId = op.data.nodeId
      // use current file name
      content = podStore.getFilename(nodeId)
      description = t('added a pdf to the Pod')
      interactionUrl += `/pdf/${op.data.nodeId}`
      break
    default:
      console.warn("unknown op:", op.op)
      return null
  }

  let username = (userInfo && userInfo.userName !== t("unknown")) ? userInfo.userName : t("A Person")
  return (
    <Box sx={{
        borderTop: userChanged ? "1px solid #e2e2e2" : "",
        display: "grid",
        gridTemplateColumns: "auto min-content",
        padding: "5px 0"
      }}>
      <Box sx={{padding: "8px 20px", fontSize: "18px"}}>
        {/* interaction description, Link = wouter, MuiLink = style passed props from wouter */}
        <Link href={interactionUrl}>
          <MuiLink underline="hover">
            <b>{username}</b> {description}
          </MuiLink>
        </Link>
        {/* content from interaction */}
        <Box sx={{color: "#555555"}}>
          {content}
        </Box>
        {/* interaction creation time */}
        <Box sx={{marginTop: "7px", color: "#808080", fontSize: "14px", fontStyle: "italic"}}>
          <TimeFromNow timestamp={op.tCreated ? op.tCreated*1000 : 0} />
        </Box>
      </Box>
      {/* show avatar symbol if user changes */}
      <Box sx={{m: "5px"}}>
        {userChanged && <UserInfoAvatar userId={userInfo?.userId} podId={pod.podId}  />}
      </Box>
    </Box>
  )
}


export default observer(Operation)
