import { IconButton } from "@mui/material"
import { Interaction } from "../../../../../types/Interaction"
import { navigate } from "wouter/use-location"
import { observer } from "mobx-react-lite"
import { useStore } from "../../../contexts/store"
import ViewTracker from "../../Elements/ViewTracker"

// shows interaction as marginalia icon
const MarginaliaEmoji = (
  {
    item,
    pdfId,
    pdfUrl,
    radius
  }: {
    item: Interaction,
    pdfId: string,
    pdfUrl: string,
    radius: number
  }
) => {
  const { podStore, uiStore } = useStore()
  const pod = podStore.pod
  if (!pod) return null
  // register changes to emoji
  const emotion = pod.getInteraction(item.interactionId)
  if(emotion === null) return null

  let opacity = uiStore.hideInteractions['emotion'] ? .2 : 1

  // call emoji again explicitly to register changes of the label
  const handleClick = (interactionType: string, interactionId: string) => {
    navigate(`${pdfUrl}/${interactionType}/${interactionId}`, {replace: true})
  }

  return (
    <IconButton
      aria-label={item.interactionType}
      onClick={() => handleClick(item.interactionType, item.interactionId)}
      sx={{
        backgroundColor: "whitesmoke",
        color: "#646464",
        fontSize: radius-4,
        height: `${radius+2}px`,
        margin: "1px",
        padding: 0,
        justifyItems: "center",
        overflow: "hidden",
        width: `${radius+2}px`,
        "&:hover": {color: "rgba(0, 0, 0, 0.54)"},
        opacity
      }}
    >
      <ViewTracker type='emotion' id={item.interactionId}>
        <span role="img" aria-label={emotion.interactionId}>
          {emotion.label}
        </span>
      </ViewTracker>
    </IconButton>
  )
}

export default observer(MarginaliaEmoji)