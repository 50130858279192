import React from 'react'
import { Link } from 'wouter'
import { styled } from '@mui/material'
import { useStore } from '../contexts/store'
import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'


const LoadError: React.FC = () => {
    const { uiStore } = useStore()
    const { t } = useTranslation()
    return (
        <ErrorContainerStyle>
            <ErrorStyle>
                <Alert severity="warning">
                    <AlertTitle>{t('Content could not be loaded')}</AlertTitle>
                    <Button variant="contained" size="small" sx={{ m: "10px 10px 0 0"}}>
                        <Link to="/" style={{textDecoration: "none", color: "white"}}>
                            {t('Go to main menu')}
                        </Link>
                    </Button>
                    {!uiStore.isOffline &&
                        <Button 
                            variant="contained"
                            size="small" sx={{ m: "10px 0px 0 0"}}
                            onClick={() => window.location.reload()}
                            >{t('Try again')}
                        </Button>
                    }
                    {uiStore.isOffline &&
                        <OfflineMessage>{t('no internet connection')}</OfflineMessage>
                    }
                </Alert>
            </ErrorStyle>
        </ErrorContainerStyle>
    )
}

const ErrorContainerStyle = styled('div')({
    alignContent: "center",
    backgroundColor: "whitesmoke",
    display: "grid",
    height: "100%",
    justifyItems: "center",
    width: "100%",
})

const ErrorStyle = styled('div')({
    border: "1px solid lightgray",
    borderRadius: "4px",
    margin: "10px",
  })

const OfflineMessage = styled('div')({
    paddingTop: "10px",
    textAlign: "end",
    color: "grey",
})

export default LoadError