import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { useStore } from "../../contexts/store"

import { Box } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'

import UserInfoAvatar from "./UserInfoAvatar"
import { PodClass } from "../../classes/Pod"
import TimeFromNow from "./TimeFromNow"

// message from thread
const MessageBox = ({ tCreated, tModified, text, userId }: { tCreated: number, tModified: number, text: string, userId: number }) => {
  const { t } = useTranslation()
  const { podStore } = useStore()

  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  const podId = pod.podId
  const userInfo = pod.userInfos[userId]
  const userName = userInfo ? userInfo.userName : t('unknown')

  return (
    <Box sx={{display: "grid", gridTemplateColumns: "max-content auto", padding: "10px 15px"}} >
      {/* avatar (left) */}
      <Box sx={{marginRight:'15px',justifySelf: "right"}}>
        <UserInfoAvatar sx={{height:36, width:36}} userId={userId} podId={podId} />
      </Box>
      {/* content (right) */}
      <Box sx={{display: "grid", gridTemplateRows: "max-content auto min-content"}}>
        {/* header */}
        <Box sx={{display: "grid", gridTemplateColumns: "max-content auto min-content", alignItems: "center", marginBottom: "3px", marginLeft: "5px"}}>
          <Box sx={{fontWeight: "600", color: "#4c4c57", wordBreak: "break-word"}}>
            {userName}
          </Box>
          <Box sx={{marginLeft: "15px", color: "#808080a6", fontSize: "13px"}}>
            <TimeFromNow timestamp={tModified ? tModified*1000 : 0} />
          </Box>
        </Box>
        <Box sx={{marginLeft: "5px"}}>
          {/* message */}
          <Box sx={{paddingRight: "30px", wordBreak: "break-word", whiteSpace: "pre-line"}}>
            {text}
            {tCreated !== tModified &&
              <Box sx={{
                alignItems: "center",
                color: "#b6b6b6",
                display: "grid",
                fontSize: "11px",
                fontStyle: "italic",
                gridTemplateColumns: "max-content max-content",
                justifyContent: "end",
                padding: "2px 10px 0 0"
              }}>
                <EditIcon sx={{fontSize: "15px"}} /> {t('Edited')}
              </Box>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  )
}


export default observer(MessageBox)