import React, { useEffect } from "react"
import { InView } from 'react-intersection-observer'
import { useStore } from "../../contexts/store"
import { observer } from "mobx-react-lite"
import { ViewableType } from "../../../../types/Miscs"


const ViewTracker = ({type, id, sub, children, ...props}: {
    type:ViewableType,
    id:string,
    sub?:number|undefined|null,
    children:any,
    props?: any
  }) => {
  //const { children } = props.props
  const { podStore } = useStore()

  useEffect(() => {
    //console.log(`${type} ${id} (${sub}) mounts`)
    return () => {
      // console.log(`${type} ${id} (${sub}) unmounts`)
      podStore.setView(false, type, id, sub)
    }
  })

  return  <InView initialInView={true} delay={500} trackVisibility={true} onChange={(inView, entry) => podStore.setView(inView, type, id, sub)}>
    {children}
  </InView>

}

export default observer(ViewTracker)