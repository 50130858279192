import i18next from 'i18next'
import alertStore, {alert} from "../stores/alertStore"
import sessionStore from "../stores/sessionStore"
import pdfMetaStore from "../stores/pdfMetaStore"

let SW: ServiceWorker | null

export function register() {
  if ('serviceWorker' in navigator) {
    // Register a service worker
    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
      navigator.serviceWorker.register(swUrl, {
        scope: "/"
      }).then(
        (registration) => {
          SW =
            registration.installing ||
            registration.waiting ||
            registration.active

            // TODO: if service worker removed: destroy session, to avoid client counter conflicts
        },
        (error) => {
          console.log('Service worker registration failed:', error, SW);
        }
      );
    })
    //listen for messages from the service worker
    navigator.serviceWorker.addEventListener('message', onMessage)

    // trigger clients.claim in service worker, if worker not already loaded
    navigator.serviceWorker.ready.then(worker => {
      if(navigator.serviceWorker.controller === null && worker.active) {
        worker.active.postMessage({op: "claimClients"})
      } else {
        sessionStore.prepareApp()
      }
    })
  }
  else {
    i18next.on('loaded', translations => {
      let language: string = i18next.language
      if(translations && language && translations[language]) {
        console.warn('Service workers are not supported.')
        alertStore.push(alert(i18next.t('offline mode does not work under these circumstances'), 'info', i18next.t('Service Workers are not supported by your browser') ?? ''))
      }
    })
    sessionStore.prepareApp()
  }
}

/** got a message from the service worker */
const onMessage = (ev: MessageEvent) => {
  if(ev.data && ev.data.msg) {
    let msg = ev.data.msg
    if(msg.op === "claimedClients") {
      sessionStore.prepareApp()
    }
    if(msg.op === "invalidSession") {
      if(sessionStore.session) {
        alertStore.push(alert(i18next.t('please log in again'), 'warning', i18next.t('Your session has expired') ?? ''))
        sessionStore.clearSession()
      }
    }
    if(msg.op === "cachedPdf" && msg.storeId) {
      pdfMetaStore.setIsCached(msg.storeId ,true)
    }
  }
  else throw new Error("unexpected message from service worker", ev.data.msg)
}
