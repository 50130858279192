import React, {useEffect} from 'react'
import LanguageSwitch from './LanguageSwitch'
import { useTranslation } from "react-i18next"

import { Button } from '@mui/material'
import Infobox from './Infobox'
import { useStore } from '../contexts/store'

const Disclaimer: React.FC = () => {
    const { t } = useTranslation()
    const { uiStore } = useStore()

    const getConsent = async() => {
      if (localStorage.getItem('CookieConsent') === 'consented') {
        uiStore.setCookieConsent(true)
      }
    }

    useEffect(() => {
      if (!uiStore.cookieConsent) getConsent()
    })

    const setConsent = (async() =>{
      localStorage.setItem('CookieConsent', 'consented')
      uiStore.setCookieConsent(true)
    })

    return (
      <>
        <div style={{justifySelf: "end", margin: "10px"}}>
          <LanguageSwitch />
        </div>
        <Infobox>
          <div style={{float:'left'}}>
            <img src="/img/shrimpy-solo.png" style={{width:100, float:'left'}} alt="The SHRIMP Logo" />
          </div>
          <div style={{marginLeft:110}}>
            <p style={{marginTop:0}}>
              {t('CookieDisclaimer')}
            </p>
            <p>{t('For more information, please refer to our')} <a href="/data-privacy.html" target="_blank">{t('Data and Privacy Information')}</a>.</p>
            <p style={{ textAlign:'right' }}>
              <Button variant="contained" onClick={setConsent}>{t('Ok')}</Button>
            </p>
          </div>
        </Infobox>
      </>
    )
  }

export default Disclaimer