import { ReactNode } from "react"
import { Dialog } from "@mui/material"

const DialogWrapper = ({
  children,
  isOpen,
  fullHeight,
  handleClose,
  modus
}: {
  children: ReactNode,
  isOpen: boolean,
  fullHeight: boolean,
  handleClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void,
  modus: "smartphone" | "tablet" | "desktop"
}) => {
  // fullscreen drawer adapts on smartphone to the height of the header
  let headerHeight = document.getElementById("app-header")?.offsetHeight
  if(!headerHeight) headerHeight = 64
  // if elements like viewer need full height of screen
  const paperProps = fullHeight ? { height: "100%" } : {}

  if(modus === 'smartphone') {
    return (
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        sx={{
          marginTop: `${headerHeight}px`,
          '&.MuiDialog-root .MuiModal-backdrop': { position: "absolute" }
        }}
      >
        {children}
      </Dialog>
    )
  }

  if(modus === 'tablet') {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          sx: { width: "800px", ...paperProps }
        }}
        sx={{
          marginTop: `${headerHeight}px`,
          '&.MuiDialog-root .MuiModal-backdrop': { position: "absolute" }
        }}
      >
        {children}
      </Dialog>
    )
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xl'}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { width: "1600px", ...paperProps }
      }}
      sx={{
        marginTop: `${headerHeight}px`,
        '&.MuiDialog-root .MuiModal-backdrop': { position: "absolute" }
      }}
    >
      {children}
    </Dialog>
  )
}

export default DialogWrapper