import dayjs from "dayjs"
import { t } from "i18next"
import { useStore } from "../../contexts/store"
import { PodClass } from "../../classes/Pod"
import { Message as MessageType } from "../../../../types/Message"
import { Op_editMessage } from "../../../../types/Ops"
import UserInfoAvatar from "./UserInfoAvatar"
import TextArea from "./TextArea"
import { Box } from "@mui/material"
import { observer } from "mobx-react-lite"


const EditMessage = ({message, podId, usergroupId}: {message: MessageType, podId: string, usergroupId: string}) => {
  const { opStore, podStore, sessionStore, uiStore } = useStore()
  const { session } = sessionStore
  const pod: PodClass | null = podStore.pod
  if(pod === null) return null

  if(!message) return null
  const userInfo = pod.userInfos[message.userId]
  const userName = userInfo ? userInfo.userName : t('unknown')
  const timestamp = message.tModified ? message.tModified*1000 : 0

  function submitEdit(edit: string) {
    // save edit when something has changed
    if(message && edit !== message.text && usergroupId) {
      const op:Op_editMessage = {
        op: "editMessage",
        podId: podId,
        data: {
          userId: session.user.userId,
          userName: podStore.userPseudonym || '',
          usergroupId: usergroupId,
          threadId: message.threadId,
          messageId: message.messageId,
          mods: {
            text: edit,
            tModified: dayjs().unix()
          }
        }
      }
      opStore.doOp(op)
    }
    // close edit message
    uiStore.setEditedMessageId(null)
  }

  return (
    <Box sx={{backgroundColor: "#edf2fc", padding: "5px 20px"}}>
      {/* header */}
      <Box sx={{display: "grid", gridTemplateColumns: "min-content auto", margin: "15px 0"}}>
        <Box sx={{marginRight:'15px',justifySelf: "right"}}>
          <UserInfoAvatar sx={{height:36, width:36}} userId={message.userId} podId={podId} />
        </Box>
        <Box sx={{display: "grid", gridTemplateColumns: "max-content auto min-content", alignItems: "center", marginBottom: "3px", marginLeft: "5px"}}>
          <Box sx={{fontWeight: "600", color: "#4c4c57", wordBreak: "break-word"}}>
            {userName}
          </Box>
          <Box sx={{marginLeft: "15px", color: "#808080a6", fontSize: "13px"}}>
            {dayjs(timestamp).fromNow()}
          </Box>
        </Box>
      </Box>
      <TextArea
        handleClose={() => {uiStore.setEditedMessageId(null)}}
        placeholder={t("Edit this comment ...")}
        showHints={true}
        submit={(edit) => submitEdit(edit)}
        value={message.text}
        disabled={!(pod.isAllowed('editMessage', message.messageId))}
      />
    </Box>
  )
}

export default observer(EditMessage)