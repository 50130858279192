import React, {useEffect} from 'react'
import { Route, Switch, useLocation } from 'wouter'
import { observer } from 'mobx-react-lite'
import { useStore } from '../contexts/store'
import alertStore, { alert } from '../stores/alertStore'
import { useTranslation } from 'react-i18next'

import Alerts from './Alerts'
import Header from './Header'
import Home from "./Pages/Home"
import Pod from "./Pages/Pod"
import PodProvider from './PodProvider'
import Pdf from './Pages/Pdf'
import System from './Dev/System'
import EditInteractionModal from './Pdf/InteractionModal/EditInteractionModal'
import Login from './Login'
import Syncing from './Pages/Syncing'

import ErrorBoundaryComponent from './ErrorBoundaryComponent'
import Disclaimer from './Disclaimer'
import VersionIndicator from './VersionIndicator'
import Account from './Pages/Account'
import Mrc from './Dev/Mrc'
import WindowTitleSetter from './WindowTitleSetter'
import api from '../api/api'

import i18n from '../i18n'
import dayjs from 'dayjs'
import 'dayjs/locale/de'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ShrimPdfReader from './Dev/ShrimPdfReader'

import ChangeMyPassword from './Elements/ChangeMyPassword'
import { Box, Paper } from '@mui/material'


const App: React.FC = () => {
  const { t } = useTranslation()
  const { sessionStore, opStore, broadcastStore, uiStore, podStore } = useStore()
  const navigate = useLocation()[1]
  const session = sessionStore.session

  // use currently selected language for dayjs globally
  const language = i18n.language
  dayjs.locale(language)

  //@ts-ignore
  window.Stores = useStore() || {}

  useEffect(() => {
    try{
      //@ts-ignore
    if (!Promise.withResolvers) {
      console.warn('polyfilling Promise.withResolvers')
      //@ts-ignore
      Promise.withResolvers = function () {
        let resolve, reject
        const promise = new Promise((res, rej) => {
          resolve = res
          reject = rej
        })
        return { promise, resolve, reject }
      }
    }
    }
    catch(e) {
      console.warn(e)
    }

    try{
      //@ts-ignore
      if (!Array.prototype.at) {
        console.warn('polyfilling Array.prototype.at')
        //@ts-ignore
        Array.prototype.at = function (index) {
          if (!this.length) return undefined;
          const number = Number(index) || 0; // if NaN, affect 0
          const intIndex = Math.sign(number) * Math.floor(Math.abs(number));
          const relativeIndex = intIndex >= 0 ? intIndex : this.length + intIndex;
          if (relativeIndex < 0 || relativeIndex >= this.length) return undefined;
          return this[relativeIndex];
        }
      }
    }
    catch(e) {
      console.warn(e)
    }

    try {
      if (!Promise.allSettled) {
        console.warn('polyfilling Promise.allSettled')
        //@ts-ignore
        Promise.allSettled = ((promises:Promise<any>[]) => Promise.all(
            promises.map(p => p
                .then(value => ({
                    status: "fulfilled",
                    value
                }))
                .catch(reason => ({
                    status: "rejected",
                    reason
                }))
            )
        ))
      }
    }
    catch(e) {
      console.warn(e)
    }

    // onMount: do a quick first sync and then add the sync interval
    // opStore.syncFull()
    const syncInterval = setInterval(() => {
      if (uiStore.cookieConsent && session) {
        if (uiStore.showVerboseLogging.sync) console.log('trigger Sync')
        opStore.syncFull()
      }
    }, 6000);

    // onMount: add incrementing timer for TimeFromNow component and update viewTracking
    const timerInterval = setInterval(() => {
      uiStore.incrementTimer()
      podStore.refreshViews()
    }, 10000);

    // onMount: add the broadcast channel
    broadcastStore.createBroadcastChannel()

    // onMount: look for the loggedOut parameter
    const url = new URL(document.location.href)
    const loggedOut = url.searchParams.get("loggedOut")
    if(loggedOut) {
      broadcastStore.sendMessage({op: "logout"})
      if (localStorage.getItem('clearAfterLogout')) {
        api.resetCache({cache: true, idbStatic: true, idbOpLog: true, suppressNavigate: true})
        localStorage.clear()
        alertStore.push(alert(t('You have successfully logged out and cached data has been cleared'), 'success'))
        window.setTimeout(() => { document.location.reload() }, 3000)
      }
      else {
        alertStore.push(alert(t('You have successfully logged out'), 'success'))
        localStorage.removeItem('clearAfterLogout')
      }
      return navigate(url.origin, { replace: true })
    }

    // initialize and update device dimensions to adjust the layout accordingly
    uiStore.setDeviceWidth(document.body.clientWidth)
    window.addEventListener("resize", function() {
      uiStore.setDeviceWidth(document.body.clientWidth)
    })

    // onUnmount: remove the broadcast channel and end the sync interval
    return () => {
      broadcastStore.removeBroadcastChannel()
      clearInterval(syncInterval)
      clearInterval(timerInterval)
    };
  }, [broadcastStore, navigate, t, uiStore, session, opStore, podStore])


  // force local users to change their password the first time they log in
  if(session && session.user && session.user.provider === 'local' && session.user.tCreated === session.user.tModified) {
  // if(session && session.user && session.user.tCreated === session.user.tModified) {
    return (
      <Box sx={{height: "100dvh", display: "grid", alignItems: "center", justifyItems: "center", gridTemplateColumns: "1fr"}}>
        <Paper elevation={3} sx={{maxWidth: "800px", width: "100%"}}>
          <Box sx={{margin: "30px 30px 10px 30px", color: "#282828e8", display: "grid", justifyContent: "center", fontSize: "24px", fontWeight: 200}}>
            {t('Welcome to your first login to SHRIMP')}
          </Box>
          <ChangeMyPassword firstLogin={true} handleClose={() => {}} successNavigationRoute='/' username={session.user.login} />
        </Paper>
      </Box>
    )
  }

  // Force 'Cookie' Acknowledgment
  if (!uiStore.cookieConsent) return <>
    <Alerts />
    <Disclaimer />
    <VersionIndicator />
  </>

  // Force Login
  if (!session) return <>
    <Alerts />
    <Login />
    <VersionIndicator />
  </>

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      <ErrorBoundaryComponent>
        <Header />
        <Alerts />
        <EditInteractionModal />
        <Switch>
          <Route path='/'><Home /><WindowTitleSetter /></Route>
          <Route path='/system'><System /><WindowTitleSetter /></Route>
          <Route path='/account'><Account /><WindowTitleSetter /></Route>
          <Route path='/syncing'><Syncing /><WindowTitleSetter /></Route>
          <Route path='/shrimPdfReader'><ShrimPdfReader /><WindowTitleSetter /></Route>
          <Route path='/pod/:podId/pdf/:nodeId*'>{(params) => <PodProvider podId={params.podId} ><Pdf pdfId={params.nodeId?.replace(/\/.*/, '')} /><WindowTitleSetter /></PodProvider>}</Route>
          <Route path='/pod/:podId/conversations/:activeConversationId*'>{(params) => <PodProvider podId={params.podId} ><Pod activeTabIndex={'conversations'} activeConversationId={params.activeConversationId} /><WindowTitleSetter /></PodProvider>}</Route>
          <Route path='/pod/:podId/:activeTabIndex/:subtab*'>{(params) => <PodProvider podId={params.podId} ><Pod activeTabIndex={params.activeTabIndex} activeSubTabIndex={params.subtab} /><WindowTitleSetter /></PodProvider>}</Route>
          <Route path='/pod/:podId/:activeTabIndex*'>{(params) => <PodProvider podId={params.podId} ><Pod activeTabIndex={params.activeTabIndex} /><WindowTitleSetter /></PodProvider>}</Route>
          <Route path='/pod/:podId'>{(params) => <PodProvider podId={params.podId} ><Pod /><WindowTitleSetter /></PodProvider>}</Route>
          <Route path='/mrc/:podId'>{(params) => <PodProvider podId={params.podId} ><Mrc /><WindowTitleSetter /></PodProvider>}</Route>
        </Switch>
      </ErrorBoundaryComponent>
    </LocalizationProvider>
  );
}

export default observer(App)