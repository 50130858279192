import { t } from "i18next"
import { MouseEvent, useEffect, useState } from "react"
import { useStore } from "../../contexts/store"
import { HexColorPicker } from "react-colorful"
import { Button, Box, Menu, IconButton } from "@mui/material"
import ColorLensIcon from '@mui/icons-material/ColorLens';

const ColorSelect = ({color, setColor, variant, disabled, sx}: {
  color: string,
  setColor: (selectedColor: string) => void,
  variant?:'outlined'|'text'|'contained',
  disabled?: boolean,
  sx?: any
}) => {
  const { uiStore } = useStore()
  const [selectedColor, setSelectedColor] = useState<string>("")
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    if(color) setSelectedColor(color)
  }, [color])

  const handlePickerOpen = (event: MouseEvent<HTMLButtonElement>) => {
    if (disabled) return false
    setAnchorEl(event.currentTarget)
  }

  const handlePickerClose = (isCanceled: boolean) => {
    // if not explicitly cancelled, transfer colour when closing the modal
    if(!isCanceled && selectedColor) setColor(selectedColor)
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        id="color-picker-button"
        onClick={handlePickerOpen}
        startIcon={<ColorLensIcon sx={{color}} />}
        variant={variant || 'outlined'}
        disabled={disabled || false}
        sx={{
          ...sx
        }}
      >{t('Set Color')}</Button>
      <Menu
        id="color-picker-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handlePickerClose(false)}
        MenuListProps={{
          'aria-labelledby': 'color-picker-button',
        }}
        sx={{padding: "10px", ".MuiPaper-root":{ backgroundColor: "white"}}}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Box sx={{ borderRadius: "5px", display: "grid", margin: "10px 25px 5px 25px", maxWidth: "200px"}}>
          <HexColorPicker  color={selectedColor} onChange={setSelectedColor} />
          {/* button group */}
          <Box sx={{
            display: "grid",
            gridGap: "10px",
            gridTemplateColumns: "min-content min-content",
            justifyContent: "end",
            marginTop: "20px"
          }}>
            <Button onClick={() => {handlePickerClose(true)}} variant="outlined">{t('Cancel')}</Button>
            <Button onClick={() => handlePickerClose(false)} variant="contained">{t('Ok')}</Button>
          </Box>
        </Box>
      </Menu>
    </>
  )
}

export default ColorSelect