import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useStore } from '../../contexts/store'
import { observer } from 'mobx-react-lite'
import { InteractionType, Rect } from '../../../../types/Interaction'
import { OpCode } from '../../../../types/OpCodes'
import { ToolRepresentation, useParams } from '../../stores/uiStore'
import { opCodeToInteractionType } from '../../helper/Helper'
import OverlayFreehandBox from './OverlayFreehandBox'

const Overlay = (
    {
      childrenDepth,
      interactionEditId,
      pageId,
      pdfId,
      pageNumber,
      scale,
      hideFreehandBox,
    }: {
      childrenDepth: number,
      interactionEditId?: string,
      pageId: string,
      pdfId: string,
      pageNumber: number,
      scale: number,
      hideFreehandBox?: boolean
    }
) => {
  const { podStore, uiStore } = useStore()
  const { interactionId } = useParams()
  const [ pageContainer, setPageContainer ] = useState<any>(null)
  const transformInteractionId = uiStore.transformInteractionMenu.interactionId
  const previewColor = uiStore.previewColor
  // highlight selected interaction
  let highlightInteractionId = interactionId
  if(transformInteractionId) highlightInteractionId = transformInteractionId

  useEffect(() => {
    const pageRef = document.getElementById(pageId)
    if(pageContainer === null && pageRef) setPageContainer(pageRef)
  }, [pageContainer, pageId])

  // get pod object
  const pod = podStore.pod
  if(pod === null) return null

  // test if page exists
  if(pageContainer === null || pageContainer.children[childrenDepth] === null) return null

  // Do not show interactions while a selection is in progress (otherwise they will end up in the rectangle list when crossing page boundaries)
  if ((uiStore.selectionStartRange) && (uiStore.selectionEndRange) && uiStore.selectionStartRange.pageNumber !== uiStore.selectionEndRange.pageNumber) return null

  // get page inside pdf container
  const page = pageContainer.children[childrenDepth]

  // save all associated rects for each interaction
  const overlayList: {rect: Rect, color: string, toolRepresentation: ToolRepresentation, interactionId: string, hidden?: boolean}[] = []

  // edit view: show only the currently selected edit overlay or the currently selected create link overlay
  // otherwise: show overlay of all interactions
  if(interactionEditId) {
    if(interactionEditId === "addLink") {
      const addLinkAnchor = uiStore.selectedLinkAnchor
      if(addLinkAnchor) {
        const rects = addLinkAnchor.rects
        const toolRepresentation = uiStore.getToolRepresentation("link", addLinkAnchor.tool)
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: uiStore.getInteractionColor("addLink"), toolRepresentation: toolRepresentation, interactionId: interactionEditId})
          })
        }
      }
    } else {
      const interaction = pod.getInteraction(interactionEditId)
      const interactionEditAnchor = podStore.getInteractionEditAnchor(interactionEditId)

      if(interaction) {
        // check if interaction is transformed and change its interactionType
        const isTransformation = uiStore.interactionModal.isTransformation
        const selectedTransformOperation = uiStore.getSelectedOperation()
        const interactionType = isTransformation ? opCodeToInteractionType(selectedTransformOperation) : interaction.interactionType

        // get interaction color
        let interactionColor = uiStore.getInteractionColor(isTransformation ? selectedTransformOperation as OpCode : interaction.interactionType as InteractionType)
        if((isTransformation && selectedTransformOperation === "addAnnotation" && interaction.style.color) ||
           (!isTransformation && interaction.interactionType === 'annotation' && interaction.style.color)) {
          interactionColor = interaction.style.color
          // show preview color, if selected
          if(previewColor && previewColor.interactionId === interaction.interactionId) {
            interactionColor = previewColor.color
          }
        }

        // if edited text exists, highlight it. otherwise highlight existing text of the interaction
        let rects = interaction.anchor.rects
        let toolRepresentation = uiStore.getToolRepresentation(interactionType, interaction.anchor.tool)
        if(interactionEditAnchor && interactionEditAnchor.rects) {
          rects = interactionEditAnchor.rects
          toolRepresentation = uiStore.getToolRepresentation(interactionType, interactionEditAnchor.tool)
        }
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: interactionColor, toolRepresentation: toolRepresentation, interactionId: interactionEditId})
          })
        }
      }
    }
  }
  else {
    // load interactions for actual page
    const pageInteractions = pod.getInteractionsFromPage(pdfId, pageNumber)
    for(const interaction of pageInteractions) {
      // hide interacttion overlay from visibility selection
      let hidden = uiStore.hideInteractions[interaction.interactionType] ? true : false
      // only annotations can have own color
      let color = uiStore.getInteractionColor(interaction.interactionType as InteractionType)
      if(interaction.interactionType === 'annotation' && interaction.style.color) color = interaction.style.color
      let toolRepresentation = uiStore.getToolRepresentation(interaction.interactionType, interaction.anchor.tool)

      const rects = interaction.anchor.rects
      if(Array.isArray(rects) && rects.length) {
        rects.forEach((rect: Rect) => {
          if(rect.p === pageNumber) overlayList.push({rect: rect, color: color, toolRepresentation: toolRepresentation, interactionId: interaction.interactionId, hidden: hidden})
        })
      }
    }
  }

  // if there are no overlays, only provide the overlay for freehand mode
  if (overlayList.length < 1) {
    return (
      createPortal(
        <div id={`overlay-page-${pageNumber}`}>
          <OverlayFreehandBox pageNumber={pageNumber} hideFreehandBox={hideFreehandBox} />
        </div>
      ,page
      )
    )
  }

  return (
    createPortal(
      <div id={`overlay-page-${pageNumber}`}>
        {overlayList.map((item: {rect: Rect, color: string, toolRepresentation: ToolRepresentation, interactionId: string, hidden?: boolean}, index: number) => {
          if(item.toolRepresentation === "block") {
            return (
              <div key={index} style={{
                backgroundColor: uiStore.getSemiTransparentColor(item.color),
                height: item.rect.h * scale,
                left: item.rect.x * scale,
                opacity: ((highlightInteractionId && item.interactionId !== highlightInteractionId) || item.hidden) ? "20%" : "",
                position: "absolute",
                top: item.rect.y * scale,
                width: item.rect.w * scale,
                zIndex: 1,
              }}></div>
            )
          }
          else if(item.toolRepresentation === "underline")
          {
            return (
              <div key={index} style={{
                borderBottom: `2px solid ${item.color}`,
                height: item.rect.h * scale,
                left: item.rect.x * scale,
                opacity: ((highlightInteractionId && item.interactionId !== highlightInteractionId) || item.hidden) ? "20%" : "",                position: "absolute",
                top: (item.rect.y * scale) + 1,
                width: item.rect.w * scale,
                zIndex: 1,
              }}></div>
            )
          }
          return null
        })}
        <OverlayFreehandBox pageNumber={pageNumber} hideFreehandBox={hideFreehandBox} />
      </div>
      ,page
    )
  )
}

export default observer(Overlay)
