import { observer } from "mobx-react-lite"
import { t } from "i18next"
import { useState } from "react"
import { Box, Button } from "@mui/material"
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// selected text associated with the interaction
const Blockquote = ({color, hasToggleButton, text}: {color: string, hasToggleButton: boolean, text: string}) => {
  const [showMore, setShowMore] = useState(false)
  const shortText = text.length < 100 || !hasToggleButton ? text : text.substring(0, 100) + "..."

  const ToggleButton = () => {
    if(showMore) return (
      <Button sx={{fontSize: "13px"}} startIcon={<ExpandLessIcon />} onClick={() => setShowMore(!showMore)}>
        {t('Show less')}
      </Button>
    )
    else return (
      <Button sx={{fontSize: "13px"}} startIcon={<ExpandMoreIcon />} onClick={() => setShowMore(!showMore)}>
        {t('Show more')}
      </Button>
    )
  }

  return (
    <Box sx={{display: "grid", margin: "5px" }}>
      <Box sx={{display: "grid", gridTemplateColumns: "min-content auto", fontStyle: "italic", margin: "10px 5px 5px 5px"}}>
        <Box sx={{backgroundColor: color, width: "6px", m: "5px"}} />
        <Box sx={{padding: "10px 25px 10px 10px", wordBreak: "break-word"}}>
          {showMore ? text : shortText}
        </Box>
      </Box>
      {/* show more or less button if necessary */}
      {(text.length > 100 && hasToggleButton) &&
        <Box sx={{display: "grid", gridTemplateColumns: "max-content", justifyContent: "end", marginRight: "20px"}}>
          <ToggleButton />
        </Box>
      }
    </Box>
  )
}

export default observer(Blockquote)
