import { t } from "i18next"
import dayjs from "dayjs"
import { observer } from "mobx-react-lite"
import { useStore } from "../../contexts/store"
import { Op_addReaction, Op_deleteReaction } from "../../../../types/Ops"
import { ReactionBlock } from "../../../../types/Miscs"
import EmojiPicker from "./EmojiPicker"

import { Box, Button, Tooltip } from "@mui/material"
import AddReactionIcon from '@mui/icons-material/AddReactionOutlined'

const Reactions = (
    {alwaysVisbile, interactionId, interactionType, isScrolling, podId, reactions}:
    {alwaysVisbile?: boolean, interactionId: string, interactionType: string, isScrolling?: boolean, podId: string, reactions: ReactionBlock}
  ) => {
  const { sessionStore, opStore, podStore, uiStore } = useStore()
  const { session } = sessionStore

  function addReaction(reactionId: string) {
    // prevent an emoji from being added twice by a user
    const includesEmoji = Object.keys(reactions) ? Object.keys(reactions).includes(reactionId) : false
    const pickedByMyself = reactions[reactionId] ? reactions[reactionId].includes(session.user.userId) : false
    const isDuplicate = (includesEmoji && pickedByMyself) ? true : false

    if(reactionId && !isDuplicate) {
      opStore.doOp({
        op: "addReaction",
        podId: podId,
        data: {
          usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
          userId: session.user.userId,
          userName: podStore.userPseudonym,
          type: interactionType,
          id: interactionId,
          reactionId: reactionId,
          tCreated: dayjs().unix(),
        }
      } as Op_addReaction)
    }
  }

  function deleteReaction(reactionId: string) {
    if(reactionId) {
      opStore.doOp({
        op: 'deleteReaction',
        podId: podId,
        data: {
          usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
          userId: sessionStore.session.user.userId,
          userName: podStore.userPseudonym as string,
          type: interactionType,
          id: interactionId,
          reactionId: reactionId
        }
      } as Op_deleteReaction)
    }
  }

  const clickReaction = (reactionId: string, pickedByMyself: boolean) => {
    if(pickedByMyself) deleteReaction(reactionId)
    else addReaction(reactionId)
  }

  const Reaction = observer(({unicode, users} :{unicode: string, users: number[]}) => {
    const pickedByMyself = users.includes(session.user.userId)
    // generate tooltip text
    let tooltipText = pickedByMyself ? t('You') : ""
    if(users.length) {
      for(const user of users) {
        const userInfo = podStore.getUserInfo(user, podId)
        if(userInfo && userInfo.userName && userInfo.userId !== session.user.userId) {
          if(tooltipText.length) tooltipText += ` | ${userInfo.userName}`
          else tooltipText = `${userInfo.userName}`
        }
      }
    }
    if(pickedByMyself) tooltipText += ` (${t('click to remove')})`
    else tooltipText += ` (${t('click to add')})`

    if(users.length > 0) {
      return (
        <Tooltip title={tooltipText} arrow>
          <Button onClick={() => clickReaction(unicode, pickedByMyself)} sx={{
            backgroundColor: pickedByMyself ? "#edf2fc" : "whitesmoke",
            border: pickedByMyself ? "1px solid #1e5ad9" : "1px solid whitesmoke",
            color: pickedByMyself ? "#1e5ad9" : "grey",
            display: "grid",
            gridTemplateColumns: "auto min-content",
            fontSize: "18px",
            gridGap: "3px",
            height: "min-content",
            minWidth: "50px",
            padding: "0 2px",
            "&:hover": {
              backgroundColor: "white",
              border: pickedByMyself ? "1px solid #1e5ad9" : "1px solid lightgrey"
            }
          }}>
            {unicode}
            <Box sx={{fontSize: "13px"}}>
              {users.length}
            </Box>
          </Button>
        </Tooltip>
      )
    }
    return null
  })

  return (
    <Box id={`reaction-${interactionId}`} sx={{
      display: "flex",
      flexWrap: "wrap",
      gridGap: "5px",
      justifyItems: "center",
      marginTop: "5px",
      paddingRight: "30px"
    }}>
      {Object.entries(reactions).map(([unicode, users]) => (
        <Reaction key={unicode} unicode={unicode} users={users} />
      ))}
      <Box id={`emoji-picker-${interactionId}`} className={(uiStore.deviceWidth === "tiny" || alwaysVisbile) ? "" : "hidden"}
        sx={{
          display: "grid",
          justifyItems: "center",
          minHeight: "33.5px"
        }}>
          {isScrolling ?
              <Button
              variant="text"
              sx={{
                backgroundColor: "whitesmoke",
                border: "1px solid whitesmoke",
                color: "grey",
                height: "33.5px",
                minWidth: "30px"
              }}
            >
              <AddReactionIcon />
            </Button> :
            <EmojiPicker handleEmojiClick={(event: any) => addReaction(event.detail.unicode)} />
          }
      </Box>
    </Box>
  )
}

export default observer(Reactions)
