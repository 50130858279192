import React from 'react'

import { versions } from '../version'
const version = versions[0]

const VersionIndicator: React.FC = () => {
    return <div style={{position:'absolute', bottom:0, right:0}} title={version.key + "\n" + version.description}>
      Version {version.version}
    </div>
  }

export default VersionIndicator