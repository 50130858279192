import { t } from "i18next"
import { MouseEvent, useEffect, useState } from "react"
import { useStore } from "../../contexts/store"
import { HexColorPicker } from "react-colorful"
import { Button, Box, Menu } from "@mui/material"

const ColorPicker = ({color, setColor, sx}: {color: string, setColor: (selectedColor: string) => void, sx?: any}) => {
  const { uiStore, podStore } = useStore()
  const [selectedColor, setSelectedColor] = useState<string>("")
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const annotationColors = uiStore.annotationColors
  const customColors = podStore.getCustomAnnotationColors()

  useEffect(() => {
    if(color) setSelectedColor(color)
  }, [color])

  const handlePickerOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePickerClose = (isCanceled: boolean) => {
    // if not explicitly cancelled, transfer colour when closing the modal
    if(!isCanceled && selectedColor) setColor(selectedColor)
    setAnchorEl(null)
  }

  const ColorBlock = ({color}: {color: string}) => (
    <Button onClick={() => setSelectedColor(color)} sx={{
      backgroundColor: color,
      boxShadow: (color !== selectedColor) ? 0 : 5,
      height: "30px",
      minWidth: "30px",
      "&:hover": {
        backgroundColor: color,
        boxShadow: 5
      }
    }} />
  )

  const ColorList = ({list, title}: {list: string[] | null, title: string}) => {
    if(list && list.length > 0) {
      return (
        <Box sx={{padding: "0 5px", margin: "7px 0", color: "#000e53", fontSize: "14px"}}>
          {t(title)}
          <Box sx={{display: "flex", flexWrap: "wrap", gridGap: "8px", marginTop: "3px"}}>
            {list.map((color:string) =>
              <ColorBlock key={color} color={color} />
            )}
          </Box>
        </Box>
      )
    }
    return null
  }

  return (
    <Box>
      <Button
        id="color-picker-button"
        onClick={handlePickerOpen}
        sx={{
          backgroundColor: color,
          height: "35px",
          opacity: "60%",
          minWidth: "35px",
          width: uiStore.deviceWidth === "tiny" ? "fitContent" : "100%",
          "&:hover": {
            backgroundColor: color,
            boxShadow: 5
          },
          ...sx
        }}
      />
      <Menu
        id="color-picker-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handlePickerClose(false)}
        MenuListProps={{
          'aria-labelledby': 'color-picker-button',
        }}
        sx={{padding: "10px", ".MuiPaper-root":{ backgroundColor: "white"}}}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Box sx={{ borderRadius: "5px", display: "grid", margin: "10px 25px 5px 25px", maxWidth: "200px"}}>
          <HexColorPicker  color={selectedColor} onChange={setSelectedColor} />
          <ColorList title='Preselected Colors' list={annotationColors} />
          <ColorList title='Custom Colors' list={customColors} />
          {/* button group */}
          <Box sx={{
            display: "grid",
            gridGap: "10px",
            gridTemplateColumns: "min-content min-content",
            justifyContent: "end",
            marginTop: "20px"
          }}>
            <Button onClick={() => {handlePickerClose(true)}} variant="outlined">{t('Cancel')}</Button>
            <Button onClick={() => handlePickerClose(false)} variant="contained">{t('Ok')}</Button>
          </Box>
        </Box>
      </Menu>
    </Box>
  )
}

export default ColorPicker