import { observer } from 'mobx-react-lite'
import { useStore } from '../../../contexts/store'
import { PodClass } from '../../../classes/Pod'
import Badge, { BadgeProps } from '@mui/material/Badge'
import { styled } from '@mui/material/styles'

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -10,
    top: 9,
    border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: "5px",
  },
}))

const ConversationUnreads = () => {
  const { sessionStore, podStore } = useStore()
  const pod:PodClass|null = podStore.pod
  const userId = sessionStore.session.user.userId
  let unreads = 0

  // load list of conversations in which the user is involved
  const conversationList = podStore.getConversations(userId)
  if(pod === null || conversationList === null) return null

  for(const conversation of conversationList) {
    if(conversation.hasUnreadMessages) unreads++
  }

  if(unreads === 0) return null
  return (
    <StyledBadge anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }} badgeContent={unreads} color="primary" sx={{marginRight: "10px"}} />
  )
}

export default observer(ConversationUnreads)