import React, {useState} from "react"
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from './ErrorFallback'
import api from "../api/api";

const ErrorBoundaryComponent = (props:any) => {
  const [ errorId, setErrorId ] = useState(0)

  const onError = async (error: Error, info: { componentStack?: string|null|undefined, context?: string, url?:string }) => {
    try {
      if (props.context) info.context = props.context
      info.url = document.location.href
      const res = await api.reportError(error, info)
      if (res) setErrorId(res?.body?.info?.reportId)
    }
    catch(e) {
      console.error(e)
    }
  }

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => { return <ErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} reportId={errorId} /> }}
      onError={onError}
    >
      {props.children}
    </ErrorBoundary>
  )
}

export default ErrorBoundaryComponent