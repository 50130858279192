import { observer } from "mobx-react-lite"
import { useStore } from "../../contexts/store"
import DialogWrapper from "../Elements/DialogWrapper"
import InteractionOpHeader from "../Elements/InteractionOpHeader"

import { DialogContent, DialogTitle } from '@mui/material'
import ChangeMyPassword from "../Elements/ChangeMyPassword"


const ChangeMyPasswordModal = ({handleClose, isOpen, username}: {handleClose: () => void, isOpen: boolean, username: string | null}) => {
  const { uiStore } = useStore()
  const deviceWidth = uiStore.deviceWidth

  const Title = () => (
    <DialogTitle sx={{padding: 0}}>
      <InteractionOpHeader color={'#1976d2'} interactionOp={'changeMyPassword'} handleClose={handleClose} />
    </DialogTitle>
  )

  // smartphone view
  if(deviceWidth === "tiny") {
    return (
      <DialogWrapper modus="smartphone" isOpen={isOpen} fullHeight={false} handleClose={handleClose} >
        <Title />
        <DialogContent sx={{ display: "grid", gridTemplateRows: "min-content auto", backgroundColor: "white", padding: 0}}>
          <ChangeMyPassword handleClose={handleClose} username={username} successNavigationRoute='/account' />
        </DialogContent>
      </DialogWrapper>
    )
  }
  return (
    <DialogWrapper modus="tablet" isOpen={isOpen} fullHeight={true} handleClose={handleClose} >
      <Title />
      <DialogContent sx={{ display: "grid", gridTemplateRows: "1fr auto", backgroundColor: "white", padding: 0}}>
        <ChangeMyPassword handleClose={handleClose} username={username} successNavigationRoute='/account' />
      </DialogContent>
    </DialogWrapper>
  )

}

export default observer(ChangeMyPasswordModal)
