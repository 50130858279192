import { makeObservable, action, observable, computed } from "mobx"
import { t } from "i18next"

import opStore from './opStore'
import uiStore from "./uiStore"
import sessionStore from "./sessionStore"
import alertStore, {alert} from "./alertStore"

import api from '../api/api'

import { Pod, PodLoadState, Usergroup } from '../../../types/Pod'
import { PodClass, PodI } from '../classes/Pod'
import { Interaction, interactionAnchor } from '../../../types/Interaction'
import { PdfFile, Tag } from "../../../types/Content"
import { Op, Op_addViews, viewData } from "../../../types/Ops"
import { Thread } from "../../../types/Message"
import { UserInfo } from "../../../types/User"
import { ConversationItem, ViewableType } from "../../../types/Miscs"
import { activityOps } from "../helper/activityOps"
import dayjs from "dayjs"
import { Timer } from "../helper/Helper"

export interface PodStoreModel {
  pod: PodClass|null
  podIsLoading: boolean
  activeLinkEditId: string | null
  userPseudonym: string | null
  userInfo: UserInfo | null
  podActivity: Op[]
  viewTrackerStore: {[key:string]: {tSeen:number, dSeen:number, visible: boolean}}

  getThread: (interactionId: string) => Thread | null
  getConversations: (userId : number) => null | ConversationItem[]
  getTagProp: (tagId: string) => Tag | null
  getTagPool: () => Tag[]
  getUsergroupByRole: (role: 'Admin'|'Pod'|'Private') => Usergroup

  setActiveLinkEditId: (linkEditId: string | null) => void
  loadPod: (podId: string) => Promise<boolean>
  unsetPod: () => void
  resetPod: (podId: string) => Promise<boolean>

  getLinkLabel: (linkId: string, linkLabel: string | null) => string
  setLinkLabel: (linkId: string, text: string) => void
  deleteLinkLabel: (linkId: string) => void
  getLinkFile: (linkId: string, fileId: string | null) => string
  setLinkFile: (linkId: string, fileId: string) => void
  deleteLinkFile: (linkId: string) => void
  getInteractionEditAnchor: (interactionEditId: string) => interactionAnchor | null
  setInteractionEditAnchor: (interactionEditId: string, anchor: interactionAnchor) => void
  deleteInteractionEditAnchor: (interactionEditId: string) => void
  getFilename: (nodeId: string) => string | null
  setPodActivity: (ops: Op[]) => void
  getPodActivity: (podId: string) => Op[] | null
  addToPodActivity: (podId: string, op: Op) => void
  getUserInfo: (userId: number | undefined | null, podId: string | undefined | null) => UserInfo | null

  setOutOfSync: (status:boolean) => void

  setView: (visible: boolean, type: ViewableType, id: string, sub?: number|null|undefined) => void
  getView: (type: ViewableType, id: string, sub?: number|null|undefined) => { tSeen:number, dSeen:number }
  refreshViews: () => void,
  getLastUnseenMessage: (thread: Thread) => {index: number|null, messageId :string|null}
  getNodeId: (interactionId: string | undefined) => null | string
  getPdfViewProgress: (nodeId: string) => number | null
  getFolderFiles: (folderId: string) => PdfFile[]
  getCustomAnnotationColors: () => string[]
}

type PodCondition = {
  status: PodLoadState,
  info: string
}

class podStore {
  podIsLoading: boolean = false
  activePodId: string | null = null
  activePdfId: string | null = null
  activeLinkEditId: string | null = null
  getOpsintervalId: number | null = null
  linkLabel: {[id: string]: string} = {}
  linkFile: {[id: string]: string} = {}
  interactionEditAnchor: {[interactionEditId: string]: interactionAnchor} = {}
  urlParams: {[key:string]: string} = {}

  viewTrackerStore: {[key:string]: {tSeen:number, dSeen:number, visible: boolean}} = {}

  // the currently loaded, active pod.
  pod: PodClass | null = null
  podCondition: {[podId: string]: PodCondition} = {}
  podActivity: Op[] = []

  constructor() {
    makeObservable(this, {
      activePodId: observable,
      activePdfId: observable,
      activeLinkEditId: observable,
      pod: observable,
      podCondition: observable,
      setPod: action,
      setActiveLinkEditId: action,
      resetPod: action,
      unsetPod: action,
      setPodStatus: action,
      getThread: observable,
      getConversations: observable,
      getTagProp: observable,
      getTagPool: observable,
      linkLabel: observable,
      getLinkLabel: observable,
      setLinkLabel: action,
      deleteLinkLabel: action,
      linkFile: observable,
      getLinkFile: observable,
      setLinkFile: action,
      deleteLinkFile: action,
      interactionEditAnchor: observable,
      getInteractionEditAnchor: observable,
      setInteractionEditAnchor: action,
      deleteInteractionEditAnchor: action,
      userPseudonym: computed,
      userInfo: computed,
      podActivity: observable,
      getPodActivity: observable,
      setPodActivity: action,
      addToPodActivity: action,
      getFilename: action,
      setOutOfSync: action,

      getView: observable,
      viewTrackerStore: observable,
      refreshViews: action,
      setView: action,
      getLastUnseenMessage: action,
      getNodeId: action,
      getPdfViewProgress: observable,
      getFolderFiles: observable,
      getUserInfo: observable,
      getCustomAnnotationColors: action,
      loadPod: action,
    })
  }

  setView(visible: boolean, type: ViewableType, id: string, sub: number|null|undefined = null) {
    const signature = `${type} ${id} ${sub}`
    const now = dayjs().unix()
    if (visible) {
      if (uiStore.showVerboseLogging.view && !this.viewTrackerStore[signature]?.visible) console.log(`View: ${signature} appeared at ${now} (with ${this.viewTrackerStore[signature]?.dSeen || 0} s of legacy views)`)
      if (typeof this.viewTrackerStore[signature] === 'undefined') this.viewTrackerStore[signature] = {
        tSeen:now,
        dSeen:0,
        visible:true
      }
      else  {
        this.viewTrackerStore[signature].dSeen += (now-this.viewTrackerStore[signature].tSeen);
        this.viewTrackerStore[signature].tSeen = now;
        this.viewTrackerStore[signature].visible = true
      }
    }
    else if ((typeof this.viewTrackerStore[signature] !== 'undefined') && (this.viewTrackerStore[signature].visible)) {
      const duration = this.viewTrackerStore[signature].dSeen + (now - this.viewTrackerStore[signature].tSeen)
      this.viewTrackerStore[signature] = { tSeen: now, dSeen: duration, visible: false }
      if(uiStore.showVerboseLogging.view) console.log(`View: ${signature} disappeared at ${now} (with ${this.viewTrackerStore[signature].dSeen} s of total viewtime`)
    }
  }

  refreshViews(force:boolean = false) {
    const signatures = Object.keys(this.viewTrackerStore)
    const now = dayjs().unix()
    const chunksize = force ? 100 : 25
    // console.log(`${force ? 'FORCED' : 'unforced'} wipe of views table`)

    if (!this.pod) return
    if (!sessionStore.session) return

    signatures.sort((a, b) => this.viewTrackerStore[b].tSeen - this.viewTrackerStore[a].tSeen)
    const views:viewData[] = []

    for(var i=0; i<Math.min(chunksize, signatures.length); i++) {
      const sig = signatures[i].split(' ')
      const type:ViewableType = sig[0] as ViewableType
      const id:string = sig[1]
      const sub:number= Number(sig[2])

      if (!this.viewTrackerStore[signatures[i]].visible) {
        if (this.viewTrackerStore[signatures[i]].dSeen !== 0) {
          views.push({
            type,
            id,
            sub: Number(sub) || 0,
            tSeen: this.viewTrackerStore[signatures[i]].tSeen,
            dSeen: this.viewTrackerStore[signatures[i]].dSeen,
          })
          delete this.viewTrackerStore[signatures[i]]
        }
      }
      else if ((this.viewTrackerStore[signatures[i]].dSeen > 300) || (force)) {
        views.push({
          type,
          id,
          sub: Number(sub) || 0,
          tSeen: this.viewTrackerStore[signatures[i]].tSeen,
          dSeen: this.viewTrackerStore[signatures[i]].dSeen + (now - this.viewTrackerStore[signatures[i]].tSeen),
        })
        this.viewTrackerStore[signatures[i]].dSeen = 0
        this.viewTrackerStore[signatures[i]].tSeen = now
      } else {
        // console.log('rebook', signatures[i])
        this.viewTrackerStore[signatures[i]].dSeen += now - this.viewTrackerStore[signatures[i]].tSeen
        this.viewTrackerStore[signatures[i]].tSeen = now
      }
    }

    // console.log(`Identified ${views.length} reapable views:`, views.length ? views : [])

    if (views.length) {
      const op:Op_addViews = {
        op: 'addViews',
        podId: this.pod.podId,
        data: {
          usergroupId: this.getUsergroupByRole('Private').usergroupId,
          userId: sessionStore.session.user.userId,
          userName: this.userPseudonym || '',
          views
        },
      }
      opStore.doOp(op)
    }
  }

  getView(type:ViewableType, id: string, sub:number|null|undefined=null) {
    const signature = `${type} ${id} ${sub}`
    var tSeen:number = this.viewTrackerStore[signature]?.tSeen || 0
    var dSeen:number = this.viewTrackerStore[signature]?.dSeen || 0

    switch(type) {
      case 'message':
        const msg = this.pod?.getMessage(id)
        if (msg) { tSeen = Math.max(tSeen, msg.tSeen || 0); dSeen += msg.dSeen || 0 }
        break
      case 'comment':
      case 'emotion':
      case 'link':
      case 'readingQuestion':
      case 'tagging':
      case 'weblink':
        const int = this.pod?.getInteraction(id)
        if (int) { tSeen = Math.max(tSeen, int.tSeen || 0); dSeen += int.dSeen || 0 }
        break
      case 'pdfPage':
        if (sub) {
          const page = this.pod?.content.pdfFiles[id]?.pages[sub]
          if (page) { tSeen = Math.max(tSeen, page.tSeen || 0); dSeen += page.dSeen || 0 }
        }
        break
      default:
        console.warn(`Cannot (yet) account for ${type}`)
      }
    return {
      tSeen,
      dSeen
    }
  }

  getPdfViewProgress(nodeId: string) {
    const pdf = this.pod?.content.pdfFiles[nodeId]
    let viewProgress = null
    if(pdf && pdf.nofPages) {
      const nofPages = pdf.nofPages
      let viewedPages = 0
      for(let pageNumber = 1; pageNumber <= nofPages; pageNumber++) {
        const view = this.getView("pdfPage", nodeId, pageNumber)
        if(view.dSeen > uiStore.readingTimer) viewedPages++
      }
      viewProgress = Math.round((viewedPages / nofPages) * 100)
    }
    return viewProgress
  }

  getFolderFiles(folderId: string) {
    const pdfFiles = this.pod?.content.pdfFiles
    const folderFiles = []
    if(pdfFiles) {
      for(const pdfId in pdfFiles) {
        const pdf = pdfFiles[pdfId]
        if(pdf.folderId === folderId && !pdf.hidden) folderFiles.push(pdf)
      }
    }
    return folderFiles
  }

  getLastUnseenMessage(thread: Thread) {
    let messageId = null
    let messageFound = false
    let index = null
    thread.messages.forEach((message, i) => {
      const signature = `message ${message.messageId} ${null}`
      const dSeen:number = (this.viewTrackerStore[signature]?.dSeen + (message.dSeen || 0)) || (message.dSeen || 0)
      // detect unread message
      if(dSeen < 2 && message.userId !== sessionStore.session.user.userId && !messageFound) {
        messageId = message.messageId
        messageFound = true
        index = i
      }
      // if there is another message from the user after an unread message,
      // search for the next unread message from that point onwards
      if(message.userId === sessionStore.session.user.userId && messageFound) {
        messageId = null
        messageFound = false
        index = null
      }
    })
    return {index: index, messageId: messageId}
  }

  get userPseudonym() {
    const userInfo = this.userInfo
    if (userInfo) return userInfo.userName
    return null
  }

  get userInfo() {
    if ((this.pod) && (sessionStore.session.user.userId)) return this.pod.userInfos[sessionStore.session.user.userId]
    return null
  }

  async resetPod(podId: string) {
    delete this.podCondition[podId]
    if (podId === this.pod?.podId) this.unsetPod()
    await api.loadPod(podId, true)
    return true
  }

  /** trigger loading pod with podId */
  async loadPod(podId: string, force: boolean = false) {
    const t0 = Date.now()

    let pod: PodClass

    // Do not load pods that are already loaded
    if ((!force) && (this.pod?.podId === podId) && (this.pod.status === 'loaded')) {
      return true
    }

    if (this.pod?.status === 'loading') {
      console.warn(`Warning: loadPod() was called while a pod was loading. Cannot load pod ${podId} while ${this.pod.podId} is loading.`)
      return false
    }

    // console.log(`Loading pod ${podId}`, this.pod)

    if (true) {
      pod = new PodClass(null, true)
      pod.podId = podId
      pod.status = 'loading'
      this.setPod(pod)
    }

    // Initialize with empty 'init' version of the pod (or get the full Pod if the serviceWorker has it)
    const loadedPod = await api.loadPod(podId)

    if (loadedPod) {

      if (loadedPod.status === 'unknown') {
        pod.setStatus('unknown')
        this.setPod(pod)
        const pendingOps = await api.getPendingOps(podId)
        if (pendingOps) {
          if (uiStore.showVerboseLogging.loadPod) console.log(`Applying ${pendingOps.length} pending OPs`)
          pendingOps.forEach((op:Op) => {
            opStore.execute(pod, op)
          })
        }
        return true
      }

      if (loadedPod.status === 'deleted') {
        pod.setStatus('deleted')
        this.setPod(pod)
        const sessionPods = sessionStore.session.pods.filter((pod: Pod) => pod.podId !== podId)
        sessionStore.setPods(sessionPods)
        return true
      }

      if (loadedPod.status === 'broken') {
        pod.status = 'broken'
        this.setPod(pod)
        return true
      }

      pod = loadedPod

      if ((pod.lastSyncOid >= pod.initMaxCoid) && (pod.status === 'loaded')) {
        // query wurde vom SW mit einem vollständigen Pod beantwortet. Apply pending Ops
        if (uiStore.showVerboseLogging.loadPod) console.log(`Finished loading in ${Date.now()-t0}ms.`)

        const pendingOps = await api.getPendingOps(podId)
        if (uiStore.showVerboseLogging.loadPod) console.log(`Applying ${pendingOps?.length} pending OPs`)
        if (pendingOps) pendingOps.forEach((op:Op) => {
          opStore.execute(pod, op)
        })

        this.setPod(pod)
        return true
      }
    }
    else {
      console.error(`Could not load pod: nothing returned`)
      alertStore.push(alert(('Could not load pod: Are you connected to the internet?'), 'error'))
      this.setPodStatus(pod, 'broken' as PodLoadState)
      return false
    }

    if (pod.status !== 'initialized') {
      console.warn(`Error condition: This should not happen! Pod status === ${pod.status}`)
      this.setPodStatus(pod, 'broken' as PodLoadState, `Error condition: This should not happen! Pod status === ${pod.status}`)
      //this.unsetPod()
      return false
    }

    if (pod.lastSyncOid as number === pod.initMaxCoid as number) {
      if (pod.initMaxCoid as Number === 0) {
        console.log('Pod appears to be empty (and is thus loaded)')
        pod.lastSyncOid = pod.initMaxCoid = pod.loadtimeMaxOid
        this.setPodStatus(pod, 'loaded' as PodLoadState)
        this.setPod(pod)
        return true
      }
      else {
        console.warn(`Error condition: This should not happen! (lastSyncOid === initMaxCoid)`)
        this.setPodStatus(pod, 'broken' as PodLoadState, `Error condition: This should not happen! (lastSyncOid === initMaxCoid)`)
        //this.unsetPod()
        return false
      }
    }

    if (uiStore.showVerboseLogging.loadPod) console.log(`Pod was initialized but is incomplete: ${pod.lastSyncOid} !== ${pod.initMaxCoid} ||  ${pod.status} !== 'loaded' --> continue with chunked loading` )

    // Pod ist nur ein initPod: perform load
    this.setPodStatus(pod, 'loading' as PodLoadState)
    pod.setLoadStatus(0)
    let completedOpsCounter = 0

    this.setPod(pod)

    const timer = new Timer()
    const shortTimer = new Timer()
    const avg = (array:number[]) => array.reduce((sum, currentValue) => sum + currentValue, 0) / array.length;


    try {

      do {

        timer.reset()
        if (uiStore.showVerboseLogging.loadPod) console.log('Client: Load chunk starting at ' + pod.lastSyncOid)
        const chunk = await api.loadPodChunk(podId, pod.lastSyncOid, pod.initMaxCoid)
        if (uiStore.showVerboseLogging.loadPod) console.log(`Client: Load chunk completed in ${timer.elapsed()} ms.`)

        if (chunk) {
          if (uiStore.showVerboseLogging.loadPod) console.log(`Client: Process chunk`)
          timer.reset()

          const { ops, totalOps } = chunk
          const tracker:any = {}
          ops.forEach((op:any) => {
            shortTimer.reset()
            opStore.execute(pod, op)
            const t = shortTimer.elapsed()
            if (!tracker[op.op]) tracker[op.op] = []
            tracker[op.op].push(t)
            pod.lastSyncOid = op.data.coid
            if (op.oid === pod.initMaxCoid) {
              this.setPodStatus(pod, 'loaded' as PodLoadState)
            }
          })

          if (uiStore.showVerboseLogging.loadPod) console.log(`Client: Process chunk completed in ${timer.elapsed()} ms.`)
          if (uiStore.showVerboseLogging.loadPod) console.log(JSON.stringify(Object.keys(tracker).map(opCode => `${opCode}: ${avg(tracker[opCode])} (x ${tracker[opCode].length})`), null, 2))

          completedOpsCounter += ops.length
          pod.setLoadStatus(totalOps ? Math.floor(100 * completedOpsCounter / totalOps) : 0)

          if (this.pod?.podId === pod.podId) this.setPod(pod)
        }

      } while(pod.status !== 'loaded' as PodLoadState)

      if (uiStore.showVerboseLogging.loadPod) console.log(`Loading of chunks completed`)

      // Get unsynced OPs from serviceWorker and apply
      // (If they were created based on an older version of the pod, they will still get applied
      // based on this new version in the backend, so we should mimick this behavior here)
      const pendingOps = await api.getPendingOps(podId)
      if (pendingOps) {
        if (uiStore.showVerboseLogging.loadPod) console.log(`Applying ${pendingOps.length} pending OPs`)
        pendingOps.forEach((op:Op) => {
          // pod.applyOp(op) //
          opStore.execute(pod, op)
        })
      }
    } catch(e) {
      console.error(`Error condition in fetch: `, e)
      this.setPodStatus(pod, 'broken' as PodLoadState)
      this.unsetPod()
      return false
    }

    api.getPodActivity(podId, pod.loadtimeMaxOid+1, true)

    if (uiStore.showVerboseLogging.loadPod) console.log(`Finished loading in ${Date.now()-t0}ms`)
    return true
  }

  setPodStatus(pod:PodI, status: PodLoadState, info: string = '') {
    if ((pod.status === 'loading') && (status === 'loaded')) pod.setLastSyncOid(pod.loadtimeMaxOid)
    pod.setStatus(status)
    this.podCondition[pod.podId] = {
      status: status,
      info,
    }
  }

  unsetPod() {
    this.refreshViews(true)
    this.pod = null
  }

  setPod(pod: PodI, info: string = '') {
    if (pod && pod.podId) {
      this.pod = pod
      if (pod.status) this.podCondition[pod.podId] = {
        status: pod.status,
        info,
      }
    }
  }

  getUsergroupByRole(role:'Admin'|'Pod'|'Private') {
    if (this.pod) return this.pod.getUsergroupByRole(role)
    throw(new Error('eee'))
  }

  getThread(interactionId: string) {
    const threads = this.pod?.content?.threads
    if(threads) {
      for(let id in threads) {
        const thread = threads[id]
        if(thread.interactionId === interactionId) return thread
      }
    }
    return null
  }

  getConversations(userId: number) {
    if(!this.pod || !userId) return null
    // load threads in which the user is involved
    const threads = this.pod.content?.threads
    const conversations: ConversationItem[] = []
    const conversationIds: string[] = []
    if(threads) {
      for(let id in threads) {
        const thread = threads[id]
        const baseInteraction = this.pod.getInteractionFromThreadId(thread.threadId)
        // if there is no baseInteraction thread was probably deleted
        if(baseInteraction && baseInteraction.interactionType === "comment") {
          if(baseInteraction.userId === userId) {
            const conversationItem = this.createConversationItem(thread, baseInteraction)
            if(conversationItem && !conversationIds.includes(conversationItem.interactionId)) {
              conversations.push(conversationItem)
              conversationIds.push(conversationItem.interactionId)
            }
          }
          else {
            for(let message of thread.messages) {
              if(message.userId === userId) {
                const conversationItem = this.createConversationItem(thread, baseInteraction)
                if(conversationItem && !conversationIds.includes(conversationItem.interactionId)) {
                  conversations.push(conversationItem)
                  conversationIds.push(conversationItem.interactionId)
                }
                break
              }
            }
          }
        }
      }
    }
    return conversations
  }

  createConversationItem(thread: Thread, baseInteraction: Interaction) {
    if(!this.pod) return null
    // create item for list of chat conversations
    const threadId = thread.threadId
    const messages = thread.messages
    const interactionId = thread.interactionId
    const userId = baseInteraction.userId
    const userName = baseInteraction.userName ? baseInteraction.userName : ""
    const label = baseInteraction.label
    const nodeId = baseInteraction.anchor.nodeId
    let tLastMessage = null
    const replies = messages.length
    const involvedUsers: number[] = []
    // get involved users
    if(messages.length) {
      // go from the last to the first message
      for (let index = (messages.length-1); index >= 0; index--) {
        const message = messages[index]
        if(!involvedUsers.includes(message.userId)) {
          involvedUsers.unshift(message.userId)
        }
        // take tCreate from last message
        if(tLastMessage === null) tLastMessage = message.tCreated
      }
      // consider user from base interaction
      if(!involvedUsers.includes(baseInteraction.userId)) {
        involvedUsers.unshift(baseInteraction.userId)
      }
    } else {
      return null
    }
    // check if the thread has unread messages
    let hasUnreadMessages = false
    if(threadId) {
      const thread = this.pod.content.threads[threadId]
      const lastMessageViewed = this.getLastUnseenMessage(thread)
      if(lastMessageViewed.messageId) {
        const dSeen = this.getView("message", lastMessageViewed.messageId, null).dSeen
        if(dSeen === 0) hasUnreadMessages = true
      }
    }
    // build conversation item
    return ({
      involvedUsers: involvedUsers,
      interactionId: interactionId,
      label: label,
      nodeId: nodeId,
      replies: replies,
      threadId: threadId,
      tLastMessage: tLastMessage ? tLastMessage : baseInteraction.tCreated,
      userId: userId,
      userName: userName,
      hasUnreadMessages: hasUnreadMessages
    })
  }

  getNodeId(interactionId: string | undefined) {
    const pdfFiles = this.pod?.content.pdfFiles
    if(pdfFiles && interactionId) {
      for(const fileId in pdfFiles) {
        const content = pdfFiles[fileId]
        if(content.annotations[interactionId]) return fileId
        if(content.comments[interactionId]) return fileId
        if(content.links[interactionId]) return fileId
        if(content.taggings[interactionId]) return fileId
        if(content.weblinks[interactionId]) return fileId
        if(content.emotions[interactionId]) return fileId
      }
    }
    return null
  }

  getTagProp(tagId: string) {
    const tagProp = this.pod?.content.tags[tagId]
    if(tagProp) return tagProp
    return null
  }

  getTagPool() {
    const tags = this.pod?.content.tags
    const tagPool = []
    if(tags) {
      for(let id in tags) {
        const tag = tags[id]
        if(tag.name) tagPool.push(tag)
      }
    }
    return tagPool
  }

  setActiveLinkEditId(linkEditId: string | null) {
    this.activeLinkEditId = linkEditId
  }

  getLinkLabel(linkId: string, linkLabel: string | null) {
    // if label does not exist yet, initialize it
    if(this.linkLabel[linkId] === undefined) {
      const label = linkLabel ? linkLabel : ""
      this.linkLabel[linkId] = label
      return label
    }
    return this.linkLabel[linkId]
  }

  setLinkLabel(linkId: string, text: string) {
    this.linkLabel[linkId] = text
  }

  deleteLinkLabel(linkId: string) {
    if(this.linkLabel[linkId] || this.linkLabel[linkId] === "") delete this.linkLabel[linkId]
  }

  getLinkFile(linkId: string, fileId: string | null) {
    // if label does not exist yet, initialize it
    if(this.linkFile[linkId] === undefined && fileId) {
      this.linkFile[linkId] = fileId
      return fileId
    }
    return this.linkFile[linkId]
  }

  setLinkFile(linkId: string, fileId: string) {
    this.linkFile[linkId] = fileId
  }

  deleteLinkFile(linkId: string) {
    if(this.linkFile[linkId]) delete this.linkFile[linkId]
  }

  getInteractionEditAnchor(interactionEditId: string) {
    const overlay = this.interactionEditAnchor[interactionEditId]
    if(overlay) return overlay
    return null
  }

  setInteractionEditAnchor(interactionEditId: string, anchor: interactionAnchor) {
    this.interactionEditAnchor[interactionEditId] = anchor
  }

  deleteInteractionEditAnchor(interactionEditId: string) {
    if(this.interactionEditAnchor[interactionEditId]) delete this.interactionEditAnchor[interactionEditId]
  }

  getFilename(nodeId: string) {
    const pdfFiles = this.pod?.getPdfFiles()
    if(pdfFiles) {
      for(const file of pdfFiles) {
        if(file.nodeId === nodeId) return file.name
      }
    }
    console.warn(`getFilename: no filename with id ${nodeId} found`)
    return null
  }

  getPodActivity(podId:string) {
    if (this.podActivity.length && this.podActivity[0].podId === podId) return this.podActivity
    return null
  }

  setPodActivity(ops: Op[]) {
    this.podActivity = ops
  }

  addToPodActivity(podId:string, op: Op) {
    if ((this.podActivity.length === 0) || this.podActivity[0].podId !== op.podId) return

    // only backend-saved ops can be part of the podActivity log
    if (!op.oid) return

    // only certain ops make it to the activity log
    if (activityOps.indexOf(op.op) === -1) return

    // only new ops will be added to the activity log
    if (this.podActivity.findIndex((o) => o.oid === op.oid) === -1) {
      this.podActivity.unshift(op)
      this.podActivity.sort((a, b) => ((b.oid || 0) - (a.oid || 0)))
      this.podActivity.length = Math.min(this.podActivity.length, 100);
    }
  }

  getUserInfo(userId: number | undefined | null, podId: string | undefined | null) {
    var pod

    // if possible, satisfy from the loaded pod
    if (userId && podId && this.pod && (this.pod.podId === podId) && this.pod.userInfos && this.pod.userInfos[userId]) return this.pod.userInfos[userId]

    // if the loaded pod could not satisfy the request, fall through to try the pod representation in the session
    if (userId && podId && (pod=sessionStore.session.pods.find((p:Pod) => p.podId===podId)) && pod.userInfos && pod.userInfos[userId]) return pod.userInfos[userId]

    // if neither worked, replace a mostly empty representation (if the user requested is the current user, use the idpProvidedUserName as fallback name, if not NULL)
    if (userId) return {
      userId,
      userName: (sessionStore.session.user.userId === userId) ? (sessionStore.session.user.idpProvidedUserName || t('unknown')) : t('unknown'),
      color: "grey"
    }

    return null
  }

  setOutOfSync(status:boolean) {
    if (this.pod) this.pod.outOfSync = status
  }

  getCustomAnnotationColors() {
    const customColors: string[] = []
    const preselectedColors = uiStore.annotationColors
    const pdfFiles = this.pod?.content.pdfFiles
    if(pdfFiles) {
      for(const fileId in pdfFiles) {
        const file = pdfFiles[fileId]
        if(file) {
          const annotations = file.annotations
          for(const id in annotations) {
            const annotation = annotations[id]
            const color = annotation.style.color
            if(color) {
              if(!preselectedColors.includes(color) && !customColors.includes(color)) {
                customColors.push(color)
              }
            }
          }
        }
      }
    }
    return customColors
  }
}


const exportPodStore = new podStore()
export default exportPodStore
