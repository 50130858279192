import { useTranslation } from "react-i18next"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import React from "react"

// message from thread
const ConfirmDialog = ({title, info, open, handleClose, onOk }: {title: string, info:string|JSX.Element, open: boolean, handleClose: any, onOk?: any}) => {
  const { t } = useTranslation()

  return <Dialog open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {info}
    </DialogContent>
    <DialogActions style={{textAlign:'right'}}>
      <Button onClick={handleClose}>{t('Cancel')}</Button>
      <Button variant='contained' onClick={() => { if (onOk) onOk(); handleClose() }}>{t('OK')}</Button>
    </DialogActions>
  </Dialog>

}

export default ConfirmDialog