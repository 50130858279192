import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../contexts/store'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'wouter'
import InfoIcon from '@mui/icons-material/Info'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import SettingsIcon from '@mui/icons-material/Settings'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { Menu, MenuItem, ListItemIcon, ListItemText, IconButton, Alert } from '@mui/material'
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, Switch } from '@mui/material'
import { FormControlLabel, FormHelperText, FormControl } from '@mui/material'
import { versions } from '../../version'
import { useParams } from '../../helper/Helper'
import UserInfoAvatar from '../Elements/UserInfoAvatar'

const AccountButton:React.FC = () => {
  const { podId } = useParams()
  const { sessionStore, opStore, broadcastStore } = useStore()
  const { t } = useTranslation()
  const navigate = useLocation()[1]

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const [ versionInfoDialog, setVersionInfoDialog ] = React.useState<boolean>(false)
  const [ logoutDialog, setLogoutDialog ] = React.useState<boolean>(false)

  const version = versions[0].version
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const clientQueueLength = opStore.static.queue.length ? opStore.static.queue.length : 0
  const { syncQueueLength } = broadcastStore.serviceWorkerStatus
  const nofUnsynched:number = Math.max(clientQueueLength, syncQueueLength)

  const warning = (nofUnsynched === 0) ? null : <Alert severity="warning" sx={{mb:2}}>
    {t('You have {{count}} unsaved changes. SHRIMP will try to store these changes for you. However, your Operating System may delete them at any point, and they will also be lost if another user logs in on this device. It might be better to wait for all changes to sync before logging off.', {count: nofUnsynched})}
  </Alert>

  return  <>
      <IconButton
        onClick={handleClick}
        size="large"
        edge="start"
        color="inherit"
        aria-label={'menu'}
        sx={{ ml:1 }}
      >
        <UserInfoAvatar userId={sessionStore.session.user.userId} podId={podId} sx={{height:36, width:36}}/>
      </IconButton>
    <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
      'aria-labelledby': 'basic-button',
    }}
    >
      <MenuItem onClick={() => { handleClose(); navigate('/account'); }}><ListItemIcon><AccountCircle sx={{fontSize:"1.2rem"}}/></ListItemIcon><ListItemText>{t('Your User Profile')}</ListItemText></MenuItem>
      <MenuItem onClick={() => { setVersionInfoDialog(true); handleClose() }}><ListItemIcon><InfoIcon sx={{fontSize:"1.2rem"}} /></ListItemIcon><ListItemText>{t('About SHRIMP_PODS')} (v.{version})</ListItemText></MenuItem>
      <MenuItem onClick={() => { handleClose(); navigate('/system'); }}><ListItemIcon><SettingsIcon sx={{fontSize:"1.2rem"}}/></ListItemIcon><ListItemText>System Info</ListItemText></MenuItem>
      <MenuItem onClick={() => { setLogoutDialog(true); handleClose() }}><ListItemIcon><PowerSettingsNewIcon sx={{fontSize:"1.2rem", color: "#ff5552"}} /></ListItemIcon><ListItemText>{t('Logout')}</ListItemText></MenuItem>
    </Menu>

    <Dialog onClose={() => { setVersionInfoDialog(false) }} open={versionInfoDialog}>
      <DialogTitle>SHRIMP Version {versions[0].version}</DialogTitle>
      <DialogContent>
        <strong>{versions[0].key}</strong>
        <p>
          {versions[0].description}
        </p>
        <span onClick={() => {  window.navigator.serviceWorker.getRegistrations().then(res => { res.map((registration:ServiceWorkerRegistration) => registration.update()) }) }}>[swupd]</span>
        <span onClick={() => {  window.navigator.serviceWorker.getRegistrations().then(res => { res.map((registration:ServiceWorkerRegistration) => registration.unregister()) }) }}>[swdestroy]</span>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => { setVersionInfoDialog(false) }} autoFocus>Ok</Button>
      </DialogActions>
    </Dialog>

    <Dialog onClose={() => { setLogoutDialog(false) }} open={logoutDialog}>
      <DialogTitle>Logout</DialogTitle>
      <DialogContent>
        {warning}
        <FormControl>
          <FormControlLabel value={Boolean(localStorage.getItem('clearAfterLogout'))} onChange={() => { localStorage.setItem('clearAfterLogout', Boolean(localStorage.getItem('clearAfterLogout')) ? "" : "1") }} control={<Switch />} label={'Clear Offline Cache'} />
          <FormHelperText sx={{marginLeft:0, marginRight:0}}>
            {t('You should clear the Offline Cache if other people have access to this computer and you are concerned about your privacy. Clearing the Offline Cache means that you will have to re-download all files and interactions the next time you use SHRIMP.')}
            &nbsp;
            { nofUnsynched ? <span style={{color:'red'}}>{t('It also means that {{count}} unsaved changes will be lost.', {count:nofUnsynched})}</span> : null }
          </FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => { setLogoutDialog(false) }}>Cancel</Button>
        <Button variant="contained" onClick={() => {
          sessionStore.logout()
          setLogoutDialog(false)
        }} autoFocus>Ok</Button>
      </DialogActions>
    </Dialog>

  </>

}

export default observer(AccountButton)