import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useStore } from '../../../contexts/store'
import UiStore from '../../../stores/uiStore'
import AddUserStepperPeople from './AddUserStepper1People'
import AddUserStepperLogin from './AddUserStepper2Login'
import AddUserStepperConfirm from './AddUserStepper3Confirm'

import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel'
import { styled } from '@mui/material'

const CustomStepLabel = styled(StepLabel)(() => ({
  [`& .${stepLabelClasses.label}`]: {
    fontSize: UiStore.deviceWidth === "tiny" ? '14px' : '17px'
  }
}))

const AddUserStepper = () => {
  const { uiStore } = useStore()
  const deviceWidth = uiStore.deviceWidth
  const tinyDevice = (deviceWidth === 'tiny') ? true : false
  const [activeStep, setActiveStep] = useState(0)
  const people = t('People')
  const login = t('Login')
  const completion = t('Completion')
  const steps = [people, login, completion]

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <Box sx={{ display: "grid", overflow: "auto", gridTemplateRows: "max-content auto", margin: tinyDevice ? "10px" : "20px"}}>
      <Stepper sx={{marginTop: "10px"}} alternativeLabel={tinyDevice ? true : false} activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <CustomStepLabel>{label}</CustomStepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{display: "grid", overflow: "auto", gridTemplateRows: "auto", height: "100%"}}>
        {(steps[activeStep] === people) && <AddUserStepperPeople  activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} steps={steps} />}
        {(steps[activeStep] === login) && <AddUserStepperLogin  activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} steps={steps}  />}
        {(steps[activeStep] === completion) && <AddUserStepperConfirm />}
      </Box>
    </Box>
  );
}

export default observer(AddUserStepper)