import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { useStore } from "../../contexts/store"
import { InteractionType, Interaction, iEmotion } from "../../../../types/Interaction"
import { PodClass } from "../../classes/Pod"
import InteractionIcon from "./InteractionIcon"
import { Box, Divider } from "@mui/material"
import Reactions from "./Reactions"
import MessageElement from "./MessageElement"
import InteractionPreview from "./InteractionPreview"
import Emotion from "./Emotion"
import { useEffect, useRef, useState } from "react"

const BlockquoteBox = ({
  interaction
}: {
  interaction: Interaction
}) => {
  const { t } = useTranslation()
  const { podStore } = useStore()
  const pod:PodClass|null = podStore.pod
  const ref = useRef<HTMLDivElement>(null)

  const [ width, setWidth ] = useState(0)

  useEffect(() => {
    const node = ref.current;
    if (node) {
      setWidth(node.getBoundingClientRect().width)
    }
  }, [ref])

  if (!pod) return null
  const podId = pod.podId

  if(interaction === null) return null
  const pageNumber = interaction.anchor.rects.length ? interaction.anchor.rects[0].p : 1

  const SpeechBubble = observer(() => {
    const interactionType = interaction.interactionType
    if(interactionType === "annotation" || interactionType === "link"|| interactionType === "weblink"|| interactionType === "tagging"|| interactionType === "message") return null
    // show interaction attributes inside speech bubble
    return (
      <Box sx={{
        backgroundColor: "white",
        border: "1px solid #656565",
        borderRadius: "2px",
        padding: "10px 0",
        position: "relative",
        wordBreak: "break-word",
        "&::after": {
          backgroundColor: "white",
          borderLeft: "1px solid #656565",
          borderTop: "1px solid #656565",
          content: '""',
          height: "14px",
          left: "25px",
          position: "absolute",
          top: "-8px",
          transform: "rotate(45deg)",
          width: "14px"
        }
      }}>
        {(interactionType === "comment" || interactionType === "readingQuestion") &&
          <MessageElement tCreated={interaction.tCreated} tModified={interaction.tModified} text={interaction.label} userId={interaction.userId} />
        }
        {interactionType === "emotion" &&
         <Emotion interaction={interaction as iEmotion} />
        }
      </Box>
    )
  })

  return (
    <Box ref={ref} sx={{
      backgroundColor:  "#e8e8e8",
      border: "1px solid #6c6cd3",
      borderRadius: "5px",
      display: "grid",
      margin: "10px",
      minHeight: "80px",
      padding: "10px"
    }}>
      {/* header */}
      <Box sx={{
        alignItems: "center",
        display: "grid",
        fontSize: "13px",
        fontWeight: 500,
        gridGap: "10px",
        gridTemplateColumns: "min-content auto max-content",
        padding: "2px",
        margin: "8px 0"
      }}>
        <InteractionIcon interactionType={interaction.interactionType} />
        <Box>{t(interaction.interactionType)}</Box>
        <Box> {t('Page')} {pageNumber}</Box>
      </Box>
      <Divider />
      <InteractionPreview interactionId={interaction.interactionId} width={Math.max(40, width-24)} height={180} size="contain" />
      <div style={{height:2}}></div>
      <SpeechBubble />
      <Reactions alwaysVisbile={true} interactionId={interaction.interactionId} interactionType={interaction.interactionType} podId={podId}  reactions={interaction.reactions} />
    </Box>
  )
}

export default observer(BlockquoteBox)