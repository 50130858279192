import { t } from "i18next"
import { observer } from "mobx-react-lite"
import { useStore } from "../../../contexts/store"
import { useParams } from "../../../helper/Helper"
import { ChangeEvent, useEffect, useState } from "react"
import api from "../../../api/api"
import { AccountData } from "../../../../../types/User"
import { instantUserPwMinLength, instantUserPwMaxLength, notInstantUserPwRegex } from "../../../validationConstantsString"
import { alert } from "../../../stores/alertStore"

import AddUserStepperButtons from "./AddUserStepperButtons"
import { Alert, Box, FormControl, FormHelperText, InputAdornment, OutlinedInput } from "@mui/material"
import PersonIcon from '@mui/icons-material/Person'
import PasswordIcon from '@mui/icons-material/Key'

const AddUserStepperLogin = (
  {activeStep, handleBack, handleNext, steps}: {
    activeStep: number,
    handleBack: () => void,
    handleNext: () => void,
    steps: string[]
  }) => {
  const { uiStore, alertStore } = useStore()
  const { podId } = useParams()
  const [userList, setUserList] = useState<{id: number, login: string, password: string, username: string}[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [disableNext, setDisableNext] = useState(false)
  const instantUserList = uiStore.instantUserList

  useEffect(() => {
    if(instantUserList.length) setUserList(instantUserList)
  }, [instantUserList])

  const handleNextStep = async () => {
    setIsLoading(true)
    const userBatch = generateUserBatchObject()
    const success = await api.createUserBatch(podId, userBatch)
    if (success) handleNext()
    else {
      alertStore.push(alert(t('Could not create new users. Please try again later.'), 'error'))
    }
    setIsLoading(false)
  }

  const generateUserBatchObject = () => {
    // generate user for api request
    const userBatch: AccountData = []
    for(const user of instantUserList) {
      if(user && user.username && user.password && user.login) {
        userBatch.push({login: user.login, userName: user.username, password: user.password})
      }
    }
    return userBatch
  }

  const getPasswordValue = (id: number) => {
    if(userList.length) {
      const user = userList.find(user => user.id === id)
      if(user) return user.password
      else {
        console.warn("could not find user in instant user list", id, userList)
      }
    }
    return ''
  }

  const hasPasswordError = (id: number) => {
    if(userList.length) {
      const user = userList.find(user => user.id === id)
      if(user && user.password) {
        const isValid = isValidPassword(user.password)
        return !isValid
      }
    }
    return true
  }

  const isValidPassword = (password: string) => {
    // check password length
    if(password.length < instantUserPwMinLength) return false
    if(password.length > instantUserPwMaxLength) return false
    // check if characters are valid
    const hasValidCharacters = password.match(notInstantUserPwRegex) ? false : true
    return hasValidCharacters
  }

  const onChangePassword = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    const password = event.target.value
    // check if all passwords are valid
    let listNotValid = false
    userList.forEach(user => {
      const userPw = (user.id === id) ? password : user.password
      const isValid = isValidPassword(userPw)
      if(!isValid) listNotValid = true
    })
    setDisableNext(listNotValid)
    // update instant user state
    const nextUserList = userList.map((user) => {
      if(user.id === id) return {id: id, login: user.login, password: password, username: user.username}
      else return user
    })
    setUserList(nextUserList)
    uiStore.updateInstantUserPassword(id, password)
  }

  return (
    <Box sx={{display: "grid", overflow: "auto", gridTemplateRows: "max-content auto max-content max-content", height: "100%"}}>
      <Box sx={{margin: "20px 0", padding: "10px 20px", color: "grey"}}>
        {t('addUserStepper_header_passwords')}
      </Box>
      <Box sx={{display: "grid", overflow: "auto", alignContent: "start", paddingRight: "2px"}}>
        {instantUserList.map((user: {id: number, login: string, password: string, username: string}, index: number) =>
          <Box key={index} sx={{display: "grid", gridTemplateColumns: "50% 50%", gridGap: "10px", margin: "5px 10px"}}>
            <FormControl>
              <OutlinedInput
                id={`login_${user.login}`}
                disabled
                value={user.login}
                startAdornment={
                  <InputAdornment position="start" sx={{fontWeight: 300}}>
                    {index+1}.
                  </InputAdornment>
                }
                />
                <FormHelperText sx={{display: "grid", gridTemplateColumns: "max-content max-content", alignItems: "center"}}>
                  <PersonIcon fontSize="small" />
                  {user.username}
                </FormHelperText>
            </FormControl>
            <FormControl>
              <OutlinedInput
                error={hasPasswordError(user.id)}
                id={`password${user.password}`}
                value={getPasswordValue(user.id)}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  onChangePassword(event, user.id)
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <PasswordIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        )}
      </Box>
      <Box sx={{paddingLeft: "10px"}}>
        {disableNext &&
          <Alert severity="error">{t('The password must contain at least {{minLength}} and no more than {{maxLength}} characters. It must not contain any special characters or spaces.', {minLength: instantUserPwMinLength, maxLength: instantUserPwMaxLength})}</Alert>
        }
      </Box>
      <AddUserStepperButtons disabledBack={isLoading} disabledNext={disableNext} loadingNext={isLoading} activeStep={activeStep} handleBack={handleBack} handleNext={handleNextStep} steps={steps} />
    </Box>
  )
}

export default observer(AddUserStepperLogin)