import { observer } from "mobx-react-lite"
import { useStore } from "../../../contexts/store"
import { PodClass } from "../../../classes/Pod"
import DialogWrapper from "../../Elements/DialogWrapper"
import InteractionOpHeader from "../../Elements/InteractionOpHeader"

import { DialogContent, DialogProps, DialogTitle } from '@mui/material'
import AddUserStepper from "./AddUserStepper"

const AddUserModal = () => {
  const { uiStore, podStore } = useStore()
  const deviceWidth = uiStore.deviceWidth
  const isOpen = uiStore.addUserModalIsOpen

  const pod:PodClass|null = podStore.pod
  if (!pod ) return null
  // no admin, no adduser
  const addUserToPodPermission = pod.permissions['addUserToPod'] ? true : false
  if(!addUserToPodPermission) return null

  function clear() {
    // clear pad and instant user list
    uiStore.updateInstantUserPad('')
    uiStore.deleteInstantUserList()
  }

  const handleClose = () => {
    // close modal
    uiStore.closeAddUserModal()
    clear()
  }

  const handleDialogClose: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
        return;
    uiStore.closeAddUserModal()
    clear()
  }

  const Title = () => (
    <DialogTitle sx={{padding: 0}}>
      <InteractionOpHeader color={'grey'} interactionOp={'addUserToPod'} handleClose={handleClose} />
    </DialogTitle>
  )


  // smartphone view
  if(deviceWidth === "tiny") {
    return (
      <DialogWrapper modus="smartphone" isOpen={isOpen} fullHeight={false} handleClose={handleDialogClose} >
        <Title />
        <DialogContent sx={{ display: "grid", gridTemplateRows: "min-content auto", backgroundColor: "white", padding: 0}}>
          <AddUserStepper />
        </DialogContent>
      </DialogWrapper>
    )
  }
  return (
    <DialogWrapper modus="tablet" isOpen={isOpen} fullHeight={true} handleClose={handleDialogClose} >
      <Title />
      <DialogContent sx={{ display: "grid", gridTemplateRows: "1fr auto", backgroundColor: "white", padding: 0}}>
        <AddUserStepper />
      </DialogContent>
    </DialogWrapper>
  )

}

export default observer(AddUserModal)