import { t } from "i18next"
import { useStore } from "../../../contexts/store"
import { ToggleButton, Tooltip } from "@mui/material"
import FreehandIcon from '@mui/icons-material/FormatShapes'
import { observer } from "mobx-react-lite"

const FreehandButton = () => {
  const { uiStore } = useStore()
  const selected = uiStore.isFreehandSelection

  return (
    <Tooltip placement="top" title={t('Use freehand marker')}>
      <ToggleButton
        value="freehandSelection"
        selected={selected}
        onChange={() => {
          if (selected) document.getElementById('root')?.classList.remove("useFreehandMode"); else document.getElementById('root')?.classList.add("useFreehandMode")
          uiStore.setIsFreehandSelection(!selected)
        }}
        size="small"
      >
        <FreehandIcon fontSize="small" />
      </ToggleButton>
    </Tooltip>
  )
}

export default observer(FreehandButton)