import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { PodClass } from '../../classes/Pod'
import { Badge, Box, IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../contexts/store'
import { CreateNewFolder, UploadFile } from '@mui/icons-material'
import { Op_addFolder, Op_addPdfFile } from '../../../../types/Ops'
import { PdfFile, ContentNodeStatus } from '../../../../types/Content'
import { Folder as FolderType } from '../../../../types/Content'
import PdfFileLine from './Contents/PdfFile'
import Folder from './Contents/Folder'
import Prompt from '../Elements/Prompt'
import {env} from '../../api/api'
import alertStore, { alert } from "../../stores/alertStore"
import { PdfImportFileInfo } from "../../stores/pdfImportStore"
import { fileTypeFromBuffer } from 'file-type'
import { unzip } from 'unzipit'


const Contents = ({pod, initScrollPos}: {pod: PodClass, initScrollPos: (tab: string) => void}) => {
  const { sessionStore, podStore, opStore, uploadStore, pdfImportStore } = useStore()
  const { t } = useTranslation()
  const [ newFolderDialog, setNewFolderDialog ] = useState(false)
  const [ isRendered, setIsRendered] = useState(false)

  const isAdmin = pod.isAllowed('deletePod', pod.podId)
  const inputFile = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  useLayoutEffect(() => {
    initScrollPos("contents")
  })

  // show blank page until elements are rendered
  if(isRendered === false) return null

  const createFolder = (folderName:string) => {

    if (folderName) {
      var weight = 0
      for (const nodeId in pod.content.pdfFiles) {
        weight = Math.max(weight, pod.content.pdfFiles[nodeId].weight)
      }

      const op:Op_addFolder = {
        op: 'addFolder',
        podId: pod.podId,
        data: {
          userId: sessionStore.session.user.userId,
          userName: podStore.userPseudonym || '',
          usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
          folderId: sessionStore.createUuid(),
          name: folderName,
          weight: weight + 100,
          coid: null,
          tCreated: dayjs().unix(),
          tModified: dayjs().unix()
        }
      }

      opStore.doOp(op)
    }
  }

  const onChangeFile = async (e:React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files) return false
    for (var i=0; i<e.target.files.length; i++) {
      const file = e.target.files[i]
      const reader = new FileReader()
      reader.onload = async function() {
        const fileData = this.result
        if ((fileData) && (typeof fileData === 'object')) {
          const type = await fileTypeFromBuffer(fileData)
          if (type?.ext === 'zip') {
              const { entries } = await unzip(fileData);
              for (const [name, zipEntry] of Object.entries(entries)) {
                // read an entry as an ArrayBuffer
                const fileData = await entries[name].arrayBuffer()
                const entry: PdfImportFileInfo = {
                  file: fileData,
                  originalFileName: file.name,
                  shrimpData: null,
                  status: 'pending',
                }
                pdfImportStore.addToQueue(entry)
              }
          }
          else {
            const entry: PdfImportFileInfo = {
              file: fileData,
              originalFileName: file.name,
              shrimpData: null,
              status: 'pending',
            }
            pdfImportStore.addToQueue(entry)
          }
        }
        e.target.value = ''
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const visibleFileStates:ContentNodeStatus[] = ['complete', 'processing', 'scheduled' , 'broken']


  // generates view of folders and files
  const PodPanelElement = () => {
    // function: return files inside a folder
    const fileListing = (folderId: string) => {
      return pod.getPdfFiles().filter((file: PdfFile) => ((visibleFileStates.indexOf(file.status) > -1) && (!file.hidden || isAdmin || file.userId === sessionStore.session.user.userId) && (file.folderId === folderId))).sort((a: any, b: any) => { if (a.weight<b.weight) return -1; if (a.weight>b.weight) return 1; return 0 }).map((file: PdfFile) => {
        return (
          <li key={file.nodeId}>
            <PdfFileLine pdfFile={file} />
          </li>
        )
      })
    }
    // data tree
    const elements: object[] = []
    // files without folder
    pod.getPdfFiles().filter((file: PdfFile) => ((visibleFileStates.indexOf(file.status) > -1) && (!file.hidden || isAdmin || file.userId === sessionStore.session.user.userId) && (!file.folderId))).forEach((file: PdfFile) => {
      elements.push({
        weight: file.weight,
        obj: (
          <li key={file.nodeId}>
            <PdfFileLine pdfFile={file} />
          </li>
        )
      })
    })
    // folder with files inside
    pod.getFolders().filter((folder:FolderType) => (!folder.hidden || isAdmin)).forEach((folder: FolderType) => {
      elements.push({
        weight: folder.weight,
        obj: (
          <li key={folder.folderId}>
              <Folder isAdmin={isAdmin} folder={folder} fileListing={fileListing} />
          </li>
        )
      })
    })
    return (
      <ul style={{listStyleType: "none", padding: 5 }}>
          {elements.sort((a: any, b: any) => { if (a.weight<b.weight) return -1; if (a.weight>b.weight) return 1; return 0 }).map((element: any) => element.obj)}
      </ul>
    )
  }

  const PodPanel = observer(PodPanelElement)


  return <Box sx={{display:'grid', gridTemplateRows: 'auto min-content'}}>
      <PodPanel />
      <Box style={{textAlign:'right', marginTop: 48}}>
      { !pod.isAllowed('addFolder') ? null : <IconButton size='large' onClick={() => setNewFolderDialog(true)}>
          <CreateNewFolder fontSize='medium' />
        </IconButton>
      }
      { !pod.isAllowed('addPdfFile') ? null : <>
        <IconButton size='large' onClick={(e:React.MouseEvent) => { inputFile.current?.click() } }>
          <Badge color="primary" badgeContent={uploadStore.uploadQueue.filter(f => f.mimeType === 'application/pdf').length}>
            <UploadFile fontSize='medium' />
          </Badge>
        </IconButton>

        <div><form id="fileForm" encType='multipart/form-data'>
        <input id="fileField"
          type="file"
          ref={inputFile}
          style={{display: 'none'}}
          multiple={true}
          onChange={onChangeFile}
        /></form>
        </div>
        </>
      }
      </Box>
      <Prompt title={t('New Folder')} type={{type:'string', maxLength:48}} info={t('Please enter a name for the new folder') as string} open={newFolderDialog} handleClose={() => setNewFolderDialog(false)} onOk={createFolder} />
  </Box>
}

export default observer(Contents)