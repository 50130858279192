import { ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { useStore } from "../../../contexts/store"
import { navigateToInteraction, useParams } from "../../../helper/Helper"
import { iLink, interactionAnchor } from "../../../../../types/Interaction"
import { Op_editLink } from "../../../../../types/Ops"
import SaveCancel from "../../Elements/SaveCancel"
import { Box, Button, TextField } from "@mui/material"
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft'
import SwitchRightIcon from '@mui/icons-material/SwitchRight'

const InteractionModalLink  = ({
    anchor,
    interaction
  }:
  {
    anchor: interactionAnchor | null,
    interaction: iLink
  }) => {
  const { t } = useTranslation()
  const [label, setLabel] = useState<string>("")
  const { podId, pdfId, interactionId } = useParams()
  const { sessionStore, podStore, opStore, uiStore } = useStore()
  const { session } = sessionStore
  const isTransformation = (interaction.interactionType === "annotation") ? true : false
  const pod = podStore.pod

  useEffect(() => {
    // load link data
    if(interaction) {
      const previewLinkLabel = uiStore.getPreviewLinkLabel(interaction.interactionId)
      // use preview label if exists
      if(previewLinkLabel || previewLinkLabel === '') setLabel(previewLinkLabel)
      else setLabel(interaction.label)
    }
  }, [podStore, interaction, pdfId, uiStore])

  // Due to the structure of the link, the two associated interactions must be created before they can be edited
  if(anchor === null || interaction === undefined || !pod) return null
  // get link from other side
  const targetLink = pod.getLinkOther(interaction)

  const handleSave = () => {
    // distinguish whether link is edited or transformed
    if(!isTransformation) {
      // save changes on source link
      const editAnchor = podStore.getInteractionEditAnchor(interaction.interactionId)
      if(label !== interaction.label || editAnchor) {
        opStore.doOp({
          op: "editLink",
          podId: podId,
          data: {
            interactionType: 'link',
            usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
            userId: session.user.userId,
            userName: podStore.userPseudonym,
            interactionId: interaction.interactionId,
            mods: {
              anchor: (editAnchor) ? JSON.parse(JSON.stringify(editAnchor)) : JSON.parse(JSON.stringify(anchor)),
              label: label,
              tModified: dayjs().unix()
            }
          }
        } as Op_editLink)
      }
      // save changes on target link
      if(targetLink) {
        const targetEditAnchor = podStore.getInteractionEditAnchor(targetLink.interactionId)
        const targetPreviewLinkLabel = uiStore.getPreviewLinkLabel(targetLink.interactionId)
        // save only if something changes in the input or relText was edited
        if(targetPreviewLinkLabel || targetPreviewLinkLabel === '' || targetEditAnchor) {
          opStore.doOp({
            op: "editLink",
            podId: podId,
            data: {
              interactionType: 'link',
              usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
              userId: session.user.userId,
              userName: podStore.userPseudonym,
              interactionId: targetLink.interactionId,
              mods: {
                anchor: (targetEditAnchor) ? JSON.parse(JSON.stringify(targetEditAnchor)) : JSON.parse(JSON.stringify(targetLink.anchor)),
                label: (targetPreviewLinkLabel || targetPreviewLinkLabel === '') ? targetPreviewLinkLabel : targetLink.label,
                tModified: dayjs().unix()
              }
            }
          } as Op_editLink)
        }
      }
    }
    handleClose()
  }

  function handleClose() {
    // close modal
    uiStore.closeInteractionModal()
    // delete selected anchor in store
    uiStore.setSelectedAnchor(null)
    // remove edited text selection from both link sides
    podStore.deleteInteractionEditAnchor(interaction.interactionId)
    if(targetLink) podStore.deleteInteractionEditAnchor(targetLink.interactionId)
    // remove link label preview from both link sides
    uiStore.deletePreviewLinkLabel(interaction.interactionId)
    if(targetLink) uiStore.deletePreviewLinkLabel(targetLink.interactionId)
  }

  function followLink() {
    if(targetLink) {
      // fix bug: if the drawer is opened at the same time as the dialogue due to navigation,
      // selections are blocked. therefore only navigate if the drawer is already open
      // except the navigation is not on the same page, then wait until drawer is opened
      if(interactionId) {
        // navigate to target link
        navigateToInteraction(pdfId, podId, targetLink.interactionId, "link", targetLink.anchor.nodeId)
        // update edit modal interactionId
        uiStore.openInteractionModal(targetLink.interactionId, false)
      }
      else {
        if(targetLink.anchor.nodeId !== interaction.anchor.nodeId) {
          navigateToInteraction(pdfId, podId, targetLink.interactionId, "link", targetLink.anchor.nodeId)
          setTimeout(() => {
            uiStore.openInteractionModal(targetLink.interactionId, false)
          }, 10)
        }
        else {
          // update edit modal interactionId
          uiStore.openInteractionModal(targetLink.interactionId, false)
        }
      }
    }
  }

  function onLabelChange(event: ChangeEvent<HTMLInputElement>) {
    const labelText = event.target.value
    setLabel(labelText)
    uiStore.setPreviewLinkLabel(interaction.interactionId, labelText)
  }

  const ToggleLinkDirection = () => {
    if(interaction?.which === "src") return (
      <Button onClick={followLink} variant="contained" sx={{ gridGap: "10px", justifyContent: "center" }} >
          {t('Switch to destination page')}
          <SwitchLeftIcon />
      </Button>
    )
    if(interaction?.which === "dst") return (
      <Button onClick={followLink} variant="contained" sx={{ gridGap: "10px", justifyContent: "center" }}>
          {t('Switch to source page')}
          <SwitchRightIcon />
      </Button>
    )
    return null
  }

  return (
    <Box sx={{display: "grid", gridTemplateRows: "min-content max-content auto", alignItems: "end"}}>
      <Box sx={{ display: "grid", margin: "0px 10px 15px 10px", maxWidth: "600px", justifySelf: "center", width: "100%"}}>
        <ToggleLinkDirection />
      </Box>
      <Box  sx={{ display: "grid", margin: "5px 10px", maxWidth: "600px", justifySelf: "center", width: "100%"}}>
        <TextField
          sx={{backgroundColor:"#dedefd"}}
          minRows={3}
          maxRows={3}
          multiline
          onChange={(event: ChangeEvent<HTMLInputElement>) => onLabelChange(event) }
          label={t("Link description")}
          value={label}
          variant="outlined"
        />
      </Box>
      <Box sx={{ maxWidth: "600px", justifySelf: "center", width: "100%", marginRight: "-20px" }}>
        <SaveCancel handleSave={handleSave} handleClose={handleClose} />
      </Box>
    </Box>
  )
}

export default InteractionModalLink