import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { useParams } from "../../../helper/Helper"
import { useStore } from "../../../contexts/store"
import { iEmotion, interactionAnchor } from "../../../../../types/Interaction"
import { Op_addEmotion, Op_deleteAnnotation, Op_editEmotion } from "../../../../../types/Ops"
import Emoji from "../../Elements/Emoji"
import SaveCancel from "../../Elements/SaveCancel"
import { Alert, Box } from "@mui/material"
import EmojiPicker from "../../Elements/EmojiPicker"
import AddReactionIcon from '@mui/icons-material/QuestionMarkOutlined'

const InteractionModalEmotion  = ({
    anchor,
    interaction
  }:
  {
    anchor: interactionAnchor | null,
    interaction: iEmotion
  }) => {
  const { t } = useTranslation()
  const [selectedEmotion, setSelectedEmotion] = useState<{label: string, emotionId: string}>()
  const [showWarning, setShowWarning] = useState(false)
  const { podId, pdfId } = useParams()
  const { sessionStore, podStore, opStore, uiStore } = useStore()
  const { session } = sessionStore
  // in case emotion is edited: load emotion data
  const isTransformation = (interaction.interactionType === "annotation") ? true : false

  useEffect(() => {
    // if emotion is edited: load existing emotion
    if(interaction && !isTransformation) {
      // TODO: swap label and emotionId attributes
      setSelectedEmotion({label: interaction.emotionId, emotionId: interaction.label})
    }
  }, [podStore, interaction, pdfId, isTransformation])

  if(anchor === null) return null

  const handleSave = () => {
    // check if emotion is selected
    if(selectedEmotion) {
      const editAnchor = podStore.getInteractionEditAnchor(interaction.interactionId)
      // distinguish whether emotion is edited or newly created
      if(!isTransformation) {
        // save only if something changes in the input
        if(selectedEmotion.emotionId !== interaction.emotionId || editAnchor) {
          // TODO: swap label and emotionId attributes
          opStore.doOp({
            op: "editEmotion",
            podId: podId,
            data: {
              interactionType: 'emotion',
              usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
              userId: session.user.userId,
              userName: podStore.userPseudonym,
              interactionId: interaction.interactionId,
              mods: {
                anchor: (editAnchor) ? JSON.parse(JSON.stringify(editAnchor)) : JSON.parse(JSON.stringify(anchor)),
                label: selectedEmotion.emotionId,
                tModified: dayjs().unix(),
                emotionId: selectedEmotion.label
              }
            }
          } as Op_editEmotion)
          handleClose()
        }
      } else {
        // transformation: add emotion, delete annotation
        const transformationAnchor = {
          nodeId: anchor.nodeId,
          relText: anchor.relText,
          rects: JSON.parse(JSON.stringify(anchor.rects)),
          tool: anchor.tool
        }
        // TODO: swap label and emotionId attributes
        opStore.doOp({
          op: "addEmotion",
          podId: podId,
          data: {
            interactionType: 'emotion',
            usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
            interactionId: sessionStore.createUuid(),
            userId: session.user.userId,
            userName: podStore.userPseudonym,
            anchor: editAnchor ? JSON.parse(JSON.stringify(editAnchor)) : JSON.parse(JSON.stringify(transformationAnchor)),
            style: { color: null },
            label: selectedEmotion.emotionId,
            reactions: {},
            tCreated: dayjs().unix(),
            tModified: dayjs().unix(),
            emotionId: selectedEmotion.label,
            coid: null,
            tSeen: dayjs().unix(),
            dSeen: 1
          }
        } as Op_addEmotion)
        // delete annotation
        opStore.doOp({
          op: 'deleteAnnotation',
          podId,
          data: {
            userId: sessionStore.session.user.userId,
            userName: podStore.userPseudonym as string,
            usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
            interactionId: interaction.interactionId
          }
        } as Op_deleteAnnotation)
        handleClose()
      }
    } else {
      setShowWarning(true)
    }
  }

  function handleClose() {
    // close modal
    uiStore.closeInteractionModal()
    // delete selected anchor in store
    uiStore.setSelectedAnchor(null)
    // remove edited text selection
    if(interaction) podStore.deleteInteractionEditAnchor(interaction.interactionId)
  }

  function handleEmojiClick(event: any) {
    const emoji = event.detail.unicode
    const label = event.detail.emoji.annotation
    if(emoji && label) {
      setSelectedEmotion({label: label, emotionId: emoji})
    }
    if(showWarning) setShowWarning(false)
  }

  const EmotionPreviw = () => {
    if(selectedEmotion) return (
      <Box sx={{fontSize: "50px"}}>
        <Emoji label={selectedEmotion.label} emotionId={selectedEmotion.emotionId} />
      </Box>
    )
    return (
        <AddReactionIcon sx={{ fontSize: "50px", margin: "13px", color: "#3e9cff40" }} />
    )
  }

  return (
    <Box sx={{display: "grid",  alignItems: "end", gridTemplateRows: "max-content auto"}}>
      <Box sx={{display: "grid", gridTemplateColumns: "auto auto", maxWidth: "500px", margin: "5px 10px", justifySelf: "center", width: "100%"}}>
        <Box sx={{display: "grid", gridTemplateRows:"max-content min-content", justifyItems: "center", alignItems: "center"}}>
          <EmotionPreviw />
          <EmojiPicker handleEmojiClick={handleEmojiClick} label={t('Select Emotion')} />
        </Box>
      </Box>
      <Box sx={{ maxWidth: "600px", justifySelf: "center", width: "100%", marginRight: "-20px" }}>
        {showWarning && (
          <Alert sx={{marginTop: "10px", marginRight: "10px"}} severity="warning">{t('Please select an emotion')}</Alert>
        )}
        <SaveCancel handleSave={handleSave} handleClose={handleClose} />
      </Box>
    </Box>
  )
}

export default InteractionModalEmotion