import { useTranslation } from 'react-i18next'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, IconButton, Switch, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import EditUserInfo from './EditUserInfo'
import { UserInfo } from '../../../../types/User'
import { useStore } from '../../contexts/store'
import { Op_addUserToPod } from '../../../../types/Ops'
import api, { env } from '../../api/api'
import { Check, Error, HourglassTop, Person, QuestionMark } from '@mui/icons-material'
import { debounce } from '@mui/material/utils'
import { podNameMaxLength, podKeyphraseMinLength, podKeyphraseMaxLength, podIdHrMaxLength } from "../../validationConstantsString"


const AddPod = ({open, handleClose, defaultValue }: {open: boolean, handleClose: any, defaultValue?:string|number}) => {
  const { sessionStore, opStore } = useStore()
  const { t } = useTranslation()

  const [ podName, setPodName ] = useState<string>('')
  const [ hasNoKeyphrase, setHasNoKeyphrase ] = useState(false)
  const [ keyphrase, setKeyphrase ] = useState<string>('')
  const [ podIdHr, setPodIdHr ] = useState<string|null>(null)
  const [ podId, setPodId ] = useState<string>('')
  const [ newUserInfo, setNewUserInfo ] = useState<UserInfo|null>(null)
  const [ userInfo, setUserInfo ] = useState<UserInfo|null>(null)
  const [ podIdHrValid, setPodIdHrValid ] = useState<{
    podIdHr:string,
    valid:true|false|'checking'|'unknown',
  }>({podIdHr:'', valid:false})

  useEffect(() => {
    if (!podName && defaultValue) setPodName(defaultValue as string)
    if (!podId && open) setPodId(sessionStore.createUuid())
    if (!userInfo) setUserInfo({
      userId: sessionStore.session.user.userId,
      userName: sessionStore.session.user.idpProvidedUserName || '',
      color:'#eee',
      login:sessionStore.session.user.login,
    })
  }, [podName, defaultValue, podId, open, sessionStore, userInfo])

  const clearForm = () => {
    setPodName('')
    setHasNoKeyphrase(false)
    setKeyphrase('')
    setUserInfo(null)
    setPodId('')
    setPodIdHr(null)
    setPodIdHrValid({podIdHr:'', valid:false})
  }

  const podIdHrBlur = async(podIdHr:string|null) => {
    if (podIdHr) {
      setPodIdHrValid({
        podIdHr,
        valid: 'checking'
      })

      const validPodIdHr:true|false|unknown = await api.checkPodIdHr(podIdHr)

      if (validPodIdHr === false) {
        setPodIdHrValid({
          podIdHr: podIdHr,
          valid: false
        })
      }
      else if (validPodIdHr === true) {
        setPodIdHrValid({
          podIdHr: podIdHr,
          valid: true
        })
      } else {
        setPodIdHrValid({
          podIdHr: podIdHr,
          valid: 'unknown'
        })
      }
    }
  }

  const isValidKeyphrase = () => {
    if(hasNoKeyphrase) return true
    if(!hasNoKeyphrase && keyphrase && keyphrase.trim() && keyphrase.length >= podKeyphraseMinLength && keyphrase.length <= podKeyphraseMaxLength) {
      return true
    }
    return false
  }
  const isValidPodIdHr = () => {
    if(!podIdHr || (podIdHr === podIdHrValid.podIdHr && podIdHrValid.valid === true) ) {
      return true
    }
    return false
  }

  const isValidSubmit = () => {
    if(isValidKeyphrase() && isValidPodIdHr() && podName.length && podName.trim().length && userInfo && userInfo.userName) {
      return true
    }
    return false
  }

  const createPod = async () => {
    if (isValidSubmit() && userInfo) {
      const op:Op_addUserToPod = {
        op: 'addUserToPod',
        podId,
        data: {
          podName: podName,
          createPod: true,
          podIdHr: podIdHr ? podIdHr : null,
          usergroupId: '',
          userId: sessionStore.session.user.userId,
          userName: userInfo.userName,
          login: userInfo.login,
          color:userInfo.color,
          type: 'request',
          keyphrase: !hasNoKeyphrase ? keyphrase : null,
        }
      }
      opStore.doOp(op)
      handleClose()
      clearForm()
    }
  }

  const nameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var newValue = event.target.value
    newValue = newValue.substring(0, podNameMaxLength)
    setPodName(newValue)
  }

  const keyphraseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var newValue = event.target.value.trim()
    newValue = newValue.substring(0, podKeyphraseMaxLength)
    setKeyphrase(newValue)
  }

  const podIdHrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(/[^a-zA-Z0-9\-_]+/g, '-').substring(0, podIdHrMaxLength)
    if (newValue) {
      setPodIdHr(newValue)
      debouncedOnAccept(newValue)
    }
    else {
      setPodIdHr(null)
    }
  }

  const onAccept = React.useCallback((newValue:string) => {
    podIdHrBlur(newValue)
  }, []);

  const debouncedOnAccept = React.useMemo(
    () => debounce(onAccept, 500),
    [onAccept],
  )

  var info = 'Needs userinfo'
  if (userInfo) info = `${userInfo.userName}`

  var validationStatus:JSX.Element|null = null
  if ((podIdHr) && (podIdHr === podIdHrValid.podIdHr) && (podIdHrValid.valid === true)) validationStatus = <Check color="success" />
  if ((podIdHr) && (podIdHr === podIdHrValid.podIdHr) && (podIdHrValid.valid === false)) validationStatus = <Error color="error" />
  if ((podIdHr) && (podIdHr === podIdHrValid.podIdHr) && (podIdHrValid.valid === 'checking')) validationStatus = <HourglassTop />
  if ((podIdHr) && (podIdHr === podIdHrValid.podIdHr) && (podIdHrValid.valid === 'unknown')) validationStatus = <QuestionMark color="warning" />
  if ((podIdHr) && (podIdHr !== podIdHrValid.podIdHr)) validationStatus = <QuestionMark color="warning" />

  return <>
    <Dialog open={open}>
      <DialogTitle>{t('Create New Pod')}</DialogTitle>
      <DialogContent>
        <Box style={{padding:'5px 0 15px 0'}}><span style={{display: 'inline-block', width:30, height:30, padding:3, marginRight:5, textAlign:'center', color:'black', backgroundColor:'#ddd', borderRadius:20, border:'1px solid #000'}}>1</span>{t('Please enter the below information to create a new pod:') as string}</Box>
        <Box>
          <TextField helperText={t('The publicly visible name of the Pod (you can change this later)')} required={true} fullWidth={true} style={{margin:'8px 0'}} label={t('Pod Name')} value={podName} onChange={nameChange} /><br />
          <Box sx={{marginTop: "5px"}}>
            <FormGroup sx={{display: "grid", gridTemplateColumns: "max-content"}}>
              <FormControlLabel control={<Switch checked={hasNoKeyphrase} onChange={() => {setHasNoKeyphrase(!hasNoKeyphrase)}} />} label={t('Access the pod without a password') as string} />
            </FormGroup>
            <TextField sx={{opacity: hasNoKeyphrase ? 0.4 : 1}} disabled={hasNoKeyphrase} helperText={t('Users have to enter this keyphrase with the minimum length {{podKeyphraseMinLength}} to join the pod (you can change this later)', {podKeyphraseMinLength: podKeyphraseMinLength})} style={{verticalAlign:'middle', margin:'8px 0'}} inputProps={{size:24}} label={t('Keyphrase')} value={keyphrase} onChange={keyphraseChange} />
          </Box>
          <Box sx={{display: "grid", width:'100%', gridTemplateColumns: "auto min-content"}}>
            <TextField helperText={t('Pod URL:') + ` ${env.frontendUrl}/pod/${(podIdHr ? `${podIdHr}` : podId)}`} style={{margin:'8px 0'}} label={t('Shortname (characters, letters, hyphen, and underscore)')} value={(podIdHr ? `${podIdHr}` : '')} onChange={podIdHrChange} onBlur={() => podIdHrBlur(podIdHr)} />
            <IconButton sx={{width:40,height:40,m:2}} disabled={validationStatus===null} style={{backgroundColor: '#ccc', opacity: validationStatus ? 1 : 0}} onClick={() => podIdHrBlur(podIdHr)}>{ (validationStatus) }</IconButton>
          </Box>
        </Box>
        <Divider sx={{mt:2, mb:2}} />
        <Box style={{padding:'5px 0 15px 0'}}><span style={{display: 'inline-block', width:30, height:30, padding:3, marginRight:5, textAlign:'center', color:'black', backgroundColor:'#ddd', borderRadius:20, border:'1px solid #000'}}>2</span>{t('Create and join this pod as:')}</Box>
        <Box>
          <Button variant="outlined" onClick={() => setNewUserInfo(userInfo)} startIcon={<Person style={{color: (userInfo?.color ? userInfo.color : '#eee')}} />}>{info}</Button>
        </Box>
        <Divider sx={{mt:3, mb:0}} />
      </DialogContent>
      <DialogActions style={{textAlign:'right'}}>
        <Button onClick={() => { handleClose(); clearForm() }}>{t('Cancel')}</Button>
        <Button variant='contained' disabled={!isValidSubmit()} onClick={() => { createPod() }}>{t('Ok')}</Button>
      </DialogActions>
    </Dialog>
    <EditUserInfo userInfo={newUserInfo} handleClose={(newUserInfo:UserInfo) => { if (newUserInfo) setUserInfo(newUserInfo); setNewUserInfo(null) }} />
  </>
}

export default AddPod