import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../contexts/store';
import App from './App';
import Loading from './Loading';


/** prepare to start the app:
 * waiting for service worker to be loaded and claimed clients
 * ask the service worker (or server) if a session exists
 */
const AppContainer: React.FC = () => {
    const { sessionStore } = useStore()

    if(sessionStore.sessionIsLoading) return <Loading info="Waiting for the session" />

    if (sessionStore.appIsReady) {
        return <App />
    } else {
        return <Loading info="Waiting for 'sessionStore.appIsReady" />
    }
}

export default observer(AppContainer)
