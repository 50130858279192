import { observer } from 'mobx-react-lite'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { useParams } from '../../helper/Helper'
import { useStore } from '../../contexts/store'
import { OpCode } from '../../../../types/OpCodes'
import { Op_deleteAnnotation, Op_editAnnotation } from '../../../../types/Ops'
import ColorPicker from './ColorPicker'
import { alert } from '../../stores/alertStore'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import InteractionIcon from './InteractionIcon'
import {  Divider, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ChangeColorIcon from '@mui/icons-material/FormatColorFill'
import CopyIcon from '@mui/icons-material/ContentCopy'

const TransformInteractionMenu = () => {
  const { uiStore, podStore, opStore, sessionStore, alertStore } = useStore()
  const { podId } = useParams()
  const { session } = sessionStore
  const isOpen = uiStore.transformInteractionMenu.isOpen
  const anchor = uiStore.transformInteractionMenu.anchor
  const interactionId = uiStore.transformInteractionMenu.interactionId
  const pod = podStore.pod

  if (!pod || !anchor || !interactionId) return null
  // get interaction data
  const interaction = pod.getInteraction(interactionId)
  if(!interaction) return null
  const color = interaction.style.color ? interaction.style.color : uiStore.getInteractionColor("annotation")

  // check which add operations are allowed
  const operations:OpCode[] = ['addAnnotation', 'addComment', 'addLink', 'addTagging', 'addWeblink', 'addEmotion', 'addReadingQuestion']
  const allowedOperations: OpCode[] = []
  for(const op of operations) {
    const isAllowed = pod.isAllowed(op)
    if(isAllowed) allowedOperations.push(op)
  }
  // check if delete is allowed
  const deleteIsAllowed = pod.isAllowed(`deleteAnnotation` as OpCode, interactionId)
  const editIsAllowed = pod.isAllowed(`editAnnotation` as OpCode, interactionId)

  const handleClose = () => {
    // close modal
    uiStore.closeTransformInteractionMenu()
  }

  const handleDelete = () => {
    if(deleteIsAllowed) {
      opStore.doOp({
        op: 'deleteAnnotation',
        podId,
        data: {
          userId: sessionStore.session.user.userId,
          userName: podStore.userPseudonym as string,
          usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
          interactionId,
        }
      } as Op_deleteAnnotation)
    }
    handleClose()
  }

  const openEditModal = (op: OpCode) => {
    // save selected rects and transform operation for EditInteractionModal
    uiStore.setSelectedAnchor(interaction.anchor)
    uiStore.setSelectedOperation(op)
    // open modal with isTransformation is true
    uiStore.openInteractionModal(interactionId, true)
    handleClose()
  }

  const changeColor = (color: string) => {
    if(color !== interaction.style.color) {
      opStore.doOp({
        op: "editAnnotation",
        podId: podId,
        data: {
          usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
          userId: session.user.userId,
          userName: podStore.userPseudonym,
          interactionId: interaction.interactionId,
          mods: {
            style: {
              color: color
            },
            tModified: dayjs().unix()
          }
        }
      } as Op_editAnnotation)
      // next annotation will use newly selected color
      uiStore.setAnnotationColor(color)
    }
    handleClose()
  }

  const copyToClipboard = () => {
    if(interaction.anchor && interaction.anchor.relText) {
      navigator.clipboard.writeText(interaction.anchor.relText)
      alertStore.push(alert(t('The selected text was copied to the clipboard'), 'success'))
    }
    handleDelete()
  }

  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      id="transform-interaction-menu"
      anchorEl={anchor}
      open={isOpen}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'transform-interaction-button',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {allowedOperations.map((op: OpCode) => (
        <Tooltip
          key={op}
          arrow
          slotProps={{popper: {modifiers: [{name: 'offset',options: {offset: [0, -5],},},],},}}
          enterDelay={800}
          title={
            <Typography sx={{padding: "5px"}} fontSize={15}>{t(`Transform to ${op}`)}</Typography>
          }
          placement="right-start"
        >
          <MenuItem onClick={() => openEditModal(op)} key={op} sx={{color: "#26266b"}}>
            <ListItemIcon sx={{color: "#26266b"}}>
              <InteractionIcon fontSize="small" interactionType={op} />
            </ListItemIcon>
            <ListItemText>{t(op)}</ListItemText>
            </MenuItem>
        </Tooltip>
      ))}
      <Divider orientation="horizontal" variant="middle" />
      {editIsAllowed &&
        <MenuItem disableRipple sx={{color: "#26266b", cursor: "default", padding: "0 16px 6px 16px", "&:hover":{backgroundColor: "white"} }}>
          <ListItemIcon>
            <ChangeColorIcon fontSize="small" sx={{color: "#26266b"}} />
          </ListItemIcon>
          <ListItemText sx={{marginRight: "10px"}}>
            <ColorPicker sx={{height: "22px", width: "100%"}} color={color} setColor={(color) => changeColor(color)} />
          </ListItemText>
        </MenuItem>
      }
      <MenuItem onClick={copyToClipboard} sx={{color: "#26266b"}}>
        <ListItemIcon>
          <CopyIcon fontSize="small" sx={{color: "#26266b"}} />
        </ListItemIcon>
        <ListItemText>{t('Copy text')}</ListItemText>
      </MenuItem>
      {deleteIsAllowed &&
        <MenuItem onClick={handleDelete} sx={{color: "#d24b4e"}}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" sx={{color: "#d24b4e"}} />
          </ListItemIcon>
          <ListItemText>{t('Delete')}</ListItemText>
        </MenuItem>
      }
    </Menu>
  )
}

export default observer(TransformInteractionMenu)