import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Loading: React.FC<{info: string}> = ({info}) => {
    return (
        <Backdrop
            sx={{ color: "#888", backgroundColor: "inherit", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            >
                <div style={{textAlign:'center'}}>
                <div>
                    <CircularProgress color="inherit" />
                </div>
                <br />
                <div>[{info}]</div>
                </div>
        </Backdrop>
    )
}

export default Loading