import { Box } from "@mui/material"
import { useStore } from "../../contexts/store"
import { observer } from "mobx-react-lite"

// scales the margin with the entire content according to the scaling factor
// for this the height must be set to the original pdf page and then scaled up or down
// the children contains the contents of the margin
// transformOrigin indicates whether the margin is left [top, right] or right [top, left] of the page
const PageMargin = ({
    height,
    interactionEditId,
    scale,
    transformOrigin,
    position,
    margin
  }: {
    height: number,
    interactionEditId: string | undefined | null
    scale: number,
    transformOrigin: string,
    position: string,
    margin: number|null
  }) => {
  const { uiStore } = useStore()
  let marginSpace = undefined

  if (position === 'left') marginSpace = margin ? margin : 0
  if (position === 'right') marginSpace = margin ? -margin : 0

  return (
    <Box sx={{
      backgroundColor: (position === 'right' && !interactionEditId) ? "ghostwhite" : "",
      display: "grid",
      height: height/scale,
      justifyContent: "center",
      left: (position === 'left') ? marginSpace : "",
      position:'relative',
      transform: `scale(${scale}, ${scale})`,
      transformOrigin: transformOrigin,
      width: uiStore.pageMargin,
      zIndex: (position === 'left') ? 6 : "",
    }}>
    </Box>
  )

}

export default observer(PageMargin)