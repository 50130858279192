import { UIEvent, useEffect, useState } from 'react'
import { useParams } from "../../stores/uiStore"
import { observer } from 'mobx-react-lite'
import { useStore } from '../../contexts/store'
import { PodClass } from '../../classes/Pod'
import ErrorBoundaryComponent from '../ErrorBoundaryComponent'

import Contents from '../Pod/Contents'
import Activity from '../Pod/Activity/Activity'
import Conversations from '../Pod/Conversation/Conversations'
import Notes from '../Pod/Notes'
import PodUsers from '../Pod/PodUsers'
import Settings from '../Pod/Settings'

import { Box } from "@mui/material"
import PodHeader from '../Pod/PodHeader'
import ImportDialog from '../Pod/Contents/ImportDialog'


const Pod = ({activeTabIndex, activeConversationId, activeSubTabIndex}: {activeTabIndex?: string, activeConversationId?: string, activeSubTabIndex?: string}) => {
  const { podId } = useParams()
  const { podStore, uiStore } = useStore()
  const pod:PodClass|null = podStore.pod
  const deviceWidth = uiStore.deviceWidth
  const containerPadding = (deviceWidth === "tiny" || deviceWidth === 'smaller') ? 16 : 40

  useEffect(() => {
    if(activeTabIndex) uiStore.setActivePodTab(podId, activeTabIndex)
    // reset show unread conversations
    uiStore.setShowUnreads(false)
  }, [activeTabIndex])

  if (pod === null) return <div>'pod' is null when trying to show pod</div>

  const isAdmin = pod.isAllowed('deletePod', pod.podId)

  const initScrollPos = (tab: string) => {
    const scrollPos = uiStore.getPodScrollPos(podId, tab)
    const podContainer = document.getElementById("pod-tab-scroll-container")
    if(scrollPos !== null && podContainer) {
      podContainer.scroll({
        top: scrollPos
      })
    }
  }

  const Main = () => {
    if(activeTabIndex === "activity") return (
      <ErrorBoundaryComponent>
        <Activity initScrollPos={initScrollPos} />
      </ErrorBoundaryComponent>
    )
    if(activeTabIndex === "conversations") return (
      <ErrorBoundaryComponent>
        <Conversations activeConversationId={activeConversationId} />
      </ErrorBoundaryComponent>
    )
    if(activeTabIndex === "notes") return (
      <ErrorBoundaryComponent>
        <Notes pod={pod} initScrollPos={initScrollPos} />
      </ErrorBoundaryComponent>
    )
    if(activeTabIndex === "people") return (
      <ErrorBoundaryComponent>
        <PodUsers initScrollPos={initScrollPos} />
      </ErrorBoundaryComponent>
    )
    if(activeTabIndex === "settings") return (
      <ErrorBoundaryComponent>
        <Settings initScrollPos={initScrollPos} activeSubTabIndex={activeSubTabIndex} />
      </ErrorBoundaryComponent>
    )
    return (
      <ErrorBoundaryComponent>
        <Contents pod={pod} initScrollPos={initScrollPos} />
      </ErrorBoundaryComponent>
    )
  }


  const updateScrollPos = (event: UIEvent<HTMLDivElement>) => {
    if(event && event.currentTarget && event.currentTarget.scrollTop !== undefined && activeTabIndex) {
      const scrollTop = event.currentTarget.scrollTop
      uiStore.setPodScrollPos(podId, activeTabIndex, scrollTop)
    }
  }

  return (
    <ErrorBoundaryComponent>
      <Box className="overflowWrapper" sx={{
          display: "grid",
          gridTemplateRows: "max-content auto",
      }}>

        {/* Header */}
        <PodHeader activeTabIndex={activeTabIndex} />

        {/* Main */}
        <Box className="overflowWrapper" sx={{backgroundColor: "whitesmoke"}}>
          <Box className="overflowWrapper" sx={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: `${1280 + (2*containerPadding)}px`,
            paddingTop: "30px",
            paddingLeft: `${containerPadding}px`,
            paddingRight: `${containerPadding}px`,
            marginBottom: (deviceWidth !== "tiny" && deviceWidth !== 'smaller') ? "10px" : "5px",
            alignItems: 'start',
            backgroundColor:'white',
          }}>
            <Box id="pod-tab-scroll-container" onScroll={(event) => updateScrollPos(event)} className="overflowWrapper" sx={{ height: "100%", p: (deviceWidth !== "tiny" && deviceWidth !== 'smaller') ? "12px 12px 0 12px" : "0 1px"}}>
              <Main />
            </Box>
          </Box>
        </Box>

      </Box>
      <ImportDialog />
    </ErrorBoundaryComponent>
  )

}

export default observer(Pod)
