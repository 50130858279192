import { t } from "i18next"
import { Box } from "@mui/material"

const NewMessage = () => {
  return (
    <Box sx={{
      textAlign: "center",
      position: "relative",
      "&:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: 0,
        right: 0,
        borderTop: "solid 1px #d29e23"
      },
    }}>
      <Box sx={{
        color: "#d29e23",
        margin: 0,
        padding: "0 10px",
        position: "relative",
        background: "#fff",
        display: "inline-block",
        zIndex: 1,
      }}>
        {t('New Messages')}
      </Box>
    </Box>
    )
}

export default NewMessage