import { observer } from "mobx-react-lite"
import { t } from "i18next"
import { useStore } from "../../../contexts/store"
import { Box, Button, Chip, Typography, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Card, CardMedia, CardContent } from "@mui/material"
import { PersonAdd } from '@mui/icons-material'

import React, { useState } from "react"
import { PodClass } from "../../../classes/Pod"

import { env } from "../../../api/api"
import QRCode from "qrcode"
import { alert } from "../../../stores/alertStore"

// message from thread
const AddUserButton = () => {
  const { podStore, uiStore, alertStore } = useStore()
  const [ selectActionAnchor, setSelectActionAnchor] = useState<null|HTMLButtonElement>(null)
  const [ qrCode, setQrCode ] = useState<string|null>(null)
  const pod:PodClass|null = podStore.pod

  const url = pod ? `${env.frontendUrl}/pod/${pod.podId}` : undefined

  React.useEffect(() => {
    if (url && qrCode===null) {
      setQrCode("")
      QRCode.toDataURL(url).then((r:string) => {
        setQrCode(r)
      })
    }
  })

  if (!pod) return null

  const handleClose = () => {
    setSelectActionAnchor(null)
  }

  const handleAddUser = () => {
    uiStore.openAddUserModal()
  }

  const downloadQR = async() => {
    if (url) {
      const qr = await QRCode.toDataURL(url)
      const element = document.createElement("a")
      element.href = qr
      element.download = "SHRIMP.png"
      element.type = 'image/png'
      document.body.appendChild(element)
      element.click()
      element.remove()
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url||'')
    alertStore.push(alert(t(`The pod's URL was copied to the clipboard`), 'success'))
  }

  /** <Divider><div style={{border:'3px solid #888', color:'#888', lineHeight:'2.2em', borderRadius:'2em', width:'3em', height:'3em', padding:'4px'}}>or</div></Divider> */

  return  <Box sx={{alignContent: "center", display: "grid", height: "50px", justifyItems: "end", padding: "0 20px"}}>
    <Button onClick={(e:React.MouseEvent<HTMLButtonElement>) => setSelectActionAnchor(e.currentTarget)} variant="contained" size="small" startIcon={<PersonAdd />}>
      {t('addUser_addUserToPod')}
    </Button>
    <Dialog
      open={Boolean(selectActionAnchor)}
      onClose={() => { setSelectActionAnchor(null) }}
      >
      <Box>
      <DialogTitle>{t('How do you want to add users?')}</DialogTitle>
      <DialogContent>

        <Divider />

        <Typography component="div" variant="subtitle1" sx={{mt:2}}>
          {t('addUser_invite_existing_users')}
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'row'}}>
          <img style={{width:100, height:100}} src={qrCode||""} />
          <Box sx={{p:1}}>
            <Typography variant="body2" color="text.secondary" component="div">
              {t('adduser_explainInvite')}: <a href={url}>{url}</a>
              <Box>{t('reminder_share_keyphrase')}</Box>
            </Typography>
            <Box style={{textAlign:'right', margin:'10px 0'}}>
              <Button variant="outlined" onClick={copyToClipboard}>{t('Copy URL to Clipboard')}</Button>
              <Button variant="outlined" sx={{ml:1}} onClick={downloadQR}>{t('Download QR Code')}</Button>
            </Box>
          </Box>
        </Box>

        <Divider><Chip label={t('or')} color='secondary' /></Divider>

        <Typography component="div" variant="subtitle1" sx={{mt:2}}>
          {t('adduser_create_instant')}
        </Typography>

        <Box sx={{display: 'flex', flexDirection: 'row'}}>
          <PersonAdd style={{fontSize:100}} />
          <Box sx={{p:1}}>
            <Typography variant="body2" color="text.secondary" component="div">
              {t('adduser_explain_create')}
            </Typography>
            <Box style={{textAlign:'right', margin:'10px 0'}}>
              <Button variant="outlined" onClick={() => { handleClose(); handleAddUser() }}>
                {t('Create New Users')}
              </Button>
            </Box>
          </Box>
        </Box>
        <Divider />
      </DialogContent>
      <DialogActions style={{textAlign:'right'}}>
        <Button onClick={handleClose}>{t('Cancel')}</Button>
      </DialogActions>
      </Box>
    </Dialog>
  </Box>

}

export default observer(AddUserButton)