import { observer } from "mobx-react-lite"
import { useStore } from "../../../contexts/store"
import { Box, Chip } from "@mui/material"
import { InteractionType, iTag } from "../../../../../types/Interaction"
import { navigate } from "wouter/use-location"
import { useParams } from "../../../stores/uiStore"
import TagIcon from '@mui/icons-material/Sell'

// shows interaction as marginalia icon
const MarginaliaTag = (
  {
    item,
    pdfUrl,
    height,
    width
  }: {
    item: iTag,
    pdfUrl: string,
    height: number,
    width: number
  }
) => {
  const { podStore, uiStore } = useStore()
  const { interactionId } = useParams()
  // get the length of time this interaction has been viewed
  const interactionType = item.interactionType
  let color = uiStore.getInteractionColor(interactionType as InteractionType)
  const tagProp = podStore.getTagProp(item.tagId)
  if(!tagProp) return null

  // open drawer, if interaction is clicked
  const handleClick = (interactionType: string, interactionId: string) => {
    navigate(`${pdfUrl}/${interactionType}/${interactionId}`, {replace: true})
    // open tag sidebar, reset open tags
    uiStore.clearActiveSidebarTags()
    uiStore.setSidebarStatus('tag')
  }


  return (
    <Box onClick={() => handleClick(interactionType, item.interactionId)}>
      <Chip
        color="secondary"
        icon={
          <TagIcon sx={{fontSize: "11px", justifySelf: "start"}} />
        }
        label={`# ${tagProp.name}`}
        variant="outlined"
        sx={{
          backgroundColor: (interactionId === item.interactionId) ? color : "white",
          borderLeft: `8px solid ${color}`,
          color: (interactionId === item.interactionId) ? "white" : "rgba(0, 0, 0, 0.87)",
          cursor: "pointer",
          display: "grid",
          gridTemplateColumns: "min-content auto",
          justifyContent: "start",
          alignContent: "center",
          fontSize: "10px",
          fontWeight: (interactionId === item.interactionId) ? 500 : 350,
          height: height,
          p: "10px",
          margin: "2px",
          width: width,
          "&:hover": {
            backgroundColor: color,
            color: "white",
            fontWeight: 500
          }
        }}
      />
    </Box>
  )
}

export default observer(MarginaliaTag)