import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../contexts/store'
import podStore from '../../stores/podStore'
import api from '../../api/api';
import { idbStoredOp } from '../../../../types/Ops'
import { Button } from '@mui/material';
import { PdfPage } from '../../../../types/Content';
import TimeFromNow from '../Elements/TimeFromNow';
import InteractionPreview from '../Elements/InteractionPreview';

const System: React.FC = () => {
  const pendingOps: idbStoredOp[] = []
  const { broadcastStore, opStore, uiStore } = useStore()
  const [ swFingerprint, setSwFingerprint ] = useState('')
  const [ backendFingerprint, setBackendFingerprint ] = useState('')
  const [ swPendingOps, setSwPendingOps ] = useState(pendingOps)
  const [ highlighted, setHighlighted ] = useState('')
  const [ pingTime, setPingTime] = useState<number|string|null>(null)

  useEffect(() => {
    if (swPendingOps.length !== broadcastStore.serviceWorkerStatus.syncQueueLength) {
      const fetchData = async () => {
        await getServiceWorkerPendingOps(broadcastStore.serviceWorkerStatus.syncQueueLength)
      }
      fetchData().catch(console.error)
    }
    if (pingTime === null) doPing()
  })

  var localPodFingerprint = '...'
  var serviceWorkerPodFingerprint = '...'
  const clientQueueLength = opStore.static.queue.length ? opStore.static.queue.length : 0

  const pod = podStore.pod
  if (pod) {
    localPodFingerprint = pod.fingerprint()
    if (pod.serviceWorkerFingerprint) serviceWorkerPodFingerprint = pod.serviceWorkerFingerprint
  }

  const doPing = async () => {
    setPingTime('...pending')
    const t = await api.ping()
    await new Promise((resolve) => { setTimeout(resolve, 1000) })
    setPingTime(t)
  }

  const updateSwFingerprint = async () => {
    if (podStore.pod?.podId) {
      const res = await api.getPodFingerprint(podStore.pod.podId, false)
      if (res.status === 200) {
        setSwFingerprint(res.serviceWorkerData.replace())
        setBackendFingerprint(res.backendData.replace())
      }
    }
  }

  const getServiceWorkerPendingOps = async (n:number) => {
    if ((n) && (swPendingOps.length === 0)) {
      const res = await api.getPendingOps()
      if (res) setSwPendingOps(res); else setSwPendingOps([])
    }
    return null
  }

  var decodeEntities = (function() {
    // this prevents any overhead from creating the object each time
    var element = document.createElement('div');

    function decodeHTMLEntities (str:any) {
      if(str && typeof str === 'string') {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = '';
      }

      return str;
    }

    return decodeHTMLEntities;
  })();

  const handleClick = (data:any) => {
    setHighlighted(decodeEntities(data))
  }

  const frontendLines = podStore.pod?.fingerprint(false).split("\n")
  const serviceWorkerLines = swFingerprint.split("\n")
  const backendLines = backendFingerprint.split("\n")

  const fingerprintLines:Array<JSX.Element> = []
  for(var i=0; i<Math.max(frontendLines?.length, serviceWorkerLines?.length, backendLines?.length); i++) {
    var lineStyle = {
      color:'darkgreen',
    }
    if ((frontendLines[i] !== serviceWorkerLines[i]) || (serviceWorkerLines[i] !== backendLines[i])) lineStyle = {
      color:'red',
    }
    fingerprintLines.push(<tr key={i} style={lineStyle}>
      <td onMouseOver={e => handleClick(e.currentTarget.innerHTML)} style={{borderRight:'1px solid #000', borderBottom:'1px solid #000', backgroundColor:(frontendLines[i] === highlighted) ? '#afa' : '#fff'}}>{frontendLines[i]}</td>
      <td onMouseOver={e => handleClick(e.currentTarget.innerHTML)} style={{borderRight:'1px solid #000', borderBottom:'1px solid #000', backgroundColor:(serviceWorkerLines[i] === highlighted) ? '#afa' : '#fff'}}>{serviceWorkerLines[i]}</td>
      <td onMouseOver={e => handleClick(e.currentTarget.innerHTML)} style={{borderRight:'1px solid #000', borderBottom:'1px solid #000', backgroundColor:(backendLines[i] === highlighted) ? '#afa' : '#fff'}}>{backendLines[i]}</td>
    </tr>
    )
  }

  const mostRecentlyViewed: {tSeen:number, info:string}[] = []

  if (podStore.pod) {
    for(const nodeId in podStore.pod.content.pdfFiles) {
      const file = podStore.pod.content.pdfFiles[nodeId]

      file.pages.forEach((page:PdfPage, i:number) => {
        if (page && page.tSeen) mostRecentlyViewed.push({
          tSeen: page.tSeen,
          info: `Page ${i+1} of ${file.name} (for ${page.dSeen} s total)`
        })})

      for(const interactionId in file.comments) {
        const interaction = file.comments[interactionId]
        if (interaction.tSeen) mostRecentlyViewed.push({
          tSeen: interaction.tSeen,
          info: `Comment "${interaction.label}" in PDF ${file.name} (for ${interaction.dSeen} s total)`
        })
      }

      for(const interactionId in file.links) {
        const interaction = file.links[interactionId]
        if (interaction.tSeen) mostRecentlyViewed.push({
          tSeen: interaction.tSeen,
          info: `Link "${interaction.label}" in PDF ${file.name} (for ${interaction.dSeen} s total)`
        })
      }

      for(const interactionId in file.taggings) {
        const interaction = file.taggings[interactionId]
        if (interaction.tSeen) mostRecentlyViewed.push({
          tSeen: interaction.tSeen,
          info: `Tag "${interaction.tagId}" in PDF ${file.name} (for ${interaction.dSeen} s total)`
        })
      }

      for(const interactionId in file.weblinks) {
        const interaction = file.weblinks[interactionId]
        if (interaction.tSeen) mostRecentlyViewed.push({
          tSeen: interaction.tSeen,
          info: `Weblink "${interaction.label}" in PDF ${file.name} (for ${interaction.dSeen} s total)`
        })
      }

      for(const interactionId in file.emotions) {
        const interaction = file.emotions[interactionId]
        if (interaction.tSeen) mostRecentlyViewed.push({
          tSeen: interaction.tSeen,
          info: `Emotion "${interaction.label}" in PDF ${file.name} (for ${interaction.dSeen} s total)`
        })
      }

    }

    for(const threadId in podStore.pod.content.threads) {
      const thread = podStore.pod.content.threads[threadId]
      thread.messages.forEach((msg) => {
        if (msg.tSeen) mostRecentlyViewed.push({
          tSeen: msg.tSeen,
          info: `Message "${msg.text}" (for ${msg.dSeen} s total)`
        })
      })
    }

    mostRecentlyViewed.sort((a, b) => b.tSeen - a.tSeen)

    mostRecentlyViewed.length = Math.min(25, mostRecentlyViewed.length)
  }


  return <div style={{overflow:'scroll'}}>
{
      /**
    <InteractionPreview interactionId="1yJ.B.J.PJwtE" width={400} height={150} />
    <InteractionPreview interactionId="1hN.1.1N" width={400} height={150} />
    <InteractionPreview interactionId="21v.1.8.12ZV5h" width={400} height={150} scrolling="auto" />
    <InteractionPreview interactionId="21v.1.C.12ZyP1" width={400} height={150} scrolling="auto" />
    <InteractionPreview interactionId="21v.1.8.12ZV5h" width={400} height={400} />

    <InteractionPreview interactionId="1hN.1.1N" width={400} height={200} />
    <InteractionPreview interactionId="21v.1.O.12n2vu" width={400} height={200} />


      <InteractionPreview interactionId="1yJ.B.J.PJwtE" width={400} height={150} />
    <InteractionPreview interactionId="1hN.1.1N" width={400} height={150} />
    <InteractionPreview interactionId="21v.1.8.12ZV5h" width={400} height={150} />
    <InteractionPreview interactionId="21v.1.C.12ZyP1" width={400} height={150} scrolling="auto" />
       */
    }
    <h1>Pod {podStore.pod?.podId}</h1>
    <button disabled={!(podStore.pod?.podId)} onClick={async() => { if (podStore.pod?.podId) { const podId = podStore.pod?.podId; await podStore.resetPod(podId); podStore.loadPod(podId) } }} title={'Unloads the pod and triggers a reload from the backend'}>
      Reset Pod
    </button>
    <h2>{mostRecentlyViewed.length} Most recently viewed</h2><ul>
    { mostRecentlyViewed.map((e, i) => {
      return <li key={i}><TimeFromNow timestamp={e.tSeen * 1000} />: {e.info}</li>
    }) }
    </ul>
    <h2>Sync</h2>
    <pre>
      LastSyncOid: {podStore.pod?.lastSyncOid}<br />
      <span onClick={doPing}>SW-Ping:     { pingTime ? ((typeof pingTime === 'object') ? JSON.stringify(pingTime, null, 2) : pingTime) : '---' }</span>
    </pre>
    <h2>Fingerprint</h2>
      <pre>
        Client:        {localPodFingerprint}<br />
        ServiceWorker: {serviceWorkerPodFingerprint}
      </pre>
    Unhashed Fingerprints: <Button variant="outlined" onClick={updateSwFingerprint}>Click to load serviceWorker/Backend Data</Button><br />
    (Highlight: {highlighted})
    <table style={{fontSize:'9px', width:'100%'}}>
      <tbody>
        <tr>
          <th style={{width:'33.4%', borderBottom:'1px solid #000'}}>Client</th>
          <th style={{width:'33.3%', borderBottom:'1px solid #000'}}>Service Worker</th>
          <th style={{width:'33.3%', borderBottom:'1px solid #000'}}>Backend</th>
        </tr>
        {fingerprintLines}
      </tbody>
    </table>
    <h2>{clientQueueLength} Pending Ops in Client</h2>
    <ol>
    {opStore.static.queue.map((row: any, i:number) => {
      return <li key={i}>{row.opLogId}: {row.op}</li>
    })}
    </ol>
    <h2>{broadcastStore.serviceWorkerStatus.syncQueueLength} Pending Ops in ServiceWorker</h2>
    <table border={1}><tbody>
    { swPendingOps.map((row: any, i:number) => {
      return <tr key={i}>
        <td>{i+1}</td>
        <td>{row.podId}</td>
        <td>{row.opLogId}</td>
        <td>{row.op}</td>
      </tr>
    }) }
    </tbody></table>
  </div>
}

export default observer(System)