import { ChangeEvent, useEffect, useState } from 'react'
import { useStore } from '../../contexts/store'
import Box from '@mui/material/Box'
import SendIcon from '@mui/icons-material/Send'
import { Button } from '@mui/material'
import Textfield from './TextField'

const MessageArea = (props: {placeholder: string, disabled: boolean, submitInput: (input: string) => void, value: string | null}) => {
  const { uiStore } = useStore()
  const [input, setInput] = useState("")
  const { value, placeholder, disabled, submitInput } = props

  useEffect(() => {
    // if a value was given, pass it to the input field
    if(value) {
      setInput(value)
    }
  }, [props.value])

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setInput(e.target.value)
  }

  function submit() {
    if(input !== '') {
      if (!disabled) submitInput(input)
      setInput('')
    }
  }

  return (
    <Box sx={{display: "grid", gridTemplateColumns: "auto max-content", padding: (uiStore.deviceWidth === "tiny" || uiStore.deviceWidth === 'smaller') ? "10px 20px 10px 20px" : "10px 20px 20px 20px"}}>
      <Textfield
        handleInputChange={handleInputChange}
        handleClose={() => {}}
        placeholder={placeholder}
        minRows={1}
        submit={submit}
        value={input}
        disabled={disabled}
      />
      <Button disabled={disabled} onClick={submit} variant="contained" endIcon={<SendIcon />} style={{justifyContent: "start", margin: "2px 10px"}} />
    </Box>
  )
}

export default MessageArea
