import { observer } from "mobx-react-lite"
import dayjs from "dayjs"
import { navigate } from "wouter/use-location"
import { createRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useStore } from "../../../contexts/store"
import { useParams } from "../../../helper/Helper"
import { baseUrl } from "../../../api/api"
import { Interaction, interactionAnchor } from "../../../../../types/Interaction"
import { Op_addLink, Op_deleteAnnotation } from "../../../../../types/Ops"
import DialogWrapper from "../../Elements/DialogWrapper"
import InteractionOpHeader from "../../Elements/InteractionOpHeader"
import Viewer from "../Viewer"
import FileSelector from "../../Elements/FileSelector"

import { Alert, Box, Button, DialogContent, DialogTitle } from "@mui/material"

const AddLinkModal = ({anchor, interaction}: {anchor: interactionAnchor, interaction: Interaction}) => {
  const { t } = useTranslation()
  const { pdfId, podId } = useParams()
  const { sessionStore, uiStore, podStore, opStore } = useStore()
  const { session } = sessionStore
  const [nodeId, setNodeId] = useState<string>("")
  const [isValidLink, setIsValidLink] = useState(true)
  const deviceWidth = uiStore.deviceWidth
  // if isOpen is true, show modal
  const isOpen = uiStore.interactionModal.isOpen
  const viewerListRef: any = createRef()

  const handleClose = () => {
    // close modal
    uiStore.closeInteractionModal()
    // delete selected anchor in store
    uiStore.setSelectedAnchor(null)
    // remove edited text selection
    uiStore.deleteSelectedLinkAnchor()
  }

  const handleFileChange = (nodeId: string) => {
    if(!isValidLink) setIsValidLink(true)
    // remove edited text selection
    uiStore.deleteSelectedLinkAnchor()
    setNodeId(nodeId)
  }

  const createLink = (proceedWithEditing: boolean) => {
    if (podId) {
      const editAnchor = podStore.getInteractionEditAnchor(interaction.interactionId)
      const srcInteractionId = sessionStore.createUuid()
      const dstInteractionId = sessionStore.createUuid()
      const linkId = sessionStore.createUuid()
      // get link anchor
      const linkAnchor = uiStore.selectedLinkAnchor

      if(anchor && linkAnchor) {
        // consider editAnchor: if it is possible to edit the source link before creating it
        const transformationAnchor = {
          nodeId: anchor.nodeId,
          relText: anchor.relText,
          rects: JSON.parse(JSON.stringify(anchor.rects)),
          tool: anchor.tool
        }
        const addLinking1:Op_addLink = {
          op: 'addLink',
          podId: podId,
          data: {
              coid: null,
              interactionId: srcInteractionId,
              interactionType: 'link',
              userId: session.user.userId,
              userName: podStore.userPseudonym,
              usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
              linkId: linkId,
              linkType: 'related',
              which: 'src',
              other: dstInteractionId,
              anchor: editAnchor ? JSON.parse(JSON.stringify(editAnchor)) : JSON.parse(JSON.stringify(transformationAnchor)),
              style: { color: null },
              label: '',
              reactions: {},
              tCreated: dayjs().unix(),
              tModified: dayjs().unix(),
              tSeen: dayjs().unix(),
              dSeen: 1
          }
        }
        const addLinking2: Op_addLink = {
          op: 'addLink',
          podId: podId,
          data: {
              coid: null,
              interactionId: dstInteractionId,
              interactionType: 'link',
              userId: session.user.userId,
              userName: podStore.userPseudonym,
              usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
              linkId: linkId,
              linkType: 'related',
              which: 'dst',
              other: srcInteractionId,
              anchor: {
                nodeId: linkAnchor.nodeId,
                relText: linkAnchor.relText,
                rects: JSON.parse(JSON.stringify(linkAnchor.rects)),
                tool: linkAnchor.tool
              },
              style: { color: null },
              label: '',
              reactions: {},
              tCreated: dayjs().unix(),
              tModified: dayjs().unix(),
              tSeen: dayjs().unix(),
              dSeen: 1
          }
        }
        opStore.doOp(addLinking1)
        opStore.doOp(addLinking2)
        // delete annotation
        opStore.doOp({
          op: 'deleteAnnotation',
          podId,
          data: {
            userId: sessionStore.session.user.userId,
            userName: podStore.userPseudonym as string,
            usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
            interactionId: interaction.interactionId
          }
        } as Op_deleteAnnotation)

        // navigate to edit Link
        handleClose()
        // if true, open edit modal for link
        if(proceedWithEditing) {
          // fix bug: if the target link is on another page, the drawer blocks the description box.
          // therefore, always open the drawer first before opening the link modal.
          navigate(`${`/pod/${podId}/pdf/${pdfId}`}/link/${srcInteractionId}`, {replace: true})
          setTimeout(() => {
            uiStore.openInteractionModal(srcInteractionId, false)
          }, 10)
        }
      } else {
        // show warning for missing anchor
        setIsValidLink(false)
      }
    }
  }

  const Title = () => (
    <DialogTitle sx={{padding: 0}}>
      <InteractionOpHeader color={uiStore.getInteractionColor("addLink")} interactionOp={"addLink"} handleClose={handleClose} />
    </DialogTitle>
  )

  const ViewerElement = () => {
    if(nodeId === "" || !nodeId) {
      return (
        <Box sx={{
          alignItems: "center",
          color: "#6d6969",
          display: "grid",
          justifyContent: "center",
          fontSize: "18px",
          fontWeight: "300",
          margin: "25px",
          textAlign: "center"
        }}>
          {t('Select the Pdf above to which the link target should refer')}
        </Box>
      )
    } else {
      const pdfUrl = `${baseUrl}/pdf/${nodeId}`
      const thumbnailUrl = `${baseUrl}/pageView/${nodeId}`
      return (
        <Viewer
          interactionEditId={"addLink"}
          pageListRef={viewerListRef}
          pdfId={nodeId}
          pdfUrl={pdfUrl}
          podId={podId}
          storeId={`addLink`}
          thumbnailUrl={thumbnailUrl}
        />
      )
    }
  }

  const AddLinkContent = () => (
    <DialogContent sx={{ display: "grid", gridTemplateRows: "min-content 1fr max-content", backgroundColor: "white", height: "100%", padding: 0}}>
      <Box>
        <FileSelector nodeId={nodeId} handleFileChange={handleFileChange} />
      </Box>
      <ViewerElement />
      <Box>
        {!isValidLink &&
          <Alert sx={{justifyContent: "center", marginTop: "3px", padding: "0 10px", alignItems: "center"}} severity="warning">{t('Select a text passage to which you would like to link')}</Alert>
        }
        <Box sx={{display: "flex", gridGap: "10px", flexWrap: "wrap", margin: "12px"}}>
          <Button sx={{ backgroundColor: "white", flex: 1 }} variant="outlined" onClick={() => createLink(true)}>{t('Create and edit link')}</Button>
          <Button sx={{ flex: 1 }} variant="contained" onClick={() => createLink(false)}>{t('Add Link')}</Button>
        </Box>
      </Box>
    </DialogContent>
  )

  if(deviceWidth === "tiny") {
    return (
      <DialogWrapper modus="smartphone" isOpen={isOpen} fullHeight={false} handleClose={handleClose} >
        <Title />
        <AddLinkContent />
      </DialogWrapper>
    )
  }

  return (
    <DialogWrapper modus="tablet" isOpen={isOpen} fullHeight={true} handleClose={handleClose} >
      <Title />
      <AddLinkContent />
    </DialogWrapper>
  )
}

export default observer(AddLinkModal)