import { useState } from "react"
import { Button, CircularProgress, ListItem, Stack } from "@mui/material"
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { styled } from '@mui/material/styles'

import { useTranslation } from "react-i18next"

import Resizer from "react-image-file-resizer"
import { env } from "../../api/api"
import { useStore } from "../../contexts/store"
import { PodClass } from "../../classes/Pod"


export const ImageUploadbutton = ({
  fileId,
  disabled,
  success,
  failure,
  type,
  sx,
  width,
  height,
  variant,
}: {
  fileId:string|null,
  disabled?: boolean,
  success: Function,
  failure: Function,
  type: 'podImage'|'avatar',
  sx?:Object
  width:number,
  height:number,
  variant?:'outlined'|'text'|'contained'
}) => {

  const [ busy, setBusy ] = useState<boolean>(false)
  const { podStore, sessionStore, uploadStore } = useStore()

  const { t } = useTranslation()

  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  const resizeFile = (file:File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        width,
        height,//200,
        "JPEG",
        100,
        0,
        (data) => {
          resolve(data)
        },
        'blob'
      )
    })

  const setPodImage = async (e:React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files) return false
    try {
      setBusy(true)
      const file = e.target.files[0]
      const image = await resizeFile(file)
      const imageBlob = image as Blob
      const imageArrayBuffer = await imageBlob.arrayBuffer()
      const fileId = sessionStore.createUuid()
      var rel = pod.podId
      if (type === 'avatar') rel = `${sessionStore.session.user.userId}`

      uploadStore.add(`${env.backendBaseUrl}/uploadFile`, pod.podId, {
        fileId: fileId,
        type: type,
        rel,
        size: imageArrayBuffer.byteLength,
      }, imageArrayBuffer, file.name, 'application/jpg', () => { setBusy(false); success(fileId) }, () => { console.warn('upload failure'); setBusy(false); failure() })

      e.target.value = ''
    } catch (err) {
      console.error(err)
      setBusy(false)
      failure()
    } finally {
    }
  }

  // <input  type="file" onChange={setPodImage} />
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  return <Stack spacing={2} direction="row" sx={{ ...sx, alignItems: "center", position:'relative', display:'inline-flex' }}>
    <Button
      component="label"
      role={undefined}
      variant={variant || "contained"}
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      disabled={disabled || busy}
      style={{opacity: (disabled||busy) ? .4 : 1}}
    >
      {t('Upload image for Pod')}
      <VisuallyHiddenInput
        type="file"
        onChange={setPodImage}
      />
    </Button>
    { fileId ? <div style={{
      width:100,
      height:48,
      backgroundColor:'#eee',
      backgroundSize: 'contain',
      backgroundRepeat:'no-repeat',
      backgroundImage: `url(${env.backendBaseUrl}/file/${fileId})`,
      borderRadius:4,
      boxShadow:'0px 0px 4px #0008',
      opacity: (disabled||busy) ? .4 : 1
    }}></div> : null }
    {busy && <CircularProgress sx={{
      position:'absolute',
      left:'50%',
      marginLeft:'-20px !important'
    }} />}
  </Stack>
}