import { observer } from "mobx-react-lite"
import React from 'react'
import { useStore } from '../../contexts/store'
import { baseUrl } from '../../api/api'
import { Interaction } from "../../../../types/Interaction"
import { PdfFile } from "../../../../types/Content"


const InteractionPreview = ({interactionId, contentType, podId, nodeId, width, height, size, scope, scrolling, boundary, hideTitle}: {interactionId:string, contentType?:'pdfFiles', podId?:string, nodeId?:string, width:number, height:number, size?:'contain'|'cover', scope?:'rect'|'page', scrolling?:'no'|'auto', boundary?:number, hideTitle?:boolean}) => {
  const { podStore } = useStore()
  const pod = podStore.pod
  const ref = React.useRef<HTMLDivElement>(null)

  const thumbnailHeight = 2048 // todo: how do we know the dimension of the thumbnail?
  const opacity = .3
  const color = 'white'
  var scrollX:number=0, scrollY:number=0

  React.useEffect(() => {
    const node = ref.current;
    if (node) {
      if (scrolling === 'auto') node.scrollTo(scrollX, scrollY)
    }
  }, [ref, scrollX, scrollY, scrolling])

  if (!pod) return <div style={{width, height, border:'1px solid #000', backgroundColor:'#eee'}}>no pod</div>
  if (pod.podId !== podId) if (!pod) return <div style={{width, height, border:'1px solid #000', backgroundColor:'#eee'}}>invalid podId</div>

  const render = (node: PdfFile, interaction:Interaction, width:number, height:number, size:'contain'|'cover', scope:'page'|'rect', scrolling:'hidden'|'scroll', boundary:number) => {
    // console.log(interaction.interactionId, ': ', interaction.label)
    // Determine the page based on the first rectangle (should have an option to override later on)
    if(interaction.anchor.rects.length === 0) return null
    const pageNo = interaction.anchor.rects[0].p

    // Determine the bounding rectangle
    const pdfToThumbScale = thumbnailHeight / node.pages[pageNo].height
    const thumbnailWidth = node.pages[pageNo].width * pdfToThumbScale
    const boundingRect = { x1:node.pages[pageNo].width, y1:node.pages[pageNo].height, x2:0, y2:0, w:0, h:0 }
    if (scope === 'page') {
      boundingRect.x1 = 0
      boundingRect.y1 = 0
      boundingRect.x2 = node.pages[pageNo].width
      boundingRect.y2 = node.pages[pageNo].height
      boundingRect.w = boundingRect.x2 - boundingRect.x1
      boundingRect.h = boundingRect.y2 - boundingRect.y1
    }
    else {
      interaction.anchor.rects.filter(r => r.p === pageNo).forEach((r) => {
        boundingRect.x1 = Math.min(boundingRect.x1, r.x - boundary)
        boundingRect.y1 = Math.min(boundingRect.y1, r.y - boundary)
        boundingRect.x2 = Math.max(boundingRect.x2, r.x + r.w + boundary)
        boundingRect.y2 = Math.max(boundingRect.y2, r.y + r.h + boundary)
      })
      boundingRect.w = (boundingRect.x2 - boundingRect.x1)
      boundingRect.h = (boundingRect.y2 - boundingRect.y1)
    }

    // Determine the scaling factor
    const scalingX = width / boundingRect.w
    const scalingY = height / boundingRect.h
    //console.log('scaling', scalingX, scalingY)
    const scaling = Math.min(.5, size === 'contain' ? Math.min(scalingX, scalingY) / pdfToThumbScale : Math.max(scalingX, scalingY) / pdfToThumbScale)
// console.log(`${interaction.interactionId} ': "${interaction.anchor.relText} -> ${pdfToThumbScale}  -> ${scaling}`)
    // perhaps move this to a more central location?
    const thumbnailUrl = `${baseUrl}/pageView/${node.nodeId}/${pageNo}`

    const h = thumbnailHeight
    const w = node.pages[pageNo].width * pdfToThumbScale
    const rectScale = pdfToThumbScale * scaling

    const outW = w * scaling
    const outH = h * scaling

    // get an SVG representation of all the rectangles
    const rects = interaction.anchor.rects.filter((r:any) => r.p === pageNo).map((r:any) => {
      return `<rect x="${r.x * rectScale}" y="${r.y * rectScale}" width="${r.w * rectScale}" height="${r.h * rectScale}" style="fill:rgb(0,0,0);opacity:1" />`
    }).join('')

    // Setup the CSS mask
    const masking = {
      scale: scaling,
      WebkitMaskImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${outW}px" height="${outH}px"><rect width="${outW}px" height="${outH}px" style="fill:rgb(0,0,0);opacity:${opacity}" />${rects}</svg>')`,
      maskImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${outW}px" height="${outH}px"><rect width="${outW}px" height="${outH}px" style="fill:rgb(0,0,0);opacity:${opacity}" />${rects}</svg>')`,
      WebkitMaskComposite: 'intersect',
      maskComposite: 'intersect',

      marginLeft: (-boundingRect.x1) * rectScale,
      marginTop: (-boundingRect.y1) * rectScale,
      width: thumbnailWidth + 400,
      height: thumbnailHeight + 400,
    }

    if (scope === 'page') {
      masking.marginLeft = 0
      masking.marginTop  = 0
    }

    if (scrolling === 'scroll') {
      masking.marginLeft = 0
      masking.marginTop  = 0
      masking.width = thumbnailWidth + 400
      masking.height = thumbnailHeight + 400
      scrollX = (boundingRect.x1) * rectScale
      scrollY = (boundingRect.y1) * rectScale
    }


    // Return the thumbnail image inside a masking div (instead of the image we could also use a reactPDF representation of the page)
    return <div
      ref={ref}
      // onMouseUp={(e) => { const t = ref.current; console.log(t); if (t) t.scrollTo((boundingRect.x1) * rectScale, (boundingRect.y1) * rectScale) }}
      style={{
        border: '1px solid #999',
        width,
        height,
        overflow: scrolling ? scrolling : 'hidden',
        backgroundColor:color,
        boxShadow: 'inset 0px 0px 4px #444',
        position: 'relative',
      }}
    >
      <div style={masking} title={hideTitle ? "" : interaction.anchor.relText || '*** no text recognized'}><img onDragStart={() => false} draggable={false} style={{userSelect:'none', pointerEvents:'none'}} width={outW} height={outH} src={thumbnailUrl} alt={interaction.anchor.relText || '*** no text recognized'} /></div>
      <div style={{
        position:'absolute',
        border: interaction.anchor.rects.length === 1 ? '1px solid #f004' : 'none',
        left:(interaction.anchor.rects[0].x - boundingRect.x1) * rectScale,
        top: (interaction.anchor.rects[0].y - boundingRect.y1) * rectScale,
        width: interaction.anchor.rects[0].w  * rectScale,
        height: interaction.anchor.rects[0].h * rectScale,
        color:'#0000',
        fontSize:'70%',
      }}>{interaction.anchor.relText}</div>
    </div>
  }

  const interaction = pod.getInteraction(interactionId)
  const node = pod.getNodeFromInteractionId(interactionId)
  if (!interaction) return <div style={{width, height, border:'1px solid #000', backgroundColor:'#eee'}}>no interaction</div>
  if (!node) return <div style={{width, height, border:'1px solid #000', backgroundColor:'#eee'}}>no node</div>
  const interactionPreview = render(node, interaction, width, height, size ? size : 'contain', scope ? scope : 'rect', scrolling === 'auto' ? 'scroll' : 'hidden', boundary ? boundary : 24)
  return interactionPreview
}

export default observer(InteractionPreview)


