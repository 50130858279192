import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

const LanguageSwitch: React.FC = () => {
    const { i18n } = useTranslation()
    const [language, setLanguage] = React.useState(i18n.language)
    const handleChange = (event: SelectChangeEvent) => {
        let language = event.target.value as string
        setLanguage(language)
        i18n.changeLanguage(language)
    }

    return (
        <Box>
            <FormControl sx={{ m: 1, minWidth: 120, backgroundColor: "white" }} size="small">
            <Select
                value={language}
                onChange={handleChange}
            >
                <MenuItem value={"de"}>Deutsch</MenuItem>
                <MenuItem value={"en"}>English</MenuItem>
            </Select>
            </FormControl>
        </Box>
    )
}

export default LanguageSwitch